import React from 'react';
import styled from 'styled-components';
import BackButton from './back-button';
import SectionAndQuestionSelection from './section-and-question-selection';

const Container = styled.div`
    padding-bottom: 15px;
    border-bottom: solid 1px lightgrey;
    position: sticky;
    top: -130px;
    background-color: white;
    z-index:1;

    @media only screen and (max-width: 600px) {
        top: -90px;
    }
`;

const TopContainer = styled.div`
    display: flex; 
    flex-direction: row;
    align-items: center;

    svg {
        color:#005270;
    }
    @media only screen and (max-width: 600px) {
        flex-direction:column;
        align-items:flex-start;

        .back-button {
            position:absolute;
        }
    }
`;

const Title = styled.span`
    margin-left: 15px;
    font-size: 28px;
    font-weight:400;
    letter-spacing:0.05em;
    display:inline-block;
    width: 100%;
    color:#005270;
    flex: 1;

    @media only screen and (max-width: 600px) {
        margin-bottom:9px;
        padding-left:30px;
    }
`;

const QuestionsInfoMessage = styled.span`
    flex: 1;
    font-size: 14px;
    line-height: 28px;
    font-weight: 700;
    flex-shrink: 0;
`;

const ProgressWrapper = styled.div`
    position:relative;
    /* BROWSER SPECIFICS => do not delete */
    progress::-webkit-progress-bar {
        background: rgba(0,0,0,0.2);
        border-radius:20px;
    }
    progress::-webkit-progress-value {
        background: #00bdeb;
        border-radius:20px;
    }
    progress::-moz-progress-bar {
        background: #00bdeb;
        border-radius:20px;
    }
    @media only screen and (max-width: 600px) {
        width:100%;
    }
`;

const Progress = styled.progress`
    font-size: 14px;
    line-height: 28px;
    font-weight: 700;
    flex-shrink: 0;
    flex-basis:100%;
    height:20px;
    background: #f5f5f5;
    color: #00bdeb;
    overflow:hidden;
    padding: 0px;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius:20px;
`;

const ProgressLabel = styled.span`
    width: 100%;
    display:block;
    color:black;
    font-size: 11px;
    line-height: 20px;
    position:absolute;
    top:0px;
    right:10px;
    font-weight: bold;
    text-align: right;
    text-transform: uppercase;
`;

const AssessmentDetailHeader = ({onClickBack, assessment, sectionIndex, questionIndex, onSectionChanged, onQuestionChanged}) => {
    const total = assessment.totalQuestions || 0;
    const answered = assessment.totalAnsweredQuestions || 0;
    const percentage = Math.round(answered / (total || 1) * 100);
    const section = assessment.sections[sectionIndex] || {};
    const sectionTotalQuestions = section.totalQuestions || 0;
    const sectionAnsweredQuestions = section.answeredQuestions || 0;
    return (<Container>
        <TopContainer>
            <BackButton className="back-button" onClick={onClickBack} />
            <Title>Assessment</Title>
            <ProgressWrapper>
                <ProgressLabel>{percentage}% completed</ProgressLabel>
                <Progress value={`${percentage}`} max="100"></Progress>
            </ProgressWrapper>
        </TopContainer>
        <SectionAndQuestionSelection
            assessment={assessment}
            sectionIndex={sectionIndex} 
            questionIndex={questionIndex}
            onSectionChanged={ onSectionChanged }
            onQuestionChanged={ onQuestionChanged }
        />
        <QuestionsInfoMessage>
            {`${sectionAnsweredQuestions} of ${sectionTotalQuestions} answered in this section.`}
        </QuestionsInfoMessage>
    </Container>);
};
export default AssessmentDetailHeader;