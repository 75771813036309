import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

const PopUpContainer = styled.div`
    position: relative;
`;

const PopUpComponentContainer = styled.div`
    visibility: ${({isOpen}) => isOpen ? 'visible' : 'hidden' };
`;

const ShowPopUp = (props) => {
    const [popupID] = useState(props.id || ('pop-up-' + Math.random().toString(36).substr(2, 9)));
    const [isOpen, setIsOpen] = useState(false);
    const [visible, setVisible] = useState(false);
    const [timeout, setTimeout] = useState(false);

    const onWindowClick = (e) => {
        if (props.disabled === true) {
            return;
        }
        const onSetVisibleTransitionTime = props.onSetVisibleTransitionTime || 1;
        const onSetHiddenTransitionTime = props.onSetHiddenTransitionTime || 300;
        
        clearTimeout(timeout);
        let newTimeout;
        if (document.getElementById(popupID).contains(e.target)) {
            const shouldOpen = !isOpen;
            if (shouldOpen) {
                setIsOpen(shouldOpen);
                newTimeout = setTimeout(() => setVisible(shouldOpen), onSetVisibleTransitionTime);
            }
            else {
                setVisible(false);
                newTimeout = setTimeout(() => setIsOpen(false), onSetHiddenTransitionTime);    
            }
        }
        else {
            setVisible(false);
            newTimeout = setTimeout(() => setIsOpen(false), onSetHiddenTransitionTime);
        }
        setTimeout(newTimeout);
    }

    useEffect(() => {
        window.addEventListener('click', onWindowClick);
        return () => {
            window.removeEventListener('click', onWindowClick);
        }
    });

    const {children, PopUpComponent=( () => null), className} = props;
    return (
        <PopUpContainer 
            id={popupID} 
            className={className}
        >
            {children}
            <PopUpComponentContainer isOpen={isOpen} >
                <PopUpComponent visible={visible} /> 
            </PopUpComponentContainer>
        </PopUpContainer>
    );
}

export default ShowPopUp;