import styled from "styled-components";

const Container = styled.div`
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex: 0;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 100%;
    max-width: ${props => props.maxWidth || 500}px;
`;
export default Container;