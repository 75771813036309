import firebase from "firebase/app";
import "firebase/functions";
import "firebase/firestore";

export const Timestamp = firebase.firestore.Timestamp;

const getDataFromResponse = (response, defaultValue) => {
    let result = response.data;
    if (result.result === 'error') {
        let error = new Error(result.errorMessage);
        error.errorCode = result.errorCode;
        throw error;
    }
    return result.data || defaultValue;
}

export const validateBusiness = async (business) => {
    let validateBusiness = firebase.functions().httpsCallable("validateBusiness");
    let response = await validateBusiness(business);
    return getDataFromResponse(response, []);
}

export const getUsers = async (rowsPerPage=50, pageToken, searchTerm='') => {
    let getUsers = firebase.functions().httpsCallable("getUsers");
    const data = {rowsPerPage, searchTerm};
    if (pageToken) {
        data.pageToken = pageToken;
    }
    let response = await getUsers(data);
    return getDataFromResponse(response, []);
}

export const getAuditLogItems = async (rowsPerPage=50, pageToken, searchTerm='') => {
    let getAuditLogItems = firebase.functions().httpsCallable("getAuditLogItems");
    const data = {rowsPerPage, searchTerm};
    if (pageToken) {
        data.pageToken = pageToken;
    }
    let response = await getAuditLogItems(data);
    return getDataFromResponse(response, []);
}

export const setUserRole = async (userUID, role) => {
    let getUsers = firebase.functions().httpsCallable("setUserRole");
    let response = await getUsers({userUID, role});
    return getDataFromResponse(response, []);
}

export const getBusinesses = async () => {
    let getBusinesses = firebase.functions().httpsCallable("getBusinesses");
    let response = await getBusinesses();
    return getDataFromResponse(response, []);
}

export const getBusinessDownloads = async (businessID) => {
    let getBusinessDownloads = firebase.functions().httpsCallable("getBusinessDownloads");
    let response = await getBusinessDownloads({businessID});
    const files = getDataFromResponse(response, []);
    const sections = [
        { name: 'Chamber Of Commerce Extract', basePath: 'extract', files: [] },
        { name: 'From Messages', basePath: 'messages', files: [] },
        { name: 'On-site Inspection Report', basePath: 'report', files: [] },
        { name: 'Certification', basePath: 'certification', files: [] }
    ];
    files.forEach(file => {
        const path = file.name || '';
        file.fileName = path.split(/[\\/]/).pop();
        sections.forEach(section => {
            if (path.startsWith(`businesses/${businessID}/${section.basePath}/`)) {
                section.files.push(file);
            }
        })
    });
    return sections;
}


export const registerBusiness = async (businessData) => {
    let registerBusiness = firebase.functions().httpsCallable("registerBusiness");
    let response = await registerBusiness(businessData);
    return getDataFromResponse(response, []);
}

export const getBusinessDetails = async (businessID) => {
    let getBusinessDetails = firebase.functions().httpsCallable("getBusinessDetails");
    let response = await getBusinessDetails({businessID});
    return getDataFromResponse(response, []);
}

export const getUserRole = async () => {
    let currentUser = firebase.auth().currentUser;
    if (currentUser) {
        let idTokenResult = await firebase.auth().currentUser.getIdTokenResult(true);
        return idTokenResult.claims.role || 'partner';
    }
    throw new Error('User is not logged in');
}

export const markBusinessAsApproved = async (businessID, approve=true) => {
    let markBusinessAsApproved = firebase.functions().httpsCallable("markBusinessAsApproved");
    let response = await markBusinessAsApproved({businessID, approve});
    return getDataFromResponse(response, []);
}

export const sendBusinessRegistrationPaymentInstructions = async (businessID, emails) => {
    let sendBusinessRegistrationPaymentInstructions = firebase.functions().httpsCallable("sendBusinessRegistrationPaymentInstructions");
    let response = await sendBusinessRegistrationPaymentInstructions({businessID, emails});
    return getDataFromResponse(response, []);
}

export const markBusinessAsPaid = async (businessID, paid=true) => {
    let markBusinessAsPaid = firebase.functions().httpsCallable("markBusinessAsPaid");
    let response = await markBusinessAsPaid({businessID, paid});
    return getDataFromResponse(response, []);
}

export const getBusinessAssessment = async (businessID) => {
    let getBusinessAssessment = firebase.functions().httpsCallable("getBusinessAssessment");
    let response = await getBusinessAssessment({businessID});
    return getDataFromResponse(response, []);
}

export const createBusinessAssessment = async (businessID) => {
    let createBusinessAssessment = firebase.functions().httpsCallable("createBusinessAssessment");
    let response = await createBusinessAssessment({businessID});
    return getDataFromResponse(response, []);
}

export const markBusinessAssessmentAsCompleted = async (businessID, complete) => {
    let markBusinessAssessmentAsCompleted = firebase.functions().httpsCallable("markBusinessAssessmentAsCompleted");
    let response = await markBusinessAssessmentAsCompleted({businessID, complete});
    return getDataFromResponse(response, []);
}

export const resetBusinessAssessment = async (businessID) => {
    let resetBusinessAssessment = firebase.functions().httpsCallable("resetBusinessAssessment");
    let response = await resetBusinessAssessment({businessID});
    return getDataFromResponse(response, []);
}

export const setBusinessAssessmentAuditorNotes = async (businessID, sectionIndex, questionIndex, auditorNotes) => {
    let setBusinessAssessmentAuditorNotes = firebase.functions().httpsCallable("setBusinessAssessmentAuditorNotes");
    let response = await setBusinessAssessmentAuditorNotes({businessID, sectionIndex, questionIndex, auditorNotes});
    return getDataFromResponse(response, []);
}

export const markBusinessAssessmentQuestionAsReviewed = async (businessID, sectionIndex, questionIndex, viewed) => {
    let markBusinessAssessmentQuestionAsReviewed = firebase.functions().httpsCallable("markBusinessAssessmentQuestionAsReviewed");
    let response = await markBusinessAssessmentQuestionAsReviewed({businessID, sectionIndex, questionIndex, viewed});
    return getDataFromResponse(response, []);
}

export const addBusinessAssessmentAuditorFeedbackMessage = async (businessID, sectionIndex, questionIndex, message, filePath) => {
    let addBusinessAssessmentAuditorFeedbackMessage = firebase.functions().httpsCallable("addBusinessAssessmentAuditorFeedbackMessage");
    let response = await addBusinessAssessmentAuditorFeedbackMessage({businessID, sectionIndex, questionIndex, message, filePath});
    return getDataFromResponse(response, []);
}

export const setBusinessAssessmentQuestionAnswer = async (businessID, sectionIndex, questionIndex, answer, answerComment) => {
    let addBusinessAssessmentAuditorFeedbackMessage = firebase.functions().httpsCallable("setBusinessAssessmentQuestionAnswer");
    let response = await addBusinessAssessmentAuditorFeedbackMessage({businessID, sectionIndex, questionIndex, answer, answerComment});
    return getDataFromResponse(response, []);
}

export const markBusinessOnSiteInspectionAsPassed = async (businessID, passed) => {
    let markBusinessOnSiteInspectionAsPassed = firebase.functions().httpsCallable("markBusinessOnSiteInspectionAsPassed");
    let response = await markBusinessOnSiteInspectionAsPassed({businessID, passed});
    return getDataFromResponse(response, []);
}

export const setBusinessOnSiteInspectionReport = async (businessID, filePath) => {
    let setBusinessOnSiteInspectionReportURL = firebase.functions().httpsCallable("setBusinessOnSiteInspectionReport");
    let response = await setBusinessOnSiteInspectionReportURL({businessID, filePath});
    return getDataFromResponse(response, []);
}

export const addBusinessOnSiteInspectionFeedbackMessage = async (businessID, message, filePath) => {
    let addBusinessOnSiteInspectionFeedbackMessage = firebase.functions().httpsCallable("addBusinessOnSiteInspectionFeedbackMessage");
    let response = await addBusinessOnSiteInspectionFeedbackMessage({businessID, message, filePath});
    return getDataFromResponse(response, []);
}

export const markBusinessAssessmentQuestionAsLastViewed = async (businessID, sectionIndex, questionIndex, lastViewed=true) => {
    let markBusinessAssessmentQuestionAsLastViewed = firebase.functions().httpsCallable("markBusinessAssessmentQuestionAsLastViewed");
    let response = await markBusinessAssessmentQuestionAsLastViewed({businessID, sectionIndex, questionIndex, lastViewed});
    return getDataFromResponse(response, []);
}

export const markBusinessAsLastViewed = async (businessID) => {
    let markBusinessAsLastViewed = firebase.functions().httpsCallable("markBusinessAsLastViewed");
    let response = await markBusinessAsLastViewed({businessID});
    return getDataFromResponse(response, []);
}

export const userDidLogIn = async () => {
    let userDidLogIn = firebase.functions().httpsCallable("userDidLogIn");
    let response = await userDidLogIn();
    return getDataFromResponse(response, []);
}

export const userDidLogOut = async () => {
    let userDidLogOut = firebase.functions().httpsCallable("userDidLogOut");
    let response = await userDidLogOut();
    return getDataFromResponse(response, []);
}

export const latestNotificationsOnSnapshot = (onSnapshotHandler, onErrorHandler) => {
    const user = firebase.auth().currentUser;
    if (user === null) 
        return _=>_;

    //const fromDate = new Date(user.metadata.lastSignInTime);
    //const fromTimestamp = firebase.firestore.Timestamp.fromDate(fromDate);
    let query = firebase.firestore()
                .collection('notifications')
                .where('uid', '==', user.uid)
                .orderBy('date', 'desc')
                .limit(5);

    return query.onSnapshot ( 
            (querySnap) => {
                let notifications = querySnap.docs.map( d => ({id: d.id, ...d.data()}) );
                /*notifications = notifications.filter( notification => {
                    const timestamp = notification.date || firebase.firestore.Timestamp.fromDate(new Date());
                    return (!notification.viewed) || (timestamp.seconds >= fromTimestamp.seconds);
                });*/
                (onSnapshotHandler && onSnapshotHandler(notifications));
            }, 
            onErrorHandler
        );
}

export const clearNewNotifications = async (notifications=[]) => {
    try {
        await firebase.firestore().runTransaction( t => {
            const updates = [];
            notifications.forEach( n => {
                if (n.viewed === false) {
                    const ref = firebase.firestore().doc(`/notifications/${n.id}`);
                    updates.push(t.update(ref, {viewed: true}));
                }
            })
            return Promise.all(updates);
        });
    }
    catch (error) {
        console.error(error);
    }
}