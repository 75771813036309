import React, {useState} from 'react';
import { DetailRow } from "../../../shared-components/detail-container";
import styled from 'styled-components';
import Avatar from '../../../shared-components/avatar';
import FirebaseSingleFileUploadManager from '../../../utils/firebase-single-file-upload-manager';
import firebase from 'firebase/app';

const HIDDEN_INPUT_ID = 'avatar-file';

const MyAvatar = styled(Avatar)`
    width: 90px;
    height: 90px;
    margin-right: 10px;
    margin-top:8px;
    color:#000000;
    cursor: unset;
    border:1px solid lightgrey;
    padding:2px;
    border-radius:50%;
    &:active {
        opacity: 1.0;
    }
`;

const HiddenInput = styled.input`
    display: none;
`;

const ChangeButton = styled.span`
    cursor: pointer;
    color:#00bdeb;
    text-decoration:underline;
`;

const createUploadManager = (user, onUserUpdate=(()=>{})) => {
    const manager = new FirebaseSingleFileUploadManager(
        "image/png,image/jpeg",
        () => `/accounts/${user.uid}/profile-photo`
    );
    manager.onError( (error) => alert(error.message));
    manager.onUploadCompleted( async (photoURL) => {
        photoURL = photoURL.replace(/\//g, '%2F');
        photoURL = (
            `https://firebasestorage.googleapis.com/v0/b/`+
            `${firebase.app().options.projectId}.appspot.com/o/`+
            `${photoURL}?alt=media&&${Date.now()}`
        );
        await user.updateProfile({photoURL});
        onUserUpdate(user);
    });
    return manager;
}


const AvatarDetailRow = ({user, onUserUpdate}) => {
    const [uploadManager] = useState(createUploadManager(user, onUserUpdate));
    const [loading, setLoading] = useState(false);
    return (
        <DetailRow
            title="Profile Photo"
            subtitle={<>
                <MyAvatar photoURL={user.photoURL} />
                <ChangeButton 
                    onClick={() => {
                        document.getElementById(HIDDEN_INPUT_ID).click()
                    }}
                    disabled={loading}
                >
                    {loading ? 'loading...' : 'Change Photo'}
                </ChangeButton>
                <HiddenInput 
                    type="file" 
                    id={HIDDEN_INPUT_ID}
                    accept="image/png,image/jpeg"
                    onChange={ async ({target}) => {
                        setLoading(true);
                        await uploadManager.onFilesChange(target.files);
                        setLoading(false);
                    }}
                />
            </>}
        />
    );
}
export default AvatarDetailRow;
