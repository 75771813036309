import React, { useState } from 'react';
import { DetailRow } from '../../../shared-components/detail-container';
import { Subtitle } from '../../../shared-components/text';
import Button from '../../../shared-components/button';


import styled from 'styled-components';

const dateFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const InspectionPassEditor = ({ passed = false, datePassed, onMarkAsPassed, canEdit }) => {
    const [markingAsPassed, setMarkingAsPassed] = useState(false);
    let passedMessage = 'In progress...';
    if (passed) {
        const datePassedAsDate = datePassed ? new Date(datePassed._seconds * 1000) : new Date();
        const formattedDate = datePassedAsDate.toLocaleDateString("en-US", dateFormatOptions);
        passedMessage = `Passed, on ${formattedDate}`;
    }

    return (
        <DetailRow
            title="Inspection Status"
            subtitle={
                <Container>
                    <Subtitle>{passedMessage}</Subtitle>
                    {canEdit && <>
                        <Button
                            title={`Mark As ${passed ? 'Not ' : ''}Passed`}
                            onClick={async () => {
                                setMarkingAsPassed(true);
                                try {
                                    await onMarkAsPassed(!passed);
                                }
                                catch (error) {
                                    alert(error);
                                }
                                setMarkingAsPassed(false);
                            }}
                            loading={markingAsPassed}
                        />
                    </>}
                </Container>
            }
        />
    );
}

export default InspectionPassEditor;