import React from 'react';
import styled from 'styled-components';
import Options from './options';
import {FORMATTED_ROLES} from '../../../utils/user';

export const EMAIL_CONTAINER_MAX_WIDTH = '320px';
export const PROPERTIES_CONTAINER_MAX_WIDTH = '250px';

const Container = styled.div`
    border-bottom: 1px lightgrey solid;
    display: flex;
    flex-direction: row;

    &:first-child {
        background-color:rgb(0, 60, 80);
        color:#ffffff;
        .email {
            color:#ffffff;
        }
    }
`;

const ContentContainer = styled.div` 
    flex: 1;

    /**Flex properties for container content */
    display: flex;
    flex-direction: row;
    justify-content: center;

    height: 60px;
`;

const OptionsContainer = styled.div` 
    flex: 0;
    display: flex;
    align-items: center;
`;

const EmailContainer = styled.div`
    padding-left: 15px;
    line-height:28px;
    display:flex;
    flex-direction: column;
    flex-basis:220px;
    justify-content: center;

    .role-property {
        display: none;
    }

    @media (max-width:480px) {
        display:flex;
        width:100%;
        flex:1 auto;
        border-bottom:1px solid rgba(255,255,255,0.2);

        .role-property {
            display: initial;
            flex-basis: initial;
            line-height:18px;
        }
    }
`;

const Email = styled.span`
    color: black;
    font-size: 14px;
    line-height:28px;
    font-weight: 500;

    @media (max-width:480px) {
        line-height:18px;
    }
`;

const PropertiesContainer = styled.div`
    /**Add flex basis (and min-width) to allow it to wrap when touching the minimum */
    flex-basis: ${PROPERTIES_CONTAINER_MAX_WIDTH};
    min-width: ${PROPERTIES_CONTAINER_MAX_WIDTH};
    
    /**Flex properties for container content */
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-basis: 75px;

    .role-property {
        flex-basis: 75px;
    }

    @media (max-width:480px) {
        display: none;
    }
`;

const Property = styled.span`
    flex: 0;
    font-size: 14px;
    line-height:28px;
    font-weight:400;
    flex-basis: 100px;

    @media (max-width:480px) {
        display: none;
        font-size: 12px;
    }
`;


const UserItem = ({email='-', created='-', signedIn='-', userRole='-', userUID, activeUserRole, onUserRoleSet, className}) => {
    return (
        <Container className={className}>
            <ContentContainer className="content-container">
                <EmailContainer className="email-container">
                    <Email 
                        className="email" 
                        title={userUID}
                    >
                        {email}
                    </Email>
                    <Property className="role-property">{FORMATTED_ROLES[userRole] || userRole }</Property>
                </EmailContainer>
                <PropertiesContainer className="properties-container">
                    <Property className="property">{created}</Property>
                    <Property className="property">{signedIn}</Property>
                    <Property className="role-property">{FORMATTED_ROLES[userRole] || userRole }</Property>
                </PropertiesContainer>
            </ContentContainer>
            <OptionsContainer className="options-container">
                <Options activeUserRole={activeUserRole} userRole={userRole} userUID={userUID} onUserRoleSet={onUserRoleSet}/>
            </OptionsContainer>
        </Container>
    );
}
export default UserItem;