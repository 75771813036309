import React from 'react';
import styled from 'styled-components';
import PopUp from '../pop-up';

const Container = styled.div`
    min-width: 20px;
    width: 20px;
    max-width: 20px;
    height: 20px;
    min-height: 20px;
    max-height: 20px;
    margin-left: 15px;
    position:relative;
    color: ${({answered}) => answered ? 'green' : 'grey'};

    &:hover {
        cursor: pointer;
    }
`;

const SVG = styled.svg`
    width: 100%;
    height: 100%;

    &:hover + div {
        display:block;
    }
`;



const AnsweredButton = ({ answered=false, ...props }) => {
    return (
        <Container {...props} answered={answered} >
            <SVG 
                aria-hidden="true" 
                focusable="false" 
                data-prefix="fad" 
                data-icon="badge-check" 
                className="svg-inline--fa fa-badge-check fa-w-16" 
                role="img" 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 512 512"
            >
                <g 
                    className="fa-group"
                >
                    <path 
                        className="fa-secondary" 
                        fill="currentColor" 
                        d="M512 256a88 88 0 0 0-57.1-82.4A88 88 0 0 0 338.4 57.1a88 88 0 0 0-164.8 0A88 88 0 0 0 57.1 173.6a88 88 0 0 0 0 164.8 88 88 0 0 0 116.5 116.5 88 88 0 0 0 164.8 0 88 88 0 0 0 116.5-116.5A88 88 0 0 0 512 256zm-144.8-44.25l-131 130a11 11 0 0 1-15.55-.06l-75.72-76.33a11 11 0 0 1 .06-15.56L171 224a11 11 0 0 1 15.56.06l42.15 42.49 97.2-96.42a11 11 0 0 1 15.55.06l25.82 26a11 11 0 0 1-.08 15.56z" 
                        opacity="0.4"
                    >
                    </path>
                    <path 
                        className="fa-primary" 
                        fill="currentColor" 
                        d="M367.2 211.75l-131 130a11 11 0 0 1-15.55-.06l-75.72-76.33a11 11 0 0 1 .06-15.56L171 224a11 11 0 0 1 15.56.06l42.15 42.49 97.2-96.42a11 11 0 0 1 15.55.06l25.82 26a11 11 0 0 1-.06 15.56z"
                    >
                    </path>
                </g>
            </SVG>
            <PopUp>{`Question is ${answered ? '' : 'un'}answered.`}</PopUp>
        </Container>
    )
};

export default AnsweredButton;