import React, {useState} from 'react';
import styled from "styled-components";
import SearchInput from '../search-input';
import {timeoutSave} from '../../utils/saving';

const Container = styled.div`
    padding-bottom: 30px;
    border-bottom: 1px lightgrey solid;
`;

const SectionSearchBar = ({searchTerm, onSetSearchTerm}) => {
    const [value, setValue] = useState(searchTerm);
    const [timeout, setTimeout] = useState();
    return (
        <Container>
            <SearchInput 
                onChange={ ({target}) => {
                    setValue(target.value);
                    timeoutSave(timeout, setTimeout, () => {
                        onSetSearchTerm(target.value);
                    }, 800);
                }} 
                value={value}
            />
        </Container>
    );
}
export default SectionSearchBar;