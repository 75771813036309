import React, {useState, useEffect} from 'react';
import { Route, useHistory } from "react-router-dom";
import styled from 'styled-components';
import AQSLogo from '../login-screen/logo_aqs.png';
import EnvelopeIcon from '../../shared-components/icons/envelope-icon';
import firebase from 'firebase/app';

import {
    PATH as BUSINESSES_PATH
} from '../businesses-screen';

import {
    PATH as LOGIN_PATH
} from '../login-screen';


export const PATH = "/login/welcome";


const Container = styled.div`
    display: flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    height:100vh;
    width: 100%;

    @media (max-width: 640px) {
        justify-content:flex-start;
    }
`;  

const SubContainer = styled.div`
    min-width:250px;
    max-width:480px;
    width:100%;
    background-color:#f5f5f5;
    padding:60px 15px;
    box-shadow:0 1px 2px rgba(0,0,0,.05),0 0 0 1px rgba(63,63,68,.1);
    text-align:center;

    .icon {
        display:block;
        text-align:center;
        align-items:center;
        margin:60px auto 15px auto;
        svg {
            min-width:30px;
            min-height:30px;
            color:#acc237;
        }
    }
`;

const Title = styled.span`
    color:#acc237;
    font-size: 28px;
    font-weight: 400;   
    letter-spacing: 0.05em;
    display:block;
`;

const Message = styled.span`
    font-size:17px;
    line-height:28px;
    font-weight:bold;
    display:block;
`;

const NoticeMessage = styled.span`
    font-size:16px;
    line-height:28px;
    display:block;
    color:grey;
    margin-bottom:60px;
`;

const Link = styled.span`
    color:white;
    text-align: center;
    border-radius: 0;
    font-size: 14px;
    line-height: 28px;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    text-decoration:none;
    cursor: pointer;
    margin-bottom:60px;

    ${({disabled}) => `background-color: ${disabled ? 'lightgrey' : '#005270'}`}
`;

const ConfirmationLink = styled.span`
    font-size:17px;
    line-height:28px;
    text-decoration:underline;
    margin-top:15px;
    display:block;
    cursor: pointer;

    ${({disabled}) => `color: ${disabled ? '#acc237' : 'lightgrey'}`}
`;

const GoBackLink = styled.a`
    font-size:17px;
    line-height:28px;
    color:red;
    text-decoration:underline;
    margin-top:25px;
    display:block;
    cursor: pointer;
`;

const Image = styled.img`
    width:120px;
    margin-bottom:15px;
    position:absolute;
    top:0px;
    left:15px;
    @media (max-width: 640px) {
        width:40px;
    }
`;

const CreateAccountCompletedScreen = () => {
    const user = firebase.auth().currentUser || {}; 
    const [sentVerification, setSentVerification] = useState(false);
    const [sendingVerification, setSendingVerification] = useState(false);
    const [emailVerified, setEmailVerified] = useState(user.emailVerified);
    const history = useHistory();
    
    useEffect( () => {
        const handle = setInterval( async () => {
            if (!user.reload) return;
            const wasVerified = user.emailVerified;
            await user.reload();
            setEmailVerified( user.emailVerified );
            if (wasVerified !== user.emailVerified) {
                window.location.reload();
            }
        }, 2000);
        return () => clearInterval(handle);
    });
    return (
        <Container >
            <Image src={AQSLogo} alt="Aruba Quality Seal" />
            <SubContainer>
                <Title>Thank you for registering!</Title>
                <EnvelopeIcon className="icon" />
                <Message>Please confirm your email address to continue</Message>
                <NoticeMessage>Check your email inbox</NoticeMessage>
                <Link 
                    onClick={ (event) => {
                        if (history) {
                            history.push(BUSINESSES_PATH);
                            event.preventDefault();
                        }
                    }}
                    disabled={ !emailVerified }
                >
                    Go To Dashboard
                </Link> 
                <Message>Didn't received an email?</Message>
                <ConfirmationLink
                    onClick={ async () => {
                        setSendingVerification(true);
                        try {
                            firebase.auth().currentUser.sendEmailVerification({ url: window.location.origin + '/login/welcome'});
                            setSentVerification(true);
                        }
                        catch (error) {
                            console.error(error);
                        }
                        setSendingVerification(false);
                    }}
                    disabled={ !sentVerification }
                >
                    {sendingVerification && "Sending..."}
                    {(!sendingVerification && !sentVerification) && "Send confirmation email again"}
                    {(!sendingVerification && sentVerification) && "Sent!"}
                </ConfirmationLink>
                
                <GoBackLink
                    onClick={ (event) => {
                        if (history) {
                            history.push(LOGIN_PATH);
                            event.preventDefault();
                        }
                    }}
                    disabled={ sentVerification }
                >
                    or go back to login
                </GoBackLink>
            </SubContainer>
        </Container>
    );
}
export default CreateAccountCompletedScreen;

export const createAccountCompletedScreenRoutes = (props) => {
    return [
        <Route path={PATH} exact key='create-account-completed-screen'>
            <CreateAccountCompletedScreen {...props} />
        </Route>
    ];
}