import React from "react";
import styled from "styled-components";
import BusinessItemContainer from './block-item-container';

const AttributeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color:#ffffff;
    width: 100%;
    height: 100%;
    &:hover {
        background-color:#f1f1f1;
        cursor:pointer;
    }
`;

const Title = styled.span`
    font-size: 16px;
    font-weight: 600;
    color:#005270;
`;

const LargeText = styled.span`
    font-size: 55px;
    font-weight: bold;
    margin-bottom: 20px;
    color:#005270;
`;

const AddBusinessItem = ({ onClick }) => {
    return (
        <BusinessItemContainer 
            onClick={onClick} 
            type='add'
        >

            <AttributeContainer>
                <LargeText>+</LargeText>
                <Title>Register New Business</Title>
            </AttributeContainer>

        </BusinessItemContainer>
    );
};
export default AddBusinessItem;
