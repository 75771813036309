import React from 'react';
import Container from './icon-container';
import SVG from './svg';

const AvatarIcon = ({className}) => (
    <Container className={className} >
        <SVG viewBox="0 0 512 512">
            <g>
                <path 
                    fill="currentColor" 
                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 96a88 88 0 1 1-88 88 88 88 0 0 1 88-88zm0 344a191.63 191.63 0 0 1-146.5-68.2c18.8-35.4 55.6-59.8 98.5-59.8a24.47 24.47 0 0 1 7.1 1.1 124.67 124.67 0 0 0 81.8 0 24.47 24.47 0 0 1 7.1-1.1c42.9 0 79.7 24.4 98.5 59.8A191.63 191.63 0 0 1 256 448z" 
                    opacity="0.4"
                >
                </path>
                <path 
                    fill="currentColor" 
                    d="M256 280a88 88 0 1 0-88-88 88 88 0 0 0 88 88zm48 40a24.47 24.47 0 0 0-7.1 1.1 124.67 124.67 0 0 1-81.8 0 24.47 24.47 0 0 0-7.1-1.1c-42.9 0-79.7 24.4-98.5 59.8a191.45 191.45 0 0 0 293 0C383.7 344.4 346.9 320 304 320z"
                >
                </path>
            </g>
        </SVG>
    </Container>
);

export default AvatarIcon;