import styled from "styled-components";

const DetailSectionContainer = styled.div`
    position: relative;
    padding: 30px;
    display: flex;
    flex-direction: column;
    background-color:#f5f5f5;
    min-height:100%;
    height:auto;

    @media only screen and (max-width: 600px) {
        padding:0;
    }
`;

export default DetailSectionContainer;