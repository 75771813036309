import React, { Component } from 'react';
import { Route } from "react-router-dom";
import PagedList from '../../../shared-components/paged-list';
import AuditLogList from './audit-log-list';
import { getAuditLogItems } from '../../../utils/server-functions';

export const PATH = '/logs';

class AuditLogSection extends Component {

    render() {
        const {
            userRole
        } = this.props;

        return (
            <PagedList
                loadResultsHandler={getAuditLogItems}
                ListComponent={ 
                    p => <AuditLogList activeUserRole={userRole} {...p} />
                }
            />
        );
    }
}
export default AuditLogSection;

export const auditLogSectionRoutes = ({ rootPath, ...props }) => {
    return [
        <Route path={`${rootPath}${PATH}`} exact key={'account-settings-screen-audit-log-section'} render={(routeProps) =>
            <AuditLogSection {...props} {...routeProps} />
        } />
    ];
}