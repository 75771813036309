import React from 'react';
import styled from 'styled-components';
import AssessmentSectionItem from './assessment-section-item';

const Container = styled.div``;

const AssessmentSectionsList = ({
    sections = [], 
    onSetAuditorNotes, 
    onMarkReviewed, 
    canViewReviewed, 
    canEditReviewed,
    canViewNotes,
    canEditNotes,
    printable}) => {
    return (
        <Container>
            {sections.map((section, i) => 
                <AssessmentSectionItem 
                    key={`assessment-item-${i}`} 
                    sectionIndex={i} 
                    onSetAuditorNotes={onSetAuditorNotes}
                    onMarkReviewed={onMarkReviewed}
                    canViewReviewed={canViewReviewed}
                    canEditReviewed={canEditReviewed}
                    canViewNotes={canViewNotes}
                    canEditNotes={canEditNotes}
                    {...section} 
                    printable={printable}
                /> 
            )}
        </Container>
    )
}

export default AssessmentSectionsList;