import React from 'react';
import styled from 'styled-components';
import PopUp from '../pop-up';

const Container = styled.div`
    min-width: 20px;
    width: 20px;
    max-width: 20px;
    height: 20px;
    min-height: 20px;
    max-height: 20px;
    margin-left: 15px;
    position:relative;
    color: ${({correct}) => correct ? 'green' : 'red'};

    &:hover {
        cursor: pointer;
    }
`;

const SVG = styled.svg`
    width: 100%;
    height: 100%;

    &:hover + div {
        display:block;
    }
`;



const MandatoryButton = ({ answered, answer='-', correctAnswer='', ...props }) => {
    return (
        <Container {...props} correct={answered && answer === correctAnswer} >
            <SVG 
                aria-hidden="true" 
                focusable="false" 
                data-prefix="fad" 
                data-icon="shield-check" 
                className="svg-inline--fa fa-shield-check fa-w-16" 
                role="img" 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 512 512"
            >
                <g 
                    className="fa-group"
                >
                    <path 
                        className="fa-secondary" 
                        fill="currentColor" 
                        d="M466.5 83.67l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80A48 48 0 0 0 16 128c0 198.5 114.5 335.69 221.5 380.29a48.15 48.15 0 0 0 36.9 0C360.1 472.58 496 349.27 496 128a48 48 0 0 0-29.5-44.33zm-47.2 114.21l-184 184a16.06 16.06 0 0 1-22.6 0l-104-104a16.07 16.07 0 0 1 0-22.61l22.6-22.6a16.07 16.07 0 0 1 22.6 0l70.1 70.1 150.1-150.1a16.07 16.07 0 0 1 22.6 0l22.6 22.6a15.89 15.89 0 0 1 0 22.61z" 
                        opacity="0.4"
                    >
                    </path>
                    <path 
                        className="fa-primary" 
                        fill="currentColor" 
                        d="M419.3 197.88l-184 184a16.06 16.06 0 0 1-22.6 0l-104-104a16.07 16.07 0 0 1 0-22.61l22.6-22.6a16.07 16.07 0 0 1 22.6 0l70.1 70.1 150.1-150.1a16.07 16.07 0 0 1 22.6 0l22.6 22.6a15.89 15.89 0 0 1 0 22.61z"
                    >
                    </path>
                </g>
            </SVG>
            <PopUp>Mandatory questions are questions that must ultimately be answered 'Yes' to pass the assessment.</PopUp>
        </Container>
    )
};

export default MandatoryButton;