import React from 'react';
import styled from 'styled-components';

const MenuItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 44px;
    padding: 12px 15px 12px 15px;
    overflow: hidden;
    box-sizing: border-box;
    ${({seperator, theme}) => 
        seperator ? `border-bottom: 0.4pt solid ${theme.authMenu.rowSeperatorColor};` : ''
    }
    transition: 300ms;
    :hover {
        background-color:#005270; 
        cursor:pointer;
        span {
            color:#fff;
        }
    }
`;

const ItemTitle = styled.span`
    color: #005270;
    font-size: 11pt;
    font-weight: bold;
`;

const MenuItem = (props) => {
    let {title, onClick=( () => null), selected=false, seperator=false} = props;
    return (
        <MenuItemContainer 
            onClick={(event) => {
                onClick(props);
                event.preventDefault();
            }}
            seperator={seperator}
        >
            <ItemTitle selected={selected} >{title}</ItemTitle>
        </MenuItemContainer>
    )
};

export default MenuItem;
  