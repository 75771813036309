import React from "react";
import { Route, useHistory } from "react-router-dom";
import {CMSForgotPassword as DefaultCMSLogin} from '@cr38te/cr38te-cms';
import styled from "styled-components";
import Header from '../login-screen/header';

import { 
    forgotPasswordCompletedScreenRoutes, 
    PATH as FORGOT_PASSWORD_COMPLETED_SCREEN_PATH 
} from "../forgot-password-completed-screen"

export const PATH = "/login/iforgot";

const CMSForgotPassword = styled(DefaultCMSLogin)`
    background-image:url('https://aruba.bynder.com/m/201143b3304ef039/fullscreen_letterbox-Entrance-to-Bushiribana-Gold-Mill-Ruins-Entrance-to-Bushiribana-Gold-Mill-Ruins.jpg');
    background-position:center center;
    background-repeat:no-repeat;
    background-size:cover;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    height:100vh;

    @media (max-width: 640px) {
        justify-content:flex-start;
    }

    form {
        min-width:250px;
        max-width:480px;
        width:100%;
        background-color:#f5f5f5;
        padding:15px;
        box-shadow:0 1px 2px rgba(0,0,0,.05),0 0 0 1px rgba(63,63,68,.1);

        > div:first-child {
            padding:0;
           .ResetPassword {
                background-color:#005270;
                color:white;
                text-align:center;
                border-radius:0;
                font-size:16px;
                line-height:36px;
                text-transform:uppercase;
            }
        }
        @media (max-width: 640px) {
            margin-left:15px;
            margin-right:15px;
            min-width:250px;
            max-width:calc( 100vw - 30px );
        }
    }
    .Title {
        flex: 1;
        font-size: 14px;
        line-height: 28px;
        font-weight: 700;
        flex-shrink: 0;
        background-color:transparent;
    }
    h1.Title {
        margin-top:0;
        font-size: 28px;
        font-weight:400;
        letter-spacing:0.05em;
        display:inline-block;
        width: 100%;
        color:#005270;
        flex: 1;
    }
    .InputContainer {
        font-size: 16px;
        border-radius: 0px;
        border: 1px solid #cccccc;
        padding: 5px 12px;
        color:black;
        .Input {
            font-size:14px;
        }
    }
    .TitleCheckboxContainer {
        #rememberMe {
            margin-top:-5px;
        }
        label {
            line-height:51px;
        }
    }
    .ErrorMessage {
        border:1px solid #990000;
        background-color:rgba(153, 0, 0, 0.35);
        padding:15px;
        color:#000;
        font-weight:bold;
        border-radius:0;
        font-size:14px;
        line-height:28px;
        letter-spacing:0.08px;
    }
    .Link {
        background-color:transparent;
        &:hover {
            text-decoration:underline;
            color:red;
        }
    }
    .InnerContainer {
        min-height:auto;
        height:auto;
        transition:all 0.2s linear;
    }
`;

const ForgotPasswordScreen = ({loginScreenPath, authManager={}}) => {
    let history = useHistory();
    return (
        <CMSForgotPassword 
            HeaderComponent={Header}
            onClickCancel={ () => history.push(loginScreenPath) }
            onForgotPassword={ authManager.forgotPassword }
            onForgotPasswordCompleted={ () => history.push(FORGOT_PASSWORD_COMPLETED_SCREEN_PATH) }
        />
    );
}
export default ForgotPasswordScreen;


export const forgotPasswordScreenRoutes = (props) => {
    return [
        <Route path={PATH} exact key='forgot-password-screen' >
            <ForgotPasswordScreen {...props} />
        </Route>,
        ...forgotPasswordCompletedScreenRoutes(props),
    ];
}