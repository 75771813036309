import React from 'react';
import styled from 'styled-components';
import MediaFileLink from '../media-file-link';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0 0 30px 0;
    padding-top:30px;
    ${({alignLeft=true}) => alignLeft ? 'padding-right:50%;' : 'padding-left:50%;'}
    align-items:center;
    position:relative;
    transition:all 0.3s linear;

    @media (max-width: 1250px) {
        ${({alignLeft=true}) => alignLeft ? 'padding-right:20%;' : 'padding-left:20%;'}
    }
    @media (max-width: 1090px) {
        ${({alignLeft=true}) => alignLeft ? 'padding-right:10%;' : 'padding-left:10%;'}
    }
    @media (max-width: 640px) {
        ${({alignLeft=true}) => alignLeft ? 'padding-right:0;' : 'padding-left:0;'}
    }
`;

const Profile = styled.img`
    margin-top: 2px;
    font-size: 14px;
    font-weight:bold;
    color: black;
    text-align: ${({alignLeft=true}) => alignLeft ? 'left' : 'right'};
    width:40px;
    height:40px;
    flex-basis:40px;
    border-radius:50%;
    ${({alignLeft=true}) => alignLeft ? 'margin-right:15px;' : 'margin-left:15px;'};
    border:lightgrey solid 1px;
`;

const Username = styled.span`
    margin-top: 2px;
    font-size: 14px;
    font-weight:bold;
    flex-basis:40px;
    color: black;
    text-align: ${({alignLeft=true}) => alignLeft ? 'left' : 'right'};
    ${({alignLeft=true}) => alignLeft ? 'margin-right:15px;' : 'margin-left:15px;'};
`;


const MessageText = styled.span`
    flex: 1;
    text-align: ${({alignLeft=true}) => alignLeft ? 'left' : 'right'};
    ${({alignLeft=true}) => alignLeft ? 'background-color:white;' : 'background-color:#c1dee9;'}        
    border:1px solid rgba(0,0,0,0.2);
    padding:15px;
    border-radius:4px;
    font-size:14px;
    line-height:28px;
    position:relative;
`;

const DateField = styled.span`
    font-size:12px;
    font-weight:600;
    line-height:28px;
    position:absolute;
    top:-30px;
    ${({alignLeft=true}) => alignLeft ? 'left:0;' : 'right:0;'}   
`;

const Label = styled.span`
    font-size:14px;
    line-height:28px;
    font-weight:bold;
    display:flex;
    flex:1;
    margin-top:15px;
`;

const dateFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute:'numeric'};

const UserProfile = ({profilePicture, userName, alignLeft}) => {
    return (profilePicture ?
        <Profile src={'https://picsum.photos/40/40'} alt={userName} alignLeft={alignLeft} />
        :
        <Username alignLeft={alignLeft}>{userName}</Username>
    );
}

const Message = ({userName, message, dateSent, profilePicture, fileURL, filePath, fileName, onClickFile, usernameSide='left'}) => {
    const alignLeft = (usernameSide === 'left');
    const dateSentAsDate = dateSent ? new Date(dateSent._seconds * 1000) : new Date();
    const formattedSentDate = dateSentAsDate.toLocaleDateString("en-US", dateFormatOptions);

    return (
        <Container alignLeft={alignLeft}>
            {alignLeft && <UserProfile profilePicture={profilePicture} userName={userName} />}
            <MessageText 
                alignLeft={alignLeft}
            >
                <DateField alignLeft={alignLeft}>{formattedSentDate}</DateField>
                {message || '...'}
                {fileURL && <>
                    <Label>Attachment:</Label>
                    <MediaFileLink 
                        fileURL={fileURL}
                        fileName={fileName}
                        filePath={filePath}
                    />
                </>}
            </MessageText>
            {!alignLeft && <UserProfile profilePicture={profilePicture} userName={userName} alignLeft={false} />}
        </Container>
    );
}

export default Message;