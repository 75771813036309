import React from 'react';
import { Route } from "react-router-dom";
import DetailHeader, { applyIconStyle } from '../../shared-components/detail-header';
import CheckIcon from '../../shared-components/icons/check-icon';
import StartAssessmentContent from './start-assessment-content';
import DuringAssessmentContent from './during-assessment-content';
import CompletedAssessmentContent from './completed-assessment-content';
import { businessAssessmentDetailScreenRoutes } from '../business-assessment-detail-screen';
import { businessAssessmentSummaryScreenRoutes } from '../business-assessment-summary-screen';
import Message from '../../shared-components/message';
import GroupElements from '../../shared-components/group-elements';
import { hasRoles } from '../../utils/user';
import Container from '../../shared-components/detail-section-container';

export const PATH = '/assessment';

const Icon = applyIconStyle(CheckIcon);

const BusinessAssessment = (props) => {
    const {
        businessID,
        business = {},
        userRole,
        reloadBusinessDetails,
        rootPath: baseRootPath,
        user,
    } = props;
    const assessmentStarted = (business.assessment !== null);
    const assessment = business.assessment || {};
    const canStartAssessment = hasRoles(userRole, 'admin', 'partner');
    const canMarkAssessmentAsCompleted = hasRoles(userRole, 'admin', 'leadAuditor');
    const canResetAssessment = hasRoles(userRole, 'admin', 'leadAuditor');
    const rootPath = `${baseRootPath}${PATH}`;
    const routeProps = {
        rootPath,
        assessment,
        userRole,
        business,
        businessID,
        reloadBusinessDetails,
        user
    };
    
    const registration = business.registration || {};
    
    return (
        <Container>
            <GroupElements>
                <DetailHeader
                    Icon={Icon}
                    title={"Assessment"}
                    detail={"Do an online assessment of your business."}
                />
                {(registration.isApproved && registration.isPaid) ?
                    <>
                        <StartAssessmentContent
                            visible={!assessmentStarted}
                            businessID={businessID}
                            canStartAssessment={canStartAssessment}
                            reloadBusinessDetails={reloadBusinessDetails}
                            rootPath={rootPath}
                        />
                        <DuringAssessmentContent
                            visible={(assessmentStarted && !assessment.completed)}
                            assessment={assessment}
                            reloadBusinessDetails={reloadBusinessDetails}
                            showMarkAsCompletedOption={canMarkAssessmentAsCompleted}
                            showResetOption={canResetAssessment}
                            rootPath={rootPath}
                        />
                        <CompletedAssessmentContent
                            visible={(assessmentStarted && assessment.completed)}
                            assessment={assessment}
                            reloadBusinessDetails={reloadBusinessDetails}
                            showMarkAsCompletedOption={canMarkAssessmentAsCompleted}
                            showResetOption={canResetAssessment}
                            rootPath={rootPath}
                        />
                        {businessAssessmentDetailScreenRoutes(routeProps)}
                        {businessAssessmentSummaryScreenRoutes(routeProps)}
                    </>
                    :
                    <>
                        <Message>Once your registration and payment have been approved you will be able to start your online assessment.</Message>
                    </>
                }
            </GroupElements>
        </Container>
    );
}
export default BusinessAssessment;

export const businessAssessmentScreenRoutes = ({ rootPath, ...props }) => {
    return [
        <Route path={`${rootPath}${PATH}`} key={'business-assessment-screen'} render={(routeProps) =>
            <BusinessAssessment {...props} {...routeProps} rootPath={rootPath} />
        } />
    ];
}