import React, { Component } from 'react';
import styled from 'styled-components';
import AQSLogo from '../login-screen/logo_aqs.png';

const Container = styled.div`
    background-color: transparent;
    position:absolute;
    left:15px;
    top:0;
    z-index:10;
    min-width:250px;
    max-width:480px;
    width:100%;
    top:0;
    @media (max-width: 640px) {
        min-width:250px;
        max-width:calc( 100vw - 30px );
        position:relative;
        left:0;
    }
`;

const Image = styled.img`
    width:120px;
    margin-bottom:15px;
    @media (max-width: 640px) {
        width:40px;
    }
`;

export default class HeaderLoginComponent extends Component {    
    render () {
        return (
            <Container>
                <Image src={AQSLogo} alt="Aruba Quality Seal" />
            </Container>
        );
    }
}