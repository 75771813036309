import React, { useState } from "react";
import styled, { keyframes } from 'styled-components';
import { Route, useHistory} from "react-router-dom";
import RegisterBusinessScreenStepOne from "./step-one";
import RegisterBusinessScreenStepTwo from "./step-two";
import RegisterBusinessScreenStepThree from "./step-three";
import { registerBusiness } from "../../utils/server-functions";

export const PATH = "/register";

const SlideInAnimation = keyframes`
    0% { 
        left: 100%; 
    } 
    100% { 
        left: 0%; 
    }
`;

const SlideOutAnimation = keyframes`
    0% { 
        left: 0%; 
    } 
    100% { 
        left: 100%; 
    }
`;

// MAIN CONTAINER
const Container = styled.div`
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    padding:7.5rem;
    animation: ${({animateIn=true}) => animateIn ? SlideInAnimation : SlideOutAnimation} 300ms ease-in-out 0ms 1 forwards;
    background: linear-gradient(90deg, rgba(255,255,255,1) 85%, rgba(0,82,112,1) 85%, rgba(0,82,112,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#005270",GradientType=1); 
    overflow-y: scroll;

    @media only screen and (max-width: 1024px) {
        padding:15px;
        background:white;
    }
`;

const RegisterBusinessScreen = ({goBackPath='/', refreshBusinesses=(_=>_)}) => {
    const history = useHistory();
    let [animateIn, setAnimateIn] = useState(true);
    let [currentStep, setCurrentStep] = useState('step-one');
    let [loadingRegistration, setLoadingRegistration] = useState(false);
    let [businessData, setBusinessData] = useState(false);
    
    const onClose = () => {
        setAnimateIn(false);
        setTimeout( () => history.push(goBackPath), 300);
    };

    return (
        <Container animateIn={animateIn}>
            <RegisterBusinessScreenStepOne
                visible={currentStep === 'step-one'}
                onClickClose={onClose} 
                onClickContinue={ (data) => {
                    setBusinessData({...businessData, ...data});
                    setCurrentStep('step-two');
                }}
            />
            <RegisterBusinessScreenStepTwo
                visible={currentStep === 'step-two'}
                onClickClose={onClose} 
                onClickPrevious={ () => setCurrentStep('step-one') }
                onClickContinue={ (data) => {
                    setBusinessData({...businessData, ...data});
                    setCurrentStep('step-three') 
                }}
            />
            <RegisterBusinessScreenStepThree
                visible={currentStep === 'step-three'}
                onClickClose={onClose} 
                onClickRegister={ async () => {
                    setLoadingRegistration(true);
                    try {
                        await registerBusiness(businessData);
                        await refreshBusinesses();
                        history.push(goBackPath);
                    }
                    catch(error) {
                        console.error(error);
                        alert(error.message);
                    }
                    finally {
                        setLoadingRegistration(false);
                    }
                }}
                loadingRegistration={ loadingRegistration }
                onClickPrevious={ () => setCurrentStep('step-two') }
            />
        </Container>
    );
}
export default RegisterBusinessScreen;

export const registerBusinessScreenRoutes = (props) => {
    return [
        <Route path={PATH} exact key={'register-business-screen'} render={ (routeProps) =>
            <RegisterBusinessScreen {...routeProps} {...props} />
        }/>,
    ];
}
