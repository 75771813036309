import React from "react";
import {CMSDefaultTheme as DefaultTheme} from '@cr38te/cr38te-cms';
import styled, { ThemeProvider }  from "styled-components";
import OriginalAvatarIcon from '../icons/avatar-icon';

const Container = styled.div`
    flex-shrink: 0;
    color: ${ ({theme}) => `${(theme.header.menuIconColor)}`};
    width: 20px;
    height: 20px;
    cursor: pointer;
    &:active {
        opacity: 0.4;
    }
    display: block;
`;

const Photo = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-image: url(${ ({photoURL}) => photoURL });
    background-color: ${ ({theme}) => `${(theme.header.menuIconColor)}`};
    background-size: cover;
`;

const AvatarIcon = styled(OriginalAvatarIcon)`
    /** Unset all of the container properties of the icon*/
    min-width: unset;
    width: unset;
    max-width: unset;
    height: unset;
    min-height: unset;
    max-height: unset;
    color: unset;
    user-select: unset;
`;

const Avatar = (props) => {
    let {photoURL, theme=DefaultTheme} = props;
    return (
        <ThemeProvider theme={theme}>
            <Container {...props} >
                { photoURL ?
                    <Photo photoURL={photoURL} />
                    :
                    <AvatarIcon />
                }
            </Container>
        </ThemeProvider>
    )
};
export default Avatar;