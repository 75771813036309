import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
    width:20vw;
    height:40px;
    border-radius:0;
    border:1px solid #ccc !important;
    box-shadow:none !important;
    appearance: none;
    font-size:13px;
    padding:.375rem 1.75rem .375rem .75rem;

    @media (max-width: 1024px) {
        width: 40vw;
    }

    @media (max-width: 700px) {
        width: calc( 100% - 45px );
        margin-bottom: 15px;
        display:block;
    }

    @media (max-width: 480px) {
        height:50px;
    }
`;

const SearchInput = ({value, onChange, placeholder='Search...', ...otherProps}) => {
    return (
        <StyledInput 
            type='text' 
            value={value} 
            onChange={onChange} 
            placeholder={placeholder}
            {...otherProps}
        />
    );
}
export default SearchInput
