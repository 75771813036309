import React from 'react';
import styled from "styled-components";

const Container = styled.div`
    border-bottom: solid 1px rgba(63,63,68,.1);
    padding-bottom: 30px;
`;

const Message = styled.span`
    font-size: 14px;
    line-height:28px; 
    font-weight: bold;
`;

const BigMessage = ({title}) => {
    return (
        <Container><Message>{title}</Message></Container>
    );
}
export default BigMessage;