import React from 'react';
import Container from './icon-container';
import SVG from './svg';

const CardIcon = ({className}) => (
    <Container className={className} >
        <SVG 
            aria-hidden="true" 
            focusable="false" 
            data-prefix="fal" 
            data-icon="credit-card" 
            className="svg-inline--fa fa-credit-card fa-w-18" 
            role="img" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 576 512"
        >
            <path 
                fill="currentColor" 
                d="M528 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM48 64h480c8.8 0 16 7.2 16 16v48H32V80c0-8.8 7.2-16 16-16zm480 384H48c-8.8 0-16-7.2-16-16V224h512v208c0 8.8-7.2 16-16 16zm-336-84v8c0 6.6-5.4 12-12 12h-72c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12zm192 0v8c0 6.6-5.4 12-12 12H236c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12z"
            >
            </path>
        </SVG>
    </Container>
);

export default CardIcon;