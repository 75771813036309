import * as React from 'react';
import styled from 'styled-components';
import Avatar from '../avatar';

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 85px;
    padding: 10px;
    overflow: hidden;
    box-sizing: border-box;
    border-bottom: 0.4pt solid ${({theme}) => theme.authMenu.rowSeperatorColor};
    background-color:#f5f5f5;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const DisplayNameTitle = styled.span`
    color: ${({theme}) => theme.authMenu.defaultTextColor} ;
    font-size: 12pt;
    font-weight: bold;
    margin-top:13px;
`;

const EmailTitle = styled.span`
    margin-top: 3px;
    color: ${({theme}) => theme.authMenu.defaultTextColor} ;
    font-size: 9pt;
`;

const MyAvatar = styled(Avatar)`
    width: 48px;
    height: 48px;
    margin-right: 10px;
    margin-top:8px;
    color:#000000;
`;

const Header = ({photoURL, displayName, email}) => (
    <HeaderContainer>
        <MyAvatar photoURL={photoURL} />
        <TitleContainer>
            <DisplayNameTitle>{displayName||"User"}</DisplayNameTitle>
            <EmailTitle>{email}</EmailTitle>
        </TitleContainer>
    </HeaderContainer>
);

export default Header;
  