import React from 'react';
import styled from 'styled-components';
import AQSLogo from '../../businesses-screen/aqs.svg';

const Container = styled.div`
    background-color:#005270;
    display: flex;
    padding: 15px;
    align-items: center;
    height: 65px;
    margin-bottom:0;
    width: 100%;
    user-select: none;
    cursor: pointer;
    border-bottom: 1px solid rgba(255,255,255, 0.1);
`;

const Title = styled.span`
    font-size: 20px;
    color: white;
    float:left;
    line-height:42px;
    margin-left:15px;
`;

const Image = styled.img`
    width:40px;
    float:left;
    display:block;
`;

const Header = ({onClick}) => {
    return (
        <Container 
            onClick={ (e) => {
                e.preventDefault();
                (onClick && onClick(e));
            }}
        >
            <Image src={AQSLogo} alt="Aruba Quality Seal" />
            <Title>Aruba Quality Seal</Title>
        </Container>
    );
}
export default Header;
