import React from 'react';
import { Route } from "react-router-dom";
import styled from 'styled-components';
import FileIcon from '../../shared-components/icons/file-icon';
import DetailHeader, { applyIconStyle } from '../../shared-components/detail-header';
import OriginalGroupElements from '../../shared-components/group-elements';
import Container from '../../shared-components/detail-section-container';
import MediaFileLink from '../../shared-components/media-file-link';
import { convertExternalDownloadsToFileSection, termsAndAgreementDownloads } from './helpers';

export const PATH = '/downloads';

const GroupElements = styled(OriginalGroupElements)`
    display: flex;
    flex-direction: column;
`;

const Icon = applyIconStyle(FileIcon);

const DownloadContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content:flex-start;
    flex-wrap: wrap;
`;

const DownloadSectionTitle = styled.div`
    font-size:19px;
    line-height:20px;
    font-weight:900;
    padding-left:0;
    padding-bottom: 30px;
    color:black;
    width:100%;
`;

const DownloadItem = styled(MediaFileLink)`

    width: 100%;

    margin-bottom: ${({last}) => last ? 30 : 0}px;
    padding: 5px;
    padding-left: 15px;

    border: solid 1px lightgrey;
    border-bottom: solid ${({last}) => last ? 1 : 0}px lightgrey;
    text-decoration: none; 
    
`;

const BusinessDownloads = (props) => {
    const {
        business,
        businessDownloads = [],
    } = props;
    let allDownloads = [termsAndAgreementDownloads(), ...businessDownloads];
    const externalDownloads = business?.registration?.externalDownloads;
    const externalDownloadsSection = convertExternalDownloadsToFileSection(externalDownloads);
    if (externalDownloadsSection) {
        allDownloads = [externalDownloadsSection, ...allDownloads];
    }

    return (
        <Container>
            <GroupElements>
                <DetailHeader
                    Icon={Icon}
                    title="Downloads"
                    detail="Your business's certification downloads."
                />
                {allDownloads.map(section => {
                    if (section.files.length === 0) {
                        return null;
                    }
                    else {
                        return (
                            <DownloadContainer key={section.name} >
                                <DownloadSectionTitle>{section.name}</DownloadSectionTitle>
                                {section.files.map((file, index) => {
                                    return (
                                        <DownloadItem
                                            fileName={file.fileName} //The last path component of the file path.
                                            filePath={file.name} //Full path of the file on storage.
                                            key={file.name}
                                            fileURL={file.publicUrl}
                                            last={index === ((section?.files?.length ?? 1) - 1) }
                                            isPublicURL={file.isPublicURL || false }
                                        />
                                    )
                                })}
                            </DownloadContainer>
                        );
                    }
                })}
            </GroupElements>
        </Container>
    );
}
export default BusinessDownloads;

export const businessDownloadsScreenRoutes = ({ rootPath, ...props }) => {
    return [
        <Route path={`${rootPath}${PATH}`} exact key={'business-on-site-inspection-screen'} render={(routeProps) =>
            <BusinessDownloads {...props} {...routeProps} rootPath={rootPath} />
        } />
    ];
}
