import { validateBusiness } from "../../utils/server-functions";

export const validateStepOneData = async (data, setCanContinue, setErrors, setLoading) => {
    try {
        setLoading(true);
        const result = await validateBusiness(data);
        const errors = result.errors;
        if (errors.nameID && errors.nameID.suggestedReplacement) { 
            data.nameID = errors.nameID.suggestedReplacement;
        }
        setErrors({
            type: (data.type || (data.name)) && errors.type,
            name: (data.name || data.chamberOfCommerceRegistrationCode) && errors.name,
            chamberOfCommerceRegistrationCode: (data.name || data.chamberOfCommerceRegistrationCode) && errors.chamberOfCommerceRegistrationCode,
            chamberOfCommerceExtractFilePath: (data.chamberOfCommerceRegistrationCode || data.chamberOfCommerceExtractFilePath) && errors.chamberOfCommerceExtractFilePath,
        });
        setCanContinue( !(errors.type || errors.name || errors.chamberOfCommerceRegistrationCode || errors.chamberOfCommerceExtractFilePath) );
    }
    catch(error) {
        console.error(error);
        alert(error.message);
    }
    finally {
        setLoading(false);
    }
}

export const validateStepTwoData = async (data, setCanContinue, setErrors, setLoading) => {
    try {
        setLoading(true);
        const result = await validateBusiness(data);
        const errors = result.errors;
        setErrors({
            contactPhoneNumber: (data.contactPhoneNumber || (data.address)) && errors.contactPhoneNumber,
            website: (data.website || data.address) && errors.website,
            address: (data.address) && errors.address,
            numberOfSites: (data.address || data.numberOfSites) && errors.numberOfSites,
        });
        setCanContinue( !(errors.contactPhoneNumber || errors.website || errors.address || errors.numberOfSites) );
        console.log(data, result);
    }
    catch(error) {
        console.error(error);
        alert(error.message);
    }
    finally {
        setLoading(false);
    }
}

export const createOnChangeField = (data, timer, setTimer, setData, setCanContinue, setErrors, setLoading, validate) => (fieldName) => {
    return ({target}) => {
        let newData = {...data, [fieldName]: target.value};
        setData(newData);
        clearTimeout(timer);
        setTimer( setTimeout(
            () => validate(newData, setCanContinue, setErrors, setLoading),
            500
        ));
    };
}

