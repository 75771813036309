import styled from "styled-components";

export const Hyperlink = styled.a`
    color:#00536e;
    font-size:18px;
    line-height:18px;
    text-decoration:none;
    position:absolute;
    left:90px;
    float:left;
    margin-right:15px;
    font-weight: ${({theme}) => theme.weightBold };
    font-family: ${({theme}) => theme.fontRoboto };

    @media only screen and (max-width: 1024px) {
        left:15px;
    }

`;

export const TitleSteps = styled.div`
    display:block;
    font-weight: ${({theme}) => theme.weightBold };
    font-family: ${({theme}) => theme.fontRoboto };
    font-size: 18px;
    line-height: 18px;
    color:#00536e;
    margin-bottom:30px;

    @media only screen and (max-width: 1024px) {
        margin-left:25px;
    }

`;

export const MainTitle = styled.h2`
    display:block;
    font-weight: ${({theme}) => theme.weightRegular };
    font-family: ${({theme}) => theme.fontRoboto };
    font-size: 28px;
    line-height: 42px;
    color:#000;

    @media only screen and (max-width: 600px) {
        font-size: 21px;
        line-height: 32px;
    }
`;