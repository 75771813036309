import React, { Component } from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import { Router, Switch, Redirect} from 'react-router-dom';
import { CMSLoader } from '@cr38te/cr38te-cms';
import {createBrowserHistory} from 'history';

import FirebaseAuthManager from './utils/firebase-authentication-manager';

import { loginScreenRoutes, PATH as LOGIN_SCREEN_PATH} from './screens/login-screen';
import { 
    PATH as CREATE_ACCOUNT_COMPLETED_SCREEN_PATH 
} from "./screens/create-account-completed-screen";

import { businessesScreenRoutes } from './screens/businesses-screen';

const GlobalStyles = createGlobalStyle`
    *, 
    *:before, 
    *:after {
        box-sizing: border-box;
    }
    body {
        margin: 0px;
        overflow: hidden;
        font-family: 'Roboto';
        color: #000;
        background-color:#fafafa;
    }
`;

const defaultTheme = {
    fontRoboto: 'Roboto',
    weightThin: '300',
    weightRegular: '400',
    weightBold: '700',
};

const Container = styled.div`
    ${ ({ width, height }) => `
        position: relative;
        top: 0px;
        bottom: 0px;
        height: ${height}px;
        width: ${width}px;

    @media (max-width: 640px) {
        height: calc( ${height} + 30px );
        overflow-y:scroll;
    }
`}`;

export default class App extends Component {
    authManager = new FirebaseAuthManager();
    state = {
        width: window.innerWidth,
        height: window.innerHeight,
        ready: false,
    };
    history = createBrowserHistory();
    mounted = false;

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
        this.unsubscribes = [
            this.authManager.onManagerReadyStateChanged(this.onManagerReadyStateChanged),
            this.authManager.onAuthenticationStateChanged(this.onAuthenticationStateChanged),
        ];
        this.mounted = true;
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
        this.unsubscribes.forEach( unsubsribe => unsubsribe() );
        this.mounted = false;
        
        (this.unsubscibeNotification && this.unsubscibeNotification());
    }

    onResize = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    onManagerReadyStateChanged = (user) => {
        if (!this.mounted) return;
        this.setState({ ready: true, user });
    }

    onAuthenticationStateChanged = (user) => {
        (this.unsubscibeNotification && this.unsubscibeNotification());
        this.unsubscibeNotification = null;
        
        if (!this.mounted) return;

        this.setState({user});
    }

    render() {
        let { width, height, ready, user, notifications=[]} = this.state;
        let authManager = this.authManager;
        const userAvailable = (user === null || user === undefined);
        const emailVerified = (user && user.emailVerified);
        return (ready ?
            <ThemeProvider theme={defaultTheme}>
                <Container height={height} width={width}>
                    <GlobalStyles />
                    <Router history={this.history} >
                        <Switch>
                            {loginScreenRoutes({authManager})}
                            {userAvailable && <Redirect to={LOGIN_SCREEN_PATH}/> }
                            {!emailVerified && <Redirect to={CREATE_ACCOUNT_COMPLETED_SCREEN_PATH} /> }
                            {businessesScreenRoutes({authManager, user, notifications})}
                        </Switch>
                    </Router>
                </Container>
            </ThemeProvider>
            :
            <CMSLoader animating={true} />
        );
    }
}
