import styled from "styled-components";

const EditorSectionTitle = styled.span`
    font-size: 28px;
    font-weight:400;
    letter-spacing:0.05em;
    display:inline-block;
    width: 100%;
    color:#005270;
    flex: 1;
`;
export default EditorSectionTitle;