import React from 'react';
import styled from 'styled-components';
import CardIcon from '../../../shared-components/icons/card-icon';
import CheckIcon from '../../../shared-components/icons/check-icon';
import FileIcon from '../../../shared-components/icons/file-icon';
import HomeIcon from '../../../shared-components/icons/home-icon';
import InfoIcon from '../../../shared-components/icons/info-icon';
import GlobeIcon from '../../../shared-components/icons/globe-icon';

const Container = styled.div`
    padding: 0;
    width: 100%;
    cursor: pointer;
`;

const InnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding:15px;
    margin-top:0;
    margin-bottom:0;
    margin-left:0;
    margin-right:0;
    border-bottom:1px solid rgba(255,255,255,0.1);
    background-color:${({selected}) => selected ? 'transparent' : '#005270'};
    &:hover {
        background-color:#acc236;
        svg {
            color:black;
        }
    }
    &:hover > span {
        color:black;
    }
    ${({selected}) => selected ? 'svg { color:#acc236; }' : 'svg { color:#00bdeb; }'}
`;

const Title = styled.span`
    margin-left: 10px;
    display: inline;
    color: ${({selected}) => selected ? '#acc236' : 'white'};
    font-weight: 400;
    font-size:15px;
    letter-spacing:0.05em;
    line-height:23px;
    text-transform:none;
    user-select: none;
`;

const Item = ({icon, title, onClick, selected}) => {
    const icons = {
        'home': HomeIcon,
        'info': InfoIcon,
        'card' : CardIcon,
        'check' : CheckIcon,
        'globe': GlobeIcon,
        'file': FileIcon,
    }
    const Icon = icons[icon] || (() => null);
    return (
        <Container 
            onClick={ (e) => {
                e.preventDefault();
                (onClick && onClick(e));
            }}
        >
            <InnerContainer selected={selected}>
                <Icon />
                <Title selected={selected}>{title}</Title>
            </InnerContainer>
        </Container>
    );
}
export default Item;
