import styled from "styled-components";
import React from 'react';
import { CMSLoader } from "@cr38te/cr38te-cms";

export const ButtonContainer = styled.button`
    ${({light}) => light ? 
        `   background-color:#FFF;
            color:#00bdeb;
        `
        :
        `   background-color:#00536e;
            color:#FFF;
        `
    }
    flex: 0;
    border:0;
    padding:17px 62px;
    font-weight: 300;
    font-family: ${({theme}) => theme.fontRoboto };
    font-size:14px;
    border-radius:0;
    display:block;
    text-align:center;
    margin-top:25px;
    transition:all 0.3s linear;
    width:auto;
    min-width:280px;
    margin-right:15px;
    letter-spacing:0.25px;
    min-height:60px;
    cursor:pointer;
    &:disabled {
        opacity: 1;
        background-color:#CCCCCC;
        color:#999999;
        cursor:not-allowed;
    }
    ${({disabled, light})=> !disabled &&
        `&:hover {
            ${light ?
                `
                    background-color:#FFF;
                    color:#005270;        
                `
                :
                `
                    background-color:#005270;
                    color:#ffffff;        
                `
            }
            
        }`
    }
    margin-bottom: 15px;

    @media only screen and (max-width: 600px) {
       width:100%;
       margin-top:7.5px;
       margin-bottom:7.5px;
    }
`;

const Button = ({loading, onClick=(()=>{}), disabled, title, light, className}) => (
    <ButtonContainer 
        onClick={(e) => {
            e.preventDefault(); 
            onClick()
        }}
        disabled={disabled}
        light={light}
        className={className}
    >
        {loading ? 
            <CMSLoader animating={true} position="relative" /> 
            : 
            title
        }
    </ButtonContainer>
);

export default Button;
