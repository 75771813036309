import React from 'react';
import styled from 'styled-components';
import ProgressContent from './progress-content';
import { PATH as BUSINESS_REGISTRATION_INFO_PATH } from '../business-registration-info-screen';
import { PATH as BUSINESS_PAYMENT_PATH } from '../business-payment-screen';
import { PATH as BUSINESS_ASSESSMENT_PATH } from '../business-assessment-screen';
import { PATH as BUSINESS_ONSITE_INSPECTION_PATH } from '../business-on-site-inspection-screen';
import { PATH as BUSINESS_DOWNLOADS_PATH } from '../business-downloads-screen';
import InfoIcon from '../../shared-components/icons/info-icon';
import CardIcon from '../../shared-components/icons/card-icon';
import CheckIcon from '../../shared-components/icons/check-icon';
import GlobeIcon from '../../shared-components/icons/globe-icon';
import FileIcon from '../../shared-components/icons/file-icon';

const Container = styled.div`
    margin:30px auto;
    width:100%;
`;

const StepIndicator = styled.div`
    display:flex;
    align-items:center;
    padding:0 40px;

    @media only screen and (max-width: 1250px) {
        padding:0;
    }

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
    }  
`;

const Step = styled.div`
    display:flex;
    align-items:center;
    flex-direction:column;
    position:relative;
    z-index:1;
    width: calc( 100% / 5 );

    @media only screen and (max-width: 600px) {
        width:100%;
        align-items:flex-start;
        margin-bottom:30px;
    }  

    /* ACTIVE */
    ${({active}) => active && `
        .step-icon {
            background-color:#00bdeb;
            color:white;
        }
        span {
            color:#00bdeb;
        }
        .indicator-line {
            background-color:#00bdeb;
        }
    `}

    /* COMPLETED */
    ${({completed, completedColor='#acc236'}) => completed && `
        span {
            color:${completedColor};
        }
        .step-icon {
            background-color:${completedColor};
        }
        .indicator-line {
            background-color:${completedColor};
        }
    `} 
`;

const StepIcon = styled.div`
    height:50px;
    width:50px;
    border-radius:50%;
    border:2px solid white;
    background-color:#005270;
    text-align:center;
    color:white;
    position:relative;
    z-index:9;
    line-height:50px;
    font-size:14px;
    font-weight:bold;

    .icon {
        min-width: 46px;
        min-height: 46px;
        svg {
            margin-top:13px;
            height:20px;
            width:20px;
            text-align:center;
            align-items:center;
        }
    }
`;

const StepTitle = styled.span`
    text-align:center;
    position:absolute;
    bottom:-40px;
    color:#005270;
    font-size:14px;
    width:240px;

    @media only screen and (max-width: 1250px) {
        width:110px;
    }

    @media only screen and (max-width: 600px) {
        width: calc(100% - 70px);
        align-items:flex-start;
        text-align:left;
        bottom:17px;
        left:70px;
    }  
`;

const IndicatorLine = styled.div`
    width:100%;
    height:2px;
    background-color:#005270;
    flex:1;
    position: absolute;
    top: 26px;
    left: 50%;
    z-index:0;

    @media only screen and (max-width: 600px) {
        left: -4px;
        transform: rotate(90deg);
        width: 60px;
        top: 68px;
    }
    
`;

const IndicationList = styled.ul`
    list-style:none;
    margin:30px auto 60px auto;
    padding:0;
    display:flex;
    align-items:center;
    flex-direction:row;
    position: relative;

    li:nth-child(1) div {
        background-color:#acc236;
    }
    li:nth-child(2) div {
        background-color:#00bdeb;
    }
    li:nth-child(3) div {
        background-color:#005270;
    }

    @media only screen and (max-width: 1010px) {
        flex-direction:column;
    }
`;

const ListItem = styled.li`
    flex-basis:calc( 100% / 3 );
    text-align:center;
    position:relative;
    line-height:40px;
`;

const Bullet = styled.div`
    width:20px;
    height:20px;
    border-radius:50%;
    display:inline-flex;
    position:absolute;
    top:10px;
`;

const ListTitle = styled.span`
    padding-left:30px;
    font-size:14px;
    color:black;
    font-weight:bold;
    text-transform:uppercase;
`;

export const REGISTRATION_STEP = 0;
export const PAYMENT_STEP = 1;
export const ASSESSMENT_STEP = 2;
export const INSPECTION_STEP = 3;
export const CERTIFICATION_STEP = 4;

const IconsTypes = {
    'card': CardIcon,
    'check': CheckIcon,
    'file': FileIcon, 
    'info': InfoIcon,
    'globe': GlobeIcon,
};

const STEP_CONTENT = [
    {
        title: `Registration`,
        message: `Your registration is now being reviewed and you will receive an email when the registration is approved.`, 
        titleButton: `View Registration`,
        path: BUSINESS_REGISTRATION_INFO_PATH, 
        iconTypes: 'info'
    },
    {
        title: `Payment`,
        message: `Your registration and payment has been approved, you will be able to start your online assessment.`,
        titleButton: `See your payment`,
        path: BUSINESS_PAYMENT_PATH,
        iconTypes: 'card'
    },
    {
        title: `Online Assessment`,
        message: `Your registration and payment has been approved, you will be able to start your online assessment.`,
        titleButton: `Go to Online Assessment`,
        path: BUSINESS_ASSESSMENT_PATH,
        iconTypes: 'check'
    },
    {
        title: `On-Site Inspection`,
        message: `You have completed the online assessment, an auditor will be contacted for an on-site inspection at your business.`,
        titleButton: `View On-Site Inspection`,
        path: BUSINESS_ONSITE_INSPECTION_PATH,
        iconTypes: 'globe'
    },
    {
        title: `Certified`,
        message: `Congratulations! You have passed the on-site inspection.`,
        titleButton: `Download certification documents`,
        path: BUSINESS_DOWNLOADS_PATH,
        iconTypes: 'file'
    }
]

const ProgressSteps = ({currentStep=REGISTRATION_STEP, business={}, rootPath}) => {
    const content = STEP_CONTENT[currentStep] || {};
    return (
        <Container>
            <IndicationList>
                <ListItem>
                    <Bullet />
                    <ListTitle>= Completed steps</ListTitle>
                </ListItem>
                <ListItem>
                    <Bullet />
                    <ListTitle>= Current step</ListTitle>
                </ListItem>
                <ListItem>
                    <Bullet />
                    <ListTitle>= Not completed steps</ListTitle>
                </ListItem>
            </IndicationList>
            <StepIndicator>
                {STEP_CONTENT.map( (content, step) => {
                    const Icon = IconsTypes[content.iconTypes] || CheckIcon;
                    const lastIndex = (STEP_CONTENT.length - 1);
                    const drawIndicator = ( step < lastIndex )
                    return (
                        <Step key={`${content.title}`}
                            active={currentStep === step } 
                            completed={currentStep > step || step === lastIndex}
                            completedColor={'#acc236'}
                        >
                            <StepIcon className="step-icon">
                                <Icon className="icon" />
                            </StepIcon>
                            <StepTitle>{`${step + 1}`}. {content.title}</StepTitle>
                            {drawIndicator && <IndicatorLine className="indicator-line" />}
                        </Step>
                    )
                })}
            </StepIndicator>
            <ProgressContent 
                currentStep={currentStep} 
                business={business} 
                toPath={`${rootPath}${content.path}`} 
                title={content.title} 
                content={content.message} 
                titleButton={content.titleButton}
            />
        </Container>
    );
}
export default ProgressSteps;