import React, { useState } from 'react';
import styled from 'styled-components';

const QuestionNumber = styled(({
    prefix, 
    number, 
    detailURL,
    ...props
}) => {
    return <span {...props}>{`${prefix}.${number}`}</span>;
})` 
    color: #005270;
`;

const Question = styled.span``;

const DETAIL_TITLE_WIDTH = 71;
const DetailContainer = styled( ({title, children, optionTitle, onClickOption=(_=>_), editable, visible=true, ...props}) => {
    const [clickingOption, setClickingOption] = useState(false);
    if (!visible) {
        return null;
    }
    return (
        <div {...props} >
            <detail-section-title>{title}:</detail-section-title>
            <detail-section-content>
                {!clickingOption && children}
                {(optionTitle && editable) && 
                    <mark-reviewed-option 
                        onClick={ async () => {
                            if (clickingOption) {
                                return;//already saving...
                            }
                            setClickingOption(true);
                            await onClickOption();
                            setClickingOption(false);
                        }}
                    >
                        {clickingOption ? 'updating...' : optionTitle }
                    </mark-reviewed-option>    
                }        
            </detail-section-content>    
        </div>  
    )
})`
    display: flex;

    detail-section-title { 
        flex: 0; 
        flex-basis: ${DETAIL_TITLE_WIDTH}px;
        font-size: 13px;
    };
    
    detail-section-content { 
        flex: 1;
        font-size: 13px;
        font-weight: ${({boldDetail}) => boldDetail ? '700' : '400'};
    }
    
    mark-reviewed-option {
        margin-left: ${({children}) => children ? '10' : '0'}px;
        color: #005270;
        cursor: pointer;
        user-select: none;
    }
`;

const Answered = styled(({answer, comment, className}) => {
    return (
        <DetailContainer 
            title={'Answered'} 
            className={className}
        >
            {`${answer ?? '-'} ${
                answer ? 
                    ('- ' + (comment ?? 'No comment given.') )
                    : 
                    '' 
                }
            `}
        </DetailContainer>
    );
})``;

const Reviewed = styled(({reviewed, onMarkReviewed=(_=>_), editable, visible, className}) => {
    return (
        <DetailContainer 
            title={'Reviewed'} 
            className={className}
            boldDetail={true}
            optionTitle={reviewed ? 'unmark' : 'mark'}
            onClickOption={ () => onMarkReviewed(!reviewed) }
            editable={editable}
            visible={visible}
        >
            {reviewed ? 'Yes' : 'No'}
        </DetailContainer>
    );
})` `;


const Notes = styled(({notes, onSetNotes=(_=>_), editable, visible, className}) => {
    const [editing, setEditing] = useState(false);
    const [editedNotes, setEditedNotes] = useState(notes);
    let optionTitle;
    if (editing || (notes && notes.length > 0)) {
        optionTitle = editing ? `done` : 'edit';
    }
    else {
        optionTitle = 'add';
    }
    return (
        <DetailContainer 
            title={'Notes'} 
            className={className}
            boldDetail={true}
            optionTitle={optionTitle}
            editingNote={editing}
            onClickOption={ async () => {
                setEditing(!editing) 
                if (editing && editedNotes !== notes) {
                    await onSetNotes(editedNotes);
                }
            }}
            editable={editable} 
            visible={visible}
        >
            {editing ? 
                <textarea
                    type="textarea" 
                    defaultValue={notes} 
                    onChange={ e => setEditedNotes(e.target.value) } 
                />
                : 
                (notes ? notes : '-')
            }
        </DetailContainer>
    );
})` 
    detail-section-content {
        display: flex;
        textarea {
            flex: 1;

            margin-left: ${-DETAIL_TITLE_WIDTH}px;
            
            min-height: 100px;
            
            outline: none;
            padding: 5px;
            font-size: 13px;
        }
    }
`;

const THIN_PADDING = 10;
const NUMBER_WIDTH = 40;
const Container = styled.div`
    position: relative;

    background: white;
    border-top: solid 1px lightgray;
    border-bottom: solid ${({last}) => last ? 1 : 0}px lightgray;

    padding: ${THIN_PADDING}px;  
    padding-left: ${THIN_PADDING + NUMBER_WIDTH + THIN_PADDING}px;
    

    display: flex;
    flex-direction: column;

    ${QuestionNumber} {
        position: absolute;
        left: ${THIN_PADDING}px;
        top: ${THIN_PADDING}px; 
        width: ${NUMBER_WIDTH}px;   
    }

    ${Question} {
        max-width: 650px;
    }

    ${DetailContainer} {
        margin-top: ${1.5 * THIN_PADDING}px;
        max-width: calc(650px - ${THIN_PADDING + NUMBER_WIDTH + THIN_PADDING}px);
        
        @media(max-width: 320px) {
            margin-left: ${({printable}) => printable ? 0 : - (NUMBER_WIDTH + THIN_PADDING)}px;
            
        }

        mark-reviewed-option {
            display: ${({printable}) => printable ? 'none' : 'initial'};
        }
    }

    ${Notes} {
        margin-bottom: ${THIN_PADDING}px;
    }
`;

const AssessmentSectionSummaryItem = ({ 
    prefix, 
    number, 
    question, 
    answer, 
    answerComment, 
    reviewed, 
    canViewReviewed=false,
    canEditReviewed=false,
    onMarkReviewed, 
    auditorNotes, 
    canViewNotes=false,
    canEditNotes=false,
    onSetNotes,
    last=false,
    printable=false,
}) => {
    return (
        <Container last={last} printable={printable} >
            <QuestionNumber 
                prefix={prefix} 
                number={number} 
            />
            <Question>{question}</Question>
            <Answered answer={answer} comment={answerComment} />
            <Reviewed 
                reviewed={reviewed} 
                onMarkReviewed={onMarkReviewed} 
                visible={canViewReviewed}
                editable={canEditReviewed}
            />
            <Notes 
                notes={auditorNotes} 
                onSetNotes={onSetNotes} 
                visible={canViewNotes} 
                editable={canEditNotes} 
            />
        </Container>
    );
}

export default AssessmentSectionSummaryItem;