import React, { useState } from "react";
import Button from "../../shared-components/button";
import FormContainer from "./form-container";
import { Hyperlink, TitleSteps, MainTitle } from "./texts";
import { Row, Column } from "./table";
import { CMSCheckbox } from "@cr38te/cr38te-cms";
import styled from "styled-components";
import StepContainer from "./step-container";

export const PATH = "/register";

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: 600px) {
        flex-direction:column;
    }
    
`;

const HyperLink = styled.a`
    color:#005270;
    text-decoration:underline;
    font-size:14px;
    margin-top:4px;
`;

const RegisterBusinessScreenStepThree = ({ visible = true, loadingRegistration=false, onClickClose = (_ => _), onClickRegister = (_ => _), onClickPrevious = (_ => _) }) => {
    let [confirmCodesOfPractive, setConfirmCodesOfPractive] = useState(false);
    let [confirmGeneralTermsAndConditions, setConfirmGeneralTermsAndConditions] = useState(false);
    let [confirmRegulationsAQS, setConfirmRegulationsAQS] = useState(false);

    return (
        <StepContainer visible={visible} >
            <Row>
                <Column>
                    <Hyperlink href='/' onClick={(e) => {
                        e.preventDefault();
                        onClickClose();
                    }}>
                        X
                    </Hyperlink>
                    <TitleSteps>
                        Register a business (Step 3 of 3)
                    </TitleSteps>
                </Column>
            </Row>
            <Row>
                <Column>
                    <MainTitle>
                        The last step is the approval of our agreements.
                    </MainTitle>
                    <FormContainer>
                        <CMSCheckbox
                            id="codesOfPractive"
                            title={
                                <HyperLink 
                                    href="https://storage.googleapis.com/aruba-quality-seal.appspot.com/terms-agreements/Codes%20of%20Practice%20FInal.pdf"
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                >
                                    Codes of Practice
                                </HyperLink>
                            }
                            onChange={ ({target}) => setConfirmCodesOfPractive(target.value) }
                            value={confirmCodesOfPractive}
                        />
                        <CMSCheckbox
                            id="generalTermsAndConditions"
                            title={
                                <HyperLink 
                                    href="https://storage.googleapis.com/aruba-quality-seal.appspot.com/terms-agreements/General%20Terms%20and%20Conditions%20.pdf" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                >
                                    General Terms and Conditions
                                </HyperLink>
                            }
                            onChange={ ({target}) => setConfirmGeneralTermsAndConditions(target.value) }
                            value={confirmGeneralTermsAndConditions}
                        />
                        <CMSCheckbox
                            id="regulationsAQS"
                            title={
                                <HyperLink 
                                    href="https://storage.googleapis.com/aruba-quality-seal.appspot.com/terms-agreements/Regulations%20Aruba%20Quality%20Seal%20Final.pdf" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                >
                                    Regulations Aruba Quality Seal
                                </HyperLink>
                            }
                            onChange={ ({target}) => setConfirmRegulationsAQS(target.value) }
                            value={confirmRegulationsAQS}
                        />
                    </FormContainer>
                    <ButtonContainer>
                        <Button
                            onClick={onClickPrevious}
                            title="Previous"
                            light={false}
                        />
                        <Button
                            onClick={onClickRegister}
                            loading={loadingRegistration}
                            disabled={!(confirmCodesOfPractive && confirmGeneralTermsAndConditions && confirmRegulationsAQS)}
                            title="Register"
                        />
                    </ButtonContainer>
                </Column>
            </Row>
        </StepContainer>
    );
}
export default RegisterBusinessScreenStepThree;
