import React from 'react';
import styled from 'styled-components';
import UserItem, {SWITCH_TO_MOBILE_WIDTH} from './audit-log-item';

const Header = styled(UserItem)`
    .property {
        font-weight: 600;
    }

    @media ( max-width: ${SWITCH_TO_MOBILE_WIDTH}px ) {
        .time {
            text-decoration: underline lightgrey;
        }
        
        .description {
            text-decoration: underline lightgrey;
        }
    }
`;

const ListHeader = () => {
    return <Header date="Time" userRole="User" type="Type" description="Description" />
}
export default ListHeader;