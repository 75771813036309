import React from 'react';
import { Route } from "react-router-dom";
import DetailHeader, { applyIconStyle } from '../../shared-components/detail-header';
import HomeIcon from '../../shared-components/icons/home-icon';
import ProgressOverview, {
    CERTIFICATION_STEP, 
    INSPECTION_STEP,
    ASSESSMENT_STEP,
    PAYMENT_STEP,
    REGISTRATION_STEP
} from './progress-steps';
import GroupElements from '../../shared-components/group-elements';

import Container from '../../shared-components/detail-section-container';

export const PATH = '/overview';

const Icon = applyIconStyle(HomeIcon);

const getCurrentProgressStep = (business) => {
    const registration = business.registration || {};
    const assessment = business.assessment || {};

    if (registration.onSiteInpectionPassed === true) {
        return CERTIFICATION_STEP;
    }
    if (assessment.completed) {
        return INSPECTION_STEP;
    }
    if (registration.isPaid) {
        return ASSESSMENT_STEP;
    }
    if (registration.isApproved) {
        return PAYMENT_STEP;
    }
    return REGISTRATION_STEP;
}

const BusinessOverview = ({business={}, rootPath}) => {
    const step = getCurrentProgressStep(business);

    return (
        <Container>
            <GroupElements>
                <DetailHeader
                    Icon={Icon}
                    title="Business Overview"
                    detail=""
                />
                <ProgressOverview currentStep={step} business={business} rootPath={rootPath} />
            </GroupElements>
        </Container>
    );
}
export default BusinessOverview;

export const businessOverviewScreenRoutes = ({ rootPath, businessDetailsLoading, ...props }) => {
    return [
        <Route path={`${rootPath}${PATH}`} exact key={'business-overview-screen'} render={(routeProps) =>
            <BusinessOverview {...props} {...routeProps} rootPath={rootPath} />
        } />
    ];
}