import React from 'react';
import styled from 'styled-components';
import { CMSHeader } from '@cr38te/cr38te-cms';
import AQSLogo from '../../businesses-screen/aqs.svg';
import Notification from '../../../shared-components/notification-list-menu';
import { useHistory } from 'react-router';

export const PATH = "/login/welcome";

const Header = styled(CMSHeader)`
    background-color:#005270;
    position: static;
    min-height: 65px;
    height: 65px;
    width: 100%;
    z-index:1;
    
    @media (max-width: 768px) {
        min-height: 50px;
        height: 50px;
    }
`;

const Container = styled.div`
    flex: 1;
    padding-left: 15px;
    cursor: pointer;
`;

const Title = styled.span`
    font-size: 20px;
    color: white;
    float:left;
    line-height:42px;
    margin-left:15px;

    @media(max-width: 320px) {
        display: none;
    }
`;

const Image = styled.img`
    width:40px;
    float:left;
    display:block;
`;

const NavBar = ({onClickUserIcon, user}) => {
    const history = useHistory();
    return (
        <Header
            showMenuIcon={false}
            onClickUserIcon={onClickUserIcon}
            user={user}
        >
            <Container
                onClick={ () => history.push('/') } //Go to home when tapped.
            >
                <Image src={AQSLogo} alt="Aruba Quality Seal" />
                <Title>
                    Aruba Quality Seal 
                </Title>
            </Container>
            <Notification user={user} />
        </Header>
    );
}
export default NavBar;
