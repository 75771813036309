import styled from "styled-components";

const GroupElements = styled.div`
    background-color:#ffffff;
    box-shadow:0 1px 2px rgba(0,0,0,.05),0 0 0 1px rgba(63,63,68,.1);
    padding:30px;

    @media only screen and (max-width: 600px) {
        padding:15px;
    }
`;
export default GroupElements;