import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CommentBox from '../comment-box';
import EditorSectionTitle from '../editor-section-title';
import Button from '../../shared-components/button';
import MessageList from '../message-list';
import ManagedUploadFileField from '../../shared-components/managed-file-upload-field';
import FirebaseSingleFileUploadManager from '../../utils/firebase-single-file-upload-manager';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 25px 0px 25px 0px;
    border-bottom: solid 1px lightgrey;
`;

const Message = styled.span`
    margin-top: 5px;
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
    color:#808080;
`;

const AuditorFeedbackEditor = ({visible=false, onSend, editorID, messages=[], userUID, userRole}) => {
    const [uploadManager] = useState( new FirebaseSingleFileUploadManager() );
    const [currentID, setCurrentID] = useState(editorID);
    const [sending, setSending] = useState(false);
    const [message, setMessage] = useState('');
    const [filePath, setFilePath] = useState('');

    useEffect( () => {
        if(currentID !== editorID) {
            setCurrentID(editorID);
            setSending(false);
            setMessage('');
        }
    }, [editorID, currentID])

    if (!visible) {
        return null;
    }

    const sendToName = userRole === 'partner' ? 'auditor' : 'partner';

    return (
        <Container>
            <EditorSectionTitle>Auditor Feedback</EditorSectionTitle>
            <Message>Send and receive messages from the {sendToName}</Message>
            <MessageList 
                visible={messages.length} 
                messages={messages} 
                userUID={userUID} 
                userRole={userRole}
            />
            <CommentBox
                placeholder="Message"
                value={message}
                onChange={ ({target}) => setMessage(target.value)}
            />
            <ManagedUploadFileField
                id='feedback-file-upload'
                title="Message Attachment"
                fileUploadManager={uploadManager}
                onUploadCompleted={ setFilePath }
            />
            <Button 
                title="Send" 
                loading={sending} 
                disabled={!(message.length > 0 || filePath.length > 0)} 
                onClick={ async () => {
                    setSending(true);
                    try {
                        await onSend(message, filePath);
                        setMessage('');
                        setFilePath('');
                        setFilePath('');
                    }
                    catch(error) {
                        alert(error);
                    }
                    setSending(false);
                }}
            />
        </Container>
    );
}

export default AuditorFeedbackEditor;