import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CommentBox from '../../../shared-components/comment-box';
import EditorSectionTitle from '../../../shared-components/editor-section-title';
import MarkAsReviewedCheckbox from './mark-as-reviewed-checkbox';
import { getSaveMessage} from '../helpers';
import {timeoutSave} from '../../../utils/saving';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 25px 0px 25px 0px;
    border-bottom: solid 1px lightgrey;
`;

const Message = styled.span`
    margin-top: 5px;
    font-size: 12px; 
    color: lightgrey;
`;

const SaveInfo = styled.span`
    margin-top: 5px;
    font-size: 12px; 
`;

const AuditorNotesEditor = ({visible=false, onSetNotes, numberPrefix, question, onSetViewed}) => {
    const [fullNumber, setFullNumber] = useState(numberPrefix+question.number);
    let [currentNotes, setCurrentNotes] = useState(question.auditorNotes);
    let [savingNotes, setSavingNotes] = useState(false);
    let [didSaveBefore, setDidSaveBefore] = useState(false);
    let [notesError, setNotesError] = useState(false);
    let [notesTimeoutTimer, setNotesTimeoutTimer] = useState(false);
    let [currentChecked, setCurrentChecked] = useState(question.reviewed || false);
    let [checkedError, setCheckedError] = useState(false);
    let [savingChecked, setSavingChecked] = useState(false);

    useEffect( () => {
        const newFullNumber = numberPrefix+question.number;
        if(fullNumber !== newFullNumber) {
            setFullNumber(newFullNumber);
            setCurrentNotes(question.auditorNotes);    
            setSavingNotes(false);
            setDidSaveBefore(false);
            setNotesError(false);
            setNotesTimeoutTimer(false);
            setCurrentChecked(question.reviewed || false);
            setCheckedError(false);
            setSavingChecked(false);
        }
        if (!savingChecked && question.reviewed !== currentChecked) {
            setCurrentChecked(question.reviewed);
        }
    }, [numberPrefix, question.number, question.auditorNotes, question.reviewed, currentChecked, savingChecked, fullNumber]);

    if (!visible) {
        return null;
    }

    let saveMessage = getSaveMessage(savingNotes, notesError, question, onSetNotes, didSaveBefore);
    return (
        <Container>
            <EditorSectionTitle>Auditor Notes</EditorSectionTitle>
            <Message>Notes are not visible to the vendor.</Message>
            <CommentBox
                value={ currentNotes || '' }
                onChange={ ({target}) => {
                    setCurrentNotes(target.value);
                    timeoutSave(notesTimeoutTimer, setNotesTimeoutTimer, async () => { 
                        setSavingNotes(true);
                        try {
                            await onSetNotes(target.value);
                            setNotesError(null);
                        }
                        catch (notesError) {
                            setNotesError(notesError);
                        }
                        setSavingNotes(false);
                        setDidSaveBefore(true);
                    });
                }}
            />
            <SaveInfo>{saveMessage}</SaveInfo>
            <MarkAsReviewedCheckbox 
                checked={currentChecked}
                onChange={  async checked => {
                    setCurrentChecked(checked);
                    setSavingChecked(true);
                    try {
                        await onSetViewed(checked);
                        setCheckedError(null);
                    }
                    catch (notesError) {
                        setCheckedError(notesError);
                    }
                    setSavingChecked(false);
                }}
                saving={savingChecked}
                error={checkedError}
            />
        </Container>
    );
}

export default AuditorNotesEditor;