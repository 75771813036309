import React from "react";
import styled from "styled-components";
import BusinessItemContainer from './block-item-container';

const AttributeContainer = styled.div`
    display: flex;
    padding-top: 15px;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color:#ffffff;
    &:hover {
        background-color:#f1f1f1;
        cursor:pointer;
    }
`;

const Name = styled.span`
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 15px;
    color:#005270;
`;
const Detail = styled.span`
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 15px;
`;

const BusinessItem = ({ name, nameID, onClick }) => {
    return (
        <BusinessItemContainer onClick={onClick}>
            <AttributeContainer>
                <Name>{name}</Name>
                <Detail>{nameID || "No Information Available"}</Detail>
            </AttributeContainer>

        </BusinessItemContainer>
    );
};

export default BusinessItem;
