import React from 'react';

export const getQuestionTitlesForSection = (assessment={}, section=0) => {
    const sections = assessment.sections || [];
    const sectionObject = sections[section] || {};
    const questions = sectionObject.questions || [];
    return questions.map( q => (`${sectionObject.prefix}.${q.number}`) );
}

export const hasFirstSectionAndQuestion = (assessment) => {
    if (assessment.sections && assessment.sections.length > 0) {
        let section = assessment.sections[0];
        if (section.questions && section.questions.length > 0) {
            return true;
        }
    }
    return false;
}

export const getSectionAndQuestionIndices = (match) => {
    const sectionIndex = parseInt((match.params||{}).sectionIndex);
    const questionIndex = parseInt((match.params||{}).questionIndex);
    return [sectionIndex, questionIndex];
}

export const getCurrentSection = (assessment={}, sectionIndex) => {
    const sections = assessment.sections || [];
    return sections[Math.max(sectionIndex, 0)] || {questions: []};
}

export const getCurrentQuestion = (assessment={}, sectionIndex, questionIndex) => {
    const section = getCurrentSection(assessment, sectionIndex);
    const questions = section.questions || [];
    return questions[questionIndex];
}

export const getSaveMessage = (saving, error, value={}, onRetry=(_=>_)) => {
    if (saving) {
        return <>Saving...</>;
    }
    else if (error) {
        return <>
            An error occured while saving. 
            <a href="/" onClick={ async e => {
                e.preventDefault();
                onRetry();
            }}>
                Try again.
            </a>
        </>
    }
    else if(value) {
        return <></>
    }
    else {
        return <>Has not been set yet...</>
    }
}
