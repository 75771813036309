import React from 'react';
import styled from 'styled-components';
import Message from './message';

import 'firebase/storage';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding:30px;
    border: solid 1px lightgrey;
    margin-top: 15px;   
    background-color:rgba(241, 231, 221, 0.4);
    box-shadow:inset 0 1px 2px 2px rgba(0,0,0,0.05);
    height:550px;
    overflow-y:scroll;
    display:block;

    @media (max-width: 640px) {
        height:auto;
        overflow-y:auto;
        padding:15px;
    }
`;

const MessageList = ({visible=true, messages=[], userUID, userRole}) => {
    // const messagesEndRef = useRef(null);

    // const scrollToBottom = () => {
    //     messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    // };

    // useEffect(scrollToBottom, messages);

    if (!visible) {
        return null;
    }
    const otherUserName = userRole === 'partner' ? 'Auditor' : 'Partner';

    return (
        <Container>
            {messages.map( (m, i) => 
                <Message
                    key={i}
                    message={m.message} 
                    fileName={m.fileName}
                    fileURL={m.fileURL}
                    filePath={m.filePath}
                    userName={ m.uid === userUID ? 'You' : otherUserName}
                    usernameSide={m.uid === userUID ? 'left' : 'right'}
                    dateSent={m.dateSent}
                />    
            )}
            {/* <div ref={messagesEndRef} /> */}
        </Container>
    );
}

export default MessageList;