import React from 'react';
import styled from "styled-components";


const Container = styled.div`
    display: flex;
    margin-top: 15px;
    align-items: center;
`;

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const CheckboxInput = styled.input`
    flex: 0;
    flex-basis: 20px;
`;

const Title = styled.span`
    flex: 1;
    font-size: 19px;
`;


const Message = styled.span`
    margin-top: 5px;
    font-size: 10px; 
    color: lightgrey;
`;


const MarkAsReviewedCheckbox = ({checked=false, onChange=(_=>_), saving=false}) => {
    return (
        <InnerContainer>
            <Container>
                <CheckboxInput 
                    id="mark-as-reviewed"
                    type="checkbox" 
                    checked={checked} 
                    onChange={ ({target}) => {
                        onChange(target.checked);
                    }}
                    disabled={saving}
                />
                <Title htmlFor="mark-as-reviewed">
                    Mark As Reviewed 
                </Title>    
            </Container>
            <Message>Mark as reviewed to help keep track of reviewed questions.</Message>
        </InnerContainer>
    )
};
export default MarkAsReviewedCheckbox;
