import React from 'react';
import styled from 'styled-components';
import SVG from '../../../../shared-components/icons/svg'

const Container = styled.div`
    min-width: 30px;
    width: 30px;
    max-width: 30px;
    height: 30px;
    min-height: 30px;
    max-height: 30px;

    &:hover {
        cursor: pointer;
        color: grey;
    }

    &:active {
        color: initial;
    }
`;



const BackButton = ({...props}) => (
    <Container {...props} >
        <SVG
            aria-hidden="true"
            focusable="false"
            data-prefix="fad"
            data-icon="arrow-circle-left"
            className="svg-inline--fa fa-arrow-circle-left fa-w-16"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
        >
            <g
                className="fa-group"
            >
                <path
                    className="fa-secondary"
                    fill="currentColor"
                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm160 256a23.94 23.94 0 0 1-24 24H209.4l75.5 72.4a24 24 0 0 1 .4 34.3l-11 10.9a23.9 23.9 0 0 1-33.9 0L107.7 272.9a23.9 23.9 0 0 1 0-33.9l132.7-132.6a23.9 23.9 0 0 1 33.9 0l11 10.9a24 24 0 0 1-.4 34.3L209.4 224H392a23.94 23.94 0 0 1 24 24z"
                    opacity="0.4"
                >
                </path>
                <path
                    className="fa-primary"
                    fill="currentColor"
                    d="M285.3 394.7l-11 10.9a23.9 23.9 0 0 1-33.9 0L107.7 272.9a23.9 23.9 0 0 1 0-33.9l132.7-132.6a23.9 23.9 0 0 1 33.9 0l11 10.9a24 24 0 0 1-.4 34.3L209.4 224H392a23.94 23.94 0 0 1 24 24v16a23.94 23.94 0 0 1-24 24H209.4l75.5 72.4a24 24 0 0 1 .4 34.3z"
                >
                </path>
            </g>
        </SVG>
    </Container>
);

export default BackButton;