import styled from "styled-components";
import React from "react";
import Field from '../field';
import ProgressBar from '../../utils/progress-bar';

let Container = styled.div``;

const UploadFileField = (props) => {
    let {
        id,
        title,
        message,
        accept,
        uploadProgress,
        onFilesChange,
        onValueChange,
        error,
        ...otherProps
    } = props;

    return (
        <Container>
            <Field
                title={title}
                renderInput={() => (
                    <input
                        id={id}
                        type="file"
                        accept={accept}
                        className="inputfile"
                        onChange={ (event) => {
                            (onFilesChange && onFilesChange(event.target.files));
                            event.preventDefault();
                        }}
                    />
                )}
                message={message}
                error={error}
                {...otherProps}
            />
            {(uploadProgress > 0) &&
                <ProgressBar progress={uploadProgress} />
            }
        </Container>
    );
}

export default UploadFileField;