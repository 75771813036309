import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import styled from 'styled-components';
import 'firebase/storage';

const Link = styled.a`
    color:red;
    text-decoration:underline;
    font-size:14px;
    line-height:28px;
`;

const MediaFileLink = ({fileName, filePath, fileURL, isPublicURL=false, className}) => {
    const [publicURL, setPublicURL] = useState(isPublicURL ? fileURL : null);
    useEffect( () => {
        if (!isPublicURL && !publicURL) {
            firebase.storage().ref(filePath).getDownloadURL().then( url => {
                setPublicURL(url);
            }).catch( error => {
                if (error.code === 'storage/unauthorized') {
                    alert(`User does not have permission to view this file "${fileName}".`);
                }
                else {
                    alert(error.errorMessage);
                }
                console.error(error);
            });
        }
    });

    return (
        <Link 
            href={ publicURL || fileURL }
            enabled={ Boolean(publicURL) }
            target="_blank"
            rel="noopener noreferrer"
            className={className}
        >
            {fileName || 'File'}
        </Link>
    );
}

export default MediaFileLink; 