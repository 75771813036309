import React from 'react';
import styled from 'styled-components';
import UserItem from './user-item';

const Header = styled(UserItem)`
    
    .email {
        font-weight: 600;
    }
    
    .property {
        font-weight: 600;
    }
    
    .options-container {
        opacity: 0;
    }

    .email-container .role-property {
        display: none;
    }
`;

const ListHeader = () => {
    return <Header email="Email" created="Created" signedIn="Signed In" userRole="Role" />
}
export default ListHeader;