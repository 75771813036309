import React from 'react';
import styled from 'styled-components';
import Header from './header';
import Item from './item';
import {useHistory} from 'react-router';

import {PATH as BUSINESSES_PATH} from '../../businesses-screen/index';
import {PATH as BUSINESS_OVERVIEW_PATH} from '../../business-overview-screen';
import {PATH as BUSINESS_REGISTRATION_INFO_PATH} from '../../business-registration-info-screen';
import {PATH as BUSINESS_PAYMENT_PATH} from '../../business-payment-screen';
import {PATH as BUSINESS_ASSESSMENT_PATH} from '../../business-assessment-screen';
import {PATH as BUSINESS_ON_SITE_INSPECTION_PATH} from '../../business-on-site-inspection-screen';
import {PATH as BUSINESS_DOWNLOADS_PATH} from '../../business-downloads-screen';

export const PATH = "/login/welcome";

const Container = styled.div`${({menuOpen=false}) => ` 
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 280px;
    @media (max-width: 768px) {
        width: ${menuOpen ? '100%' : '0px'};
    }
`}`;

const MenuContainer = styled.div` ${({menuOpen=false}) => `
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 280px;
    z-index: 1;
    background-color: #005270;
    @media (max-width: 768px) {
        margin-left: ${menuOpen ? '0px' : '-280px'}
    }
    transition: 300ms;
    overflow-y: scroll;
`}`;

const DismissAreaContainer = styled.div` ${({menuOpen=false}) => `
    position: absolute;
    float: right;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: 0;
    background-color: rgba(0,0,0,0.5);
    opacity: 0;
    @media (max-width: 768px) {
        opacity: ${menuOpen ? '1' : '0'};
    }
    transition: opacity 300ms;
`}`;


const BottomContainer = styled.div` ${({menuOpen=false}) => `
    position:absolute;
    bottom:0;
    z-index:2;
    display: flex;
    flex-direction: row;
    padding:15px;
    margin-top:0;
    margin-bottom:0;
    margin-left:0;
    margin-right:0;
    border-bottom:1px solid rgba(255,255,255,0.1);
    background-color:#00bdeb;
    width: 280px;
    @media (max-width: 768px) {
        margin-left: ${menuOpen ? '0px' : '-280px'}
    }
    transition: 300ms;
`}`;

const MetaTitle = styled.span` ${({menuOpen=false}) => `
    margin-left: 10px;
    font-weight: 400;
    font-size:12px;
    color:black;
    letter-spacing:0.05em;
    line-height:23px;
    text-transform:uppercase;
    user-select: none;
`}`;

const HyperLink = styled.a` ${({menuOpen=false}) => `
    font-weight: 400;
    font-size:12px;
    color:black;
    letter-spacing:0.05em;
    line-height:23px;
    text-transform:none;
    user-select: none;
`}`;

const Menu = ({menuOpen=true, onDismiss, rootPath, selectedItem}) => {
    const history = useHistory();
    const onClick = (path) => () => {
        history.push(path);
        (onDismiss && onDismiss());
    }
    let selectedRoute = `/${selectedItem}`;
    return (
        <Container
            menuOpen={menuOpen}
        >
            <MenuContainer 
                menuOpen={menuOpen}
            >
                <Header 
                    onClick={onClick(`${BUSINESSES_PATH}`)} 
                />
                <Item 
                    icon="home" 
                    title="Business Overview" 
                    onClick={onClick(`${rootPath}${BUSINESS_OVERVIEW_PATH}`)} 
                    selected={selectedRoute === BUSINESS_OVERVIEW_PATH}
                />
                <Item 
                    icon="info" 
                    title="Registration" 
                    onClick={onClick(`${rootPath}${BUSINESS_REGISTRATION_INFO_PATH}`)} 
                    selected={selectedRoute === BUSINESS_REGISTRATION_INFO_PATH}
                />
                <Item 
                    icon="card" 
                    title="Payment" 
                    onClick={onClick(`${rootPath}${BUSINESS_PAYMENT_PATH}`)} 
                    selected={selectedRoute === BUSINESS_PAYMENT_PATH}
                />
                <Item 
                    icon="check" 
                    title="Assessment" 
                    onClick={onClick(`${rootPath}${BUSINESS_ASSESSMENT_PATH}`)} 
                    selected={selectedRoute === BUSINESS_ASSESSMENT_PATH}
                />
                <Item 
                    icon="globe" 
                    title="On-Site Inspection" 
                    onClick={onClick(`${rootPath}${BUSINESS_ON_SITE_INSPECTION_PATH}`)} 
                    selected={selectedRoute === BUSINESS_ON_SITE_INSPECTION_PATH}
                />
                <Item 
                    icon="file" 
                    title="Downloads" 
                    onClick={ onClick(`${rootPath}${BUSINESS_DOWNLOADS_PATH}`) } 
                    selected={selectedRoute === BUSINESS_DOWNLOADS_PATH}
                />
            </MenuContainer>
            <BottomContainer
                menuOpen={menuOpen}
            >
                <MetaTitle>
                    Engineered by: <HyperLink href="https://www.cr38te.com" title="Engineered by CR38TE" target="_blank">CR38TE</HyperLink>
                </MetaTitle>
            </BottomContainer>
            <DismissAreaContainer 
                menuOpen={menuOpen}
                onClick={onDismiss}
            />
        </Container>
    );
}
export default Menu;
