import React from 'react';
import styled from 'styled-components';
import { CMSHeader } from '@cr38te/cr38te-cms';
import Notification from '../../../shared-components/notification-list-menu';
import { useHistory } from 'react-router';

import { PATH as REGISTER_BUSINESS_SCREEN_PATH } from '../../../screens/register-business-screen';
import BusinessListMenu from './business-list-menu';

export const PATH = "/login/welcome";

const Header = styled(CMSHeader)`
    background-color:#005270;   
    flex: 0;
    min-height: 64px;
    height: 64px;
    border-bottom: 1px solid rgba(255,255,255, 0.1);
    width: 100%;
    @media (max-width: 768px) {
        min-height: 50px;
        height: 50px;
    }
    z-index: 2;
`;

const Container = styled.div`
    flex: 1;
    display:flex;
    justify-content:space-between;
    align-items:center;
    @media (min-width: 768px) {
        padding-left: 25px;
    }
`;


const DetailNavBar = ({ businesses, selectedBusinessID, onClickMenuIcon, onClickUserIcon, onChangeBusiness, user, onReloadBusinesses}) => {
    let showMenuIcon = (window.innerWidth < 768);
    let history = useHistory();
    
    const otherBusinesses = businesses.filter( b => b.id !== selectedBusinessID ).slice(0, 10);
    otherBusinesses.sort( (b1, b2) => 
        (b2.lastViewed || b2.updated)._seconds - (b1.lastViewed || b1.updated)._seconds
    );
    
    return (
        <Header
            showMenuIcon={showMenuIcon}
            onClickMenuIcon={onClickMenuIcon}
            onClickUserIcon={onClickUserIcon}
            user={user}
        >
            <Container>
                <BusinessListMenu
                    title={ selectedBusinessID }
                    onClickSeeAllBusinesses={ () => history.push('/') }
                    onClickRegisterBusiness={ () => history.push(REGISTER_BUSINESS_SCREEN_PATH) }
                    businesses={ otherBusinesses }
                    onClickBusiness= { b => {
                        (onChangeBusiness && onChangeBusiness(b.id));
                    }}
                />
                {true && <Notification user={user} id='business-detail-notification-select' onReload={onReloadBusinesses} />}
            </Container>
        </Header>
    );
}
export default DetailNavBar;
