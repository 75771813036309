import React, { Component } from 'react';
import { Route } from "react-router-dom";
import UserList from './user-list';
import PagedList from '../../../shared-components/paged-list';
import { getUsers } from '../../../utils/server-functions';

export const PATH = '/users';

class UsersSection extends Component {

    render() {
        const {
            userRole
        } = this.props;
        return (
            <PagedList
                loadResultsHandler={ getUsers }
                ListComponent={ 
                    ({role, ...p}) => (
                        <UserList 
                            activeUserRole={userRole} 
                            userRole={role} 
                            {...p} 
                        />
                    )
                }
            />
        );
    }
}
export default UsersSection;

export const userSectionRoutes = ({ rootPath, ...props }) => {
    return [
        <Route path={`${rootPath}${PATH}`} exact key={'account-settings-screen-users-section'} render={(routeProps) =>
            <UsersSection {...props} {...routeProps} />
        } />
    ];
}