import React, { useEffect } from 'react';
import { Route} from "react-router-dom";
import styled from 'styled-components';
import AssessmentDetailHeader from './assessment-detail-header';
import QuestionEditor from './question-editor';
import AuditorNotesEditor from './auditor-notes-editor';
import AuditorFeedbackEditor from '../../shared-components/auditor-feedback-editor';
import OriginalGroupElements from '../../shared-components/group-elements';
import { 
    hasFirstSectionAndQuestion, 
    getSectionAndQuestionIndices, 
    getCurrentSection, 
    getCurrentQuestion 
} from './helpers';
import { 
    setBusinessAssessmentAuditorNotes, 
    markBusinessAssessmentQuestionAsReviewed, 
    addBusinessAssessmentAuditorFeedbackMessage, 
    setBusinessAssessmentQuestionAnswer,
    markBusinessAssessmentQuestionAsLastViewed
} from '../../utils/server-functions';
import RelatedDocuments from './related-documents';
import { hasRoles } from '../../utils/user';

export const ROOT_PATH = '/detail';
export const PATH = `${ROOT_PATH}/:sectionIndex?/:questionIndex?`;

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: #f5f5f5;
    min-height:100%;
    height:auto;

    @media only screen and (max-width: 600px) {
        padding:0;
    }
`;

const GroupElements = styled(OriginalGroupElements)`
    @media only screen and (max-width: 600px) {
        width:100%;
        box-shadow:none;
    }
`;

const BusinessAssessmentDetailScreen = ({businessID, rootPath, history, match={}, assessment, userRole, user={}, reloadBusinessDetails}) => {
    useEffect( () => {
        let {sectionIndex, questionIndex} = match.params;
        if ( !(sectionIndex && questionIndex) && hasFirstSectionAndQuestion(assessment) ) {
            history.push(`${rootPath}${ROOT_PATH}/0/0`);
        }
    });

    const [sectionIndex, questionIndex] = getSectionAndQuestionIndices(match);
    if (isNaN(sectionIndex) || isNaN(questionIndex)) {
        return null;
    }

    const currentSection = getCurrentSection(assessment, sectionIndex);
    const currentQuestion = getCurrentQuestion(assessment, sectionIndex, questionIndex);
    return (
        <Container>
            <GroupElements>
                <AssessmentDetailHeader 
                    onClickBack={ () => history.push(rootPath) }
                    assessment={assessment}
                    sectionIndex={sectionIndex} 
                    questionIndex={questionIndex}
                    onSectionChanged={ sectionIndex => {
                        history.push(`${rootPath}${ROOT_PATH}/${sectionIndex}/0`);
                        markBusinessAssessmentQuestionAsLastViewed(businessID, sectionIndex, 0);
                        assessment.lastViewedQuestion = {sectionIndex: sectionIndex, questionIndex: 0};
                    }}
                    onQuestionChanged={ questionIndex => {
                        history.push(`${rootPath}${ROOT_PATH}/${sectionIndex}/${questionIndex}`) 
                        markBusinessAssessmentQuestionAsLastViewed(businessID, sectionIndex, questionIndex);
                        assessment.lastViewedQuestion = {sectionIndex: sectionIndex, questionIndex: questionIndex};
                    }}
                />
                <QuestionEditor
                    numberPrefix={currentSection.prefix}
                    question={currentQuestion}
                    canEditAnswer={ hasRoles(userRole, 'admin', 'partner') }
                    goToPreviousQuestion={ () => {
                        const previusSectionQuestionsCount = getCurrentSection(assessment, sectionIndex - 1).questions.length;
                        const newSectionIndex = (questionIndex > 0) ? sectionIndex : (sectionIndex - 1);
                        const newQuestionIndex = (questionIndex <= 0) ? previusSectionQuestionsCount - 1 : (questionIndex - 1);
                        history.push(`${rootPath}${ROOT_PATH}/${newSectionIndex}/${newQuestionIndex}`)
                        markBusinessAssessmentQuestionAsLastViewed(businessID, newSectionIndex, newQuestionIndex);
                        assessment.lastViewedQuestion = {sectionIndex: newSectionIndex, questionIndex: newQuestionIndex};
                    }}
                    hasPreviousQuestion={sectionIndex > 0 || questionIndex > 0}
                    goToNextQuestion={ async () => {
                        const beforeEdgeOfSection = (questionIndex < (currentSection.questions.length - 1));
                        const newSectionIndex = sectionIndex + (beforeEdgeOfSection ? 0 : 1);
                        const newQuestionIndex = beforeEdgeOfSection ? (questionIndex + 1) : 0;
                        history.push(`${rootPath}${ROOT_PATH}/${newSectionIndex}/${newQuestionIndex}`)
                        markBusinessAssessmentQuestionAsLastViewed(businessID, newSectionIndex, newQuestionIndex);
                        assessment.lastViewedQuestion = {sectionIndex: newSectionIndex, questionIndex: newQuestionIndex};
                        console.log(assessment.lastViewedQuestion);
                    }}
                    hasNextQuestion={
                        sectionIndex < (assessment.sections.length - 1)
                        || 
                        questionIndex < (currentSection.questions.length - 1)
                    }
                    onSetQuestionAnswer={ async (answer, comment) => {
                        await setBusinessAssessmentQuestionAnswer(businessID, sectionIndex, questionIndex, answer, comment);
                        await reloadBusinessDetails();
                    }}
                />
                <AuditorNotesEditor
                    numberPrefix={currentSection.prefix}
                    visible={ hasRoles(userRole, 'admin', 'auditor', 'leadAuditor') }
                    question={currentQuestion}
                    onSetNotes={ async notes => {
                        await setBusinessAssessmentAuditorNotes(businessID, sectionIndex, questionIndex, notes);
                        await reloadBusinessDetails();
                    }}
                    onSetViewed={ async reviewed => {
                        await markBusinessAssessmentQuestionAsReviewed(businessID, sectionIndex, questionIndex, reviewed);
                        await reloadBusinessDetails();
                    }}
                />
                <AuditorFeedbackEditor
                    editorID={ currentSection.prefix + currentQuestion.number }
                    visible={
                        hasRoles(userRole, 'admin', 'auditor', 'leadAuditor')
                        ||
                        (currentQuestion.auditorFeedbackMessages||[]).length
                    }
                    messages={currentQuestion.auditorFeedbackMessages}
                    onSend={ async (message, filePath) => {
                        await addBusinessAssessmentAuditorFeedbackMessage(businessID, sectionIndex, questionIndex, message, filePath);
                        await reloadBusinessDetails();
                    }}
                    userUID={(user || {}).uid}
                    userRole={userRole}
                />
                <RelatedDocuments
                    visible={ (currentQuestion.relatedDocuments||[]).length }
                    documents={ currentQuestion.relatedDocuments }
                />
            </GroupElements>
        </Container>
    );
}
export default BusinessAssessmentDetailScreen;

export const businessAssessmentDetailScreenRoutes = ({rootPath, ...props}) => {
    return [
        <Route path={`${rootPath}${PATH}`} key={'business-assessment-detail-screen'} render={ (routeProps) =>
            <BusinessAssessmentDetailScreen {...props} {...routeProps} rootPath={rootPath} />
        }/>
    ];
}