import React from 'react';
import styled from 'styled-components';
import LeftIcon from '../icons/left-icon';
import RightIcon from '../icons/right-icon';

const Container = styled.div`
    display:grid;
    grid-template-columns:1fr 1fr;
    grid-column-gap:15px;    
    background-color:#f5f5f5;
    padding:15px;
    align-items:center;

    @media (max-width:865px) {
        grid-template-columns:1fr;
    }
`;

const InnerContainer = styled.div`
    grid-column: 2 / -1;
    display:grid;
    grid-template-columns:repeat(3, 1fr) 20px 20px;
    @media (max-width:865px) {
        grid-template-columns:1fr 1fr 1fr 1fr;
        grid-column: 1 / -1;
        grid-row-gap:15px;
    }
`;

const Text = styled.span` 
    font-size: 14px;
    font-weight:bold;
    margin-right: 15px;
    @media (max-width:865px) {
        grid-column: 1 / 3;
    }
`;

const SubText = styled.span` 
    font-size: 14px;
    font-weight:bold;
    margin-right: 15px;
    @media (max-width:865px) {
        grid-column: 1 / 3;
        width:100%;
    }
`;

const styledArrow = (Arrow, addRightMargin=true) => styled(Arrow)`
    cursor: ${({enabled=true}) => enabled ? 'pointer' : 'default'};
    color: darkgray;
    margin-top: -1px;
    max-height: 15px;
    height: 15px;
    min-height: 15px;
    max-width: 15px;
    width: 15px;
    min-width: 15px;
    :hover {
        color: ${({enabled=true}) => enabled ? 'black' : 'darkgrey'} ;
    }
`;
const LeftArrow = styledArrow(LeftIcon);

const RightArrow = styledArrow(RightIcon, false)

const RowsCountSelector = ({value, onChange}) => {
    const Select = styled.select` 
        margin-right: 40px;
        margin-top: -2px;
        height:25px;
        @media (max-width:865px) {
            grid-column: 3 / 5;
            width:100%;
        }
    `;

    const Option = styled.option`
        font-size:14px;
    `;

    return (
        <Select value={value} onChange={onChange}>
            <Option>50</Option>
            <Option>100</Option>
            <Option>150</Option>
        </Select>
    );
}


const PagingInfo = ({
    rowsPerPage=50, 
    hasNext=false,
    hasPrevious=false,
    onClickNext=( () => {}), 
    onClickPrevious=( () => {}), 
    onChangeRowsPerPage=(()=>{}) }
) => {
    const setRowsPerPage = ({target}) => onChangeRowsPerPage(parseInt(target.value));
    return (
        <Container>
            <InnerContainer>
                <Text>Rows per page:</Text>
                <RowsCountSelector 
                    value={rowsPerPage} 
                    onChange={ setRowsPerPage } 
                /> 
                <SubText />
                <LeftArrow 
                    enabled={hasPrevious} 
                    onClick={ () => hasPrevious && onClickPrevious() } 
                />
                <RightArrow 
                    enabled={ hasNext } 
                    onClick={ () => hasNext && onClickNext() } 
                />
            </InnerContainer>
        </Container>
    );
}
export default PagingInfo;