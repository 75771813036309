export const hasRoles = (userRole = '', ...roles) => {
    return [...roles].includes(userRole);
}

export const FORMATTED_ROLES = { 
    'partner': 'Business', 
    'ata': 'ATA', 
    'leadAuditor': 'Lead Audit.',
    'auditor': 'Audit.',
    'admin': 'Admin',
    'gimp': 'Gimp',
    null : '-',
    undefined : '-'
};
