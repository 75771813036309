import React, { Component } from "react";
import UploadFileField from '../upload-file-field';

const DummyManager = {};
DummyManager.accept = () => '';
DummyManager.uploadProgress = () => undefined;
DummyManager.onFilesChange = () => null;
DummyManager.onUploadProgressChange = () => null;
DummyManager.onUploadCompleted = () => null;
DummyManager.onError = () => null;

export default class ManagedUploadFileField extends Component {

    state = {};

    change = () => {
        this.setState({ update: (this.state.update || 0) + 1 });
    }

    onError = (error) => {
        this.setState({ error });
    }

    render() {
        let { 
            fileUploadManager=DummyManager,
            error: propsError
        } = this.props;

        let {
            id,
            title,
            message,
            onFilesChange,
            onUploadCompleted,
            ...otherProps
        } = this.props;

        let {
            error,
        } = this.state;

        fileUploadManager.onUploadProgressChange( this.change );
        fileUploadManager.onUploadCompleted( (url) => {
            this.change();
            (onUploadCompleted && onUploadCompleted(url));
        }); 
        fileUploadManager.onError( this.onError );

        return (
            <UploadFileField
                id={id}
                title={title}
                message={error ? error.message : message }
                accept={ fileUploadManager.accept() }
                uploadProgress={ fileUploadManager.uploadProgress() }
                onFilesChange={ (files, value) => {
                    fileUploadManager.onFilesChange(files);
                    (onFilesChange && onFilesChange(files));
                    this.setState({value});
                }}
                error={propsError || error}
                {...otherProps}
            />
        );

    }
}