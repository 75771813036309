import React, { useState } from 'react';
import BigMessage from '../big-message';
import { useHistory } from 'react-router';
import { createBusinessAssessment } from '../../../utils/server-functions';
import Button from '../../../shared-components/button';

import { ROOT_PATH as DETAIL_PATH } from '../../business-assessment-detail-screen';

const StartAssessmentContent = ({rootPath, businessID, visible, canStartAssessment, reloadBusinessDetails}) => {
    const [creatingAssessment, setCreatingAssessment] = useState(false);
    const history = useHistory();

    if (!visible) return null;
    return (<>
        <BigMessage
            title="Start an assessment of your business that an auditor will use to assess your business for AQS."
        />
        {canStartAssessment && (
            <Button
                title="Start Assessment"
                loading={creatingAssessment}
                light={false}
                onClick={ async () => {
                    try {
                        setCreatingAssessment(true);
                        await createBusinessAssessment(businessID);
                        await reloadBusinessDetails();
                        history.push(`${rootPath}${DETAIL_PATH}`);
                    }
                    catch (error) {
                        console.log(error);
                        alert(error);
                    }
                    finally {
                        setCreatingAssessment(false);
                    }
                }}
            />
        )}
    </>);
}
export default StartAssessmentContent;
