import React from 'react';
import styled from 'styled-components';
import AssessmentSectionSummaryItem from './assessment-section-summary-item';

const Container = styled.div`
    height: ${props => props.collapsed ? '0px' : 'auto'};
    overflow: ${props => props.collapsed ? 'hidden' : 'auto'};
    display: block;
`

const List = styled.div`
    display:grid;
    grid-template-columns:repeat(3, 1fr);
    margin-top:15px;
    margin-bottom:15px;
    padding-left:15px;
    padding-right:15px;

    @media (max-width:480px) {
        grid-template-columns:1fr;
        margin-top:7px;
        margin-bottom:7px;
        padding-left:7px;
        padding-right:7px;
    }
`;

const Item = styled.div`
    font-size:14px;
    padding:10px;
`;

const Label = styled.span`
    font-size:14px;
    font-weight:bold;
    color:black;
    line-height:28px;   
`;




const AssessmentSectionSummary = ({
    sectionIndex, 
    totalQuestions, 
    totalPointsScored, 
    answeredQuestions, 
    prefix, 
    questions = [], 
    collapsed,
    onMarkReviewed=(_=>_),
    onSetAuditorNotes=(_=>_),
    canViewReviewed=false,
    canEditReviewed=false,
    canViewNotes=false,
    canEditNotes=false,
    printable=false,
}) => {
    let mandatoryQuestions = questions.filter(({ mandatory }) => mandatory === true);
    let mandatoryQuestionsAnswered = mandatoryQuestions.filter(({ answered }) => answered === true);
    let pointsAvailable = questions.reduce((a, b) => a + (b['points'] || 0), 0);
    return (
        <Container collapsed={collapsed} >
            <List>
                <Item>
                    <Label>Total questions:</Label> {totalQuestions} ({mandatoryQuestions.length} mandatory)
                </Item>
                <Item>
                    <Label>Questions answered:</Label> {answeredQuestions} ({mandatoryQuestionsAnswered.length} mandatory)
                </Item>
                <Item>
                    <Label>Total points scored:</Label> {totalPointsScored} of {pointsAvailable}
                </Item>
            </List>
            <Container>
            {questions.map( (question, questionIndex) =>
                <AssessmentSectionSummaryItem 
                    key={`${sectionIndex}.${questionIndex}`}
                    questionIndex={questionIndex}
                    sectionIndex={sectionIndex} 
                    prefix={prefix}
                    {...question} 
                    onSetNotes={ (notes) => onSetAuditorNotes(sectionIndex, questionIndex, notes) }
                    onMarkReviewed={ (reviewed) => onMarkReviewed(sectionIndex, questionIndex, reviewed) }
                    last={questionIndex === questions.length - 1 }
                    printable={printable}
                    canViewReviewed={canViewReviewed}
                    canEditReviewed={canEditReviewed}
                    canViewNotes={canViewNotes}
                    canEditNotes={canEditNotes}
                />
            )}
            </Container>
        </Container>
    )
}

export default AssessmentSectionSummary;