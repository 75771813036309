import styled from "styled-components";
import {
    WIDTH,
    MOBILE_MEDIUM_WIDTH,
    MOBILE_LARGE_WIDTH
} from './block-item-container';

const BlockItemsContainer = styled.div`
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex: 0;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 auto;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-top: 15px;
    min-width: 100%;
    ${({fullHeight}) => fullHeight ? 'min-height: 100%;' : ''}
    max-height: 100%;
    width: 100%;
    flex-basis: auto;

    @media (min-width: 319px) and (max-width: 374px) {
        padding-left:15px;
        padding-right:15px;
        display:block;
    }

    @media (min-width: 374px) and (max-width: 413px) {
        padding-left: calc(calc(100% - ${MOBILE_MEDIUM_WIDTH}) / 2);
    }
    
    @media (min-width: 413px) and (max-width: 564px) {
        padding-left: calc(calc(100% - ${MOBILE_LARGE_WIDTH}) / 2);
    }
    
    @media (min-width: 564px) and (max-width: 834px) {
        min-width: calc( calc(2 * ${WIDTH}) + calc(2 * 15px) );
        width: calc( calc(2 * ${WIDTH}) + calc(2 * 15px) );
    }

    @media (min-width: 833px) and (max-width: 1024px) {
        min-width: calc( calc(3 * ${WIDTH}) + calc(3 * 15px) );
        width: calc( calc(3 * ${WIDTH}) + calc(3 * 15px) );
    }

    @media (min-width: 1024px) and (max-width: 2559px) {
        min-width: calc( calc(4 * ${WIDTH}) + calc(4 * 15px));
        width: calc( calc(4 * ${WIDTH}) + calc(4 * 15px) );
    }

    @media (min-width: 2559px) {
        min-width: calc( calc(9 * ${WIDTH}) + calc(9 * 15px));
        width: calc( calc(9 * ${WIDTH}) + calc(9 * 15px) );
    }
`;

export default BlockItemsContainer;