import React, { useState } from "react";
import Button from "../../shared-components/button";
import TextField from "../../shared-components/text-field";
import SelectField from "../../shared-components/select-field";
import FormContainer from "./form-container";
import { Hyperlink, TitleSteps, MainTitle } from "./texts";
import { Row, Column } from "./table";
import { createOnChangeField, validateStepOneData } from "./helpers";
import ManagedUploadFileField from "../../shared-components/managed-file-upload-field";
import FirebaseSingleFileUploadManager from "../../utils/firebase-single-file-upload-manager";
import StepContainer from "./step-container";

export const PATH = "/register";

const TypeOptions = [
    {value:'-', title:"Select Type"},
    {value:"retail", title: "Retail"},
    {value:"restaurant", title: "Restaurant"},
    {value:"touroperator", title: "Tour Operator"},
];

const RegisterBusinessScreenStepOne = ({visible=true, onClickClose=(_=>_), onClickContinue=(_=>_)}) => {
    let [data, setData] = useState({});
    let [canContinue, setCanContinue] = useState(false);
    let [loading, setLoading] = useState(false);
    let [errors, setErrors] = useState({});
    let [timer, setTimer] = useState(0);
    
    let [uploadManager] = useState( new FirebaseSingleFileUploadManager() );

    const onChange = createOnChangeField(data, timer, setTimer, setData, setCanContinue, setErrors, setLoading, validateStepOneData);
    return (
        <StepContainer visible={visible}>
            <Row>
                <Column>
                    <Hyperlink href='/' onClick={(e) => {
                        e.preventDefault(); 
                        onClickClose(); 
                    }}>
                        X
                    </Hyperlink> 
                    <TitleSteps>
                        Register a business (Step 1 of 3) 
                    </TitleSteps>
                </Column>
            </Row>
            <Row>
                <Column>
                    <MainTitle>
                        Let's start with the type of business and registration information.
                    </MainTitle>
                        <FormContainer>
                            <SelectField
                                id="type" 
                                title="Type of Business" 
                                message="Choose the type of Business"
                                options={ TypeOptions }
                                onChange={ onChange('type') }
                                value={data.type || ''}
                                error={errors.type}
                            />
                            <TextField 
                                id="name" 
                                title="Business name" 
                                message={`The name of your business name ${data.nameID ? `(business ID: ${data.nameID})` : ''}`}
                                placeholder="My Restaurant"
                                onChange={ (event) => {
                                    const name = event.target.value||'';
                                    const nameID = (name||'').trim().toLowerCase().replace(/[^a-z1-9\s]/g, '').replace(/\s/g, '-');
                                    let newData = {...data, name, nameID};
                                    setData(newData);
                                    clearTimeout(timer);
                                    setTimer( setTimeout(
                                        () => validateStepOneData(newData, setCanContinue, setErrors, setLoading),
                                        500
                                    ));
                                }}
                                value={data.name || ''}
                                error={errors.name}
                            />
                            <TextField 
                                id="chamberOfCommerceRegistrationCode" 
                                title="Chamber of Commerce Code*" 
                                message="Chamber of commerce code must be unique" 
                                placeholder="H12455.03" 
                                onChange={ onChange('chamberOfCommerceRegistrationCode') }
                                error={errors.chamberOfCommerceRegistrationCode}
                            />
                            <ManagedUploadFileField
                                id="chamberOfCommerceExtractFilePath" 
                                title={`Chamber of Commerce Extract (PDF, JPG or PNG)*`}
                                message={"Upload a copy of your camber of commerce extract."}
                                fileUploadManager={uploadManager}
                                onUploadCompleted={ (path) => {
                                    let change = onChange('chamberOfCommerceExtractFilePath')
                                    change({target: {value:path}});
                                }}
                                error={errors.chamberOfCommerceExtractFilePath}
                            />  
                        </FormContainer>
                        <Button
                            onClick={ () => onClickContinue(data) }
                            disabled={ !canContinue }
                            loading={ loading }
                            title="Continue"
                        />
                </Column>
            </Row>
        </StepContainer>
    );
}
export default RegisterBusinessScreenStepOne;
