import styled from "styled-components";

export const Row = styled.div`
    min-width:320px;
    max-width:480px;
    padding-right:15px;
`;

export const Column = styled.div`
    width:100%;
    display:block;
`;