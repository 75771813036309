import React, { useState } from 'react';
import styled from 'styled-components';
import AssessmentSectionSummary from './assessment-section-summary'
import DefaultProgressBar from '../../utils/progress-bar';
import UpIcon from '../../shared-components/icons/up-icon';
import DownIcon from '../../shared-components/icons/down-icon';

const ProgressBar = styled(DefaultProgressBar)`
    flex-grow: 1;
    width: 45%;

    @media only screen and (max-width: 1200px) {
        flex-basis:90%;
        margin-top:8px;
        margin-bottom:8px;
    }
`;

const Trigger = styled.div`
    flex-grow: 1;
    width: 10%;

    @media only screen and (max-width: 1200px) {
        position:absolute;
        top:15px;
        right:0px;
    }
`;

const Accordion = styled.div``;

const Header = styled.div`
    
    background-color:#005270;
    padding:10px;
    position:sticky;
    top: 0px;
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    justify-content:center;
    align-items:center;
    cursor:pointer;
    transition:all 0.3s linear;
    

    z-index: 1;
    
    svg {
        position:absolute;
        top:0;
        right:15px;
        color:black;
        width:15px;
    }

    span, svg {
        color:white;
    }

    ${({printable}) => printable ? 
        `   
            page-break-before: always;
            background-color: white;
            position: relative;
            span {
                color: #005270;
                font-size: 20px;
            }

            ${ProgressBar}, ${Trigger} {
                display: none;
            }
        `
        :
        ''
    }
`;

const Label = styled.span`
    font-size:14px;
    font-weight:bold;
    color:black;
    line-height:28px;
    flex-grow: 1;
    width: 45%;

    @media only screen and (max-width: 1200px) {
        flex-basis:100%;
    }
`;





const Content = styled.div`
    margin-bottom:0;
`;

const AssessmentSectionItem = ({ 
    name,
    answeredQuestions, 
    questions, 
    prefix, 
    initialCollapsed=false, 
    sectionIndex,
    onSetAuditorNotes,
    onMarkReviewed,
    printable=false,

    canViewReviewed, 
    canEditReviewed,
    canViewNotes,
    canEditNotes,

    ...props
}) => {

    const [collapsed, toggleAccordion] = useState(initialCollapsed);
    let progress = (answeredQuestions || 0) / (questions.length || 1);

    return (
        <Accordion>
            <Header 
                onClick={() => toggleAccordion(!collapsed)} 
                printable={printable}
            >
                <Label>{name}</Label>
                <ProgressBar progress={progress} />
                <Trigger>
                    {collapsed ? <DownIcon /> : <UpIcon />}
                </Trigger>
            </Header>
            <Content>
                <AssessmentSectionSummary 
                    answeredQuestions={answeredQuestions} 
                    prefix={prefix} 
                    questions={questions} 
                    collapsed={ printable ? false : collapsed } 
                    sectionIndex={sectionIndex} 
                    onSetAuditorNotes={onSetAuditorNotes}
                    onMarkReviewed={onMarkReviewed}
                    printable={printable}
                    canViewReviewed={canViewReviewed}
                    canEditReviewed={canEditReviewed}
                    canViewNotes={canViewNotes}
                    canEditNotes={canEditNotes}
                    {...props} 
                />
            </Content>
        </Accordion>
    )
}

export default AssessmentSectionItem;