import React, { useRef } from 'react';
import { Route} from "react-router-dom";
import styled from 'styled-components';
import { getDate } from '../../utils/date-format';
import { 
    GetAllAssessmentQuestions, 
    GetAnsweredQuestions, 
    GetAnsweredQuestionsPercentage 
} from '../business-detail-screen/helpers';
import AssessmentSectionsList from './assessment-sections-list';
import OriginalGroupElement from '../../shared-components/group-elements'
import { setBusinessAssessmentAuditorNotes, markBusinessAssessmentQuestionAsReviewed } from '../../utils/server-functions';
import ReactToPrint from 'react-to-print';
import PrintIcon from '../../shared-components/icons/print-icon';
import { hasRoles } from '../../utils/user';

export const PATH = '/summary';

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: #f5f5f5;
    min-height:100%;
    height:auto;

    @media only screen and (max-width: 600px) {
        padding:0;
    }
`;

const GroupElements = styled(OriginalGroupElement)`
    @media only screen and (max-width: 600px) {
        width:100%;
        box-shadow:none;
    }
`;

const TopContainer = styled.div`
    display: flex; 
    flex-direction: row;
    align-items: center;

    svg {
        color:#005270;
    }
    @media only screen and (max-width: 600px) {
        flex-direction:column;
        align-items:flex-start;

        .back-button {
            position:absolute;
        }
    }
`;

const Title = styled.span`
    margin-left: 0;
    font-size: 28px;
    font-weight:400;
    letter-spacing:0.05em;
    display:inline-block;
    width: 100%;
    color:#005270;
    flex: 1;

    @media only screen and (max-width: 600px) {
        margin-bottom:9px;
        max-width: 250px;
    }
`;

const MetaContainer = styled.div`
    display:flex;
    flex-wrap: wrap;
    flex-direction:row;
    margin-top:15px;
    padding-top:15px;
    padding-bottom:15px;
    margin-bottom:15px;
    border-bottom:1px solid lightgrey;
    border-top:1px solid lightgrey;
`;

const Metadata = styled.span`
    font-size:14px;
    line-height:28px;
    flex-grow: 1;
    width: 33%;

    @media only screen and (max-width: 1170px) {
        flex-basis:50%;
    }
    @media only screen and (max-width: 945px) {
        flex-basis:100%;
    }
`;

const Label = styled.span`
    font-weight:bold;
    margin-right:8px;
`;

const AssessmentSectionsContainer = styled.div`
    margin-left: -15px;
    margin-right: -15px;
`;

const PrintButton = styled(PrintIcon)`
    position: absolute;
    right: 60px;
    top: 67px;
    color: #005270;

    @media(max-width: 600px) {
        right: 25px;
        top: 20px;
    }
`;

class BusinessAssessmentSummaryScreenContent extends React.Component {

    render () {
        let{businessID, assessment, reloadBusinessDetails, printable=false, userRole} = this.props;
        let { completed, datecompleted, created, updated, sections  } = assessment;

        let createdDisplay = getDate(created);
        let updatedDisplay = getDate(updated);
        let completedDisplay = getDate(datecompleted);

        let allQuestions = GetAllAssessmentQuestions(sections);
        let answeredQuestions = GetAnsweredQuestions(allQuestions);
        let percentage = GetAnsweredQuestionsPercentage(answeredQuestions, allQuestions);

        let totalPointsScored = sections.reduce((a, b) => a + (b['totalPointsScored'] || 0), 0);
        let mandatoryQuestions = allQuestions.filter(({mandatory}) => mandatory === true);
        let mandatoryQuestionsAnswered = mandatoryQuestions.filter(({answered}) => answered === true);
        let pointsAvailable = allQuestions.reduce((a, b) => a + (b['points'] || 0), 0);

        const canEditReviewed = hasRoles(userRole, 'admin', 'auditor', 'leadAuditor');
        const canViewNotes =  hasRoles(userRole, 'admin', 'auditor', 'leadAuditor', 'ata' );
        const canEditNotes =  hasRoles(userRole, 'admin', 'auditor', 'leadAuditor');
        return (
            <GroupElements>
                <TopContainer>
                    <Title >Assessment Report</Title>
                </TopContainer>
                <MetaContainer>
                    <Metadata>
                        <Label>Created on:</Label> 
                        {createdDisplay}
                    </Metadata>
                    <Metadata>
                        <Label>Last modified:</Label> 
                        {updatedDisplay}
                    </Metadata>
                    <Metadata>
                        <Label>Status:</Label> 
                        {completed ? `Completed on ${completedDisplay}` : percentage > 0 ? `In progress (${percentage}% completed)` : 'Not started'}
                    </Metadata>
                    <Metadata>
                        <Label>Total questions:</Label> 
                        {allQuestions.length} ({mandatoryQuestions.length} mandatory)
                    </Metadata>
                    <Metadata>
                        <Label>Questions answered:</Label> 
                        {answeredQuestions.length} ({mandatoryQuestionsAnswered.length} mandatory)
                    </Metadata>
                    <Metadata>
                        <Label>Total points scored:</Label> 
                        {totalPointsScored} of {pointsAvailable}
                    </Metadata>
                </MetaContainer>
                <AssessmentSectionsContainer>
                    <AssessmentSectionsList 
                        printable={printable}
                        sections={sections} 
                        onSetAuditorNotes={ async (sectionIndex, questionIndex, notes) => {
                            await setBusinessAssessmentAuditorNotes(businessID, sectionIndex, questionIndex, notes);
                            await reloadBusinessDetails();
                        }}
                        onMarkReviewed={ async (sectionIndex, questionIndex, reviewed) => {
                            await markBusinessAssessmentQuestionAsReviewed(businessID, sectionIndex, questionIndex, reviewed);
                            await reloadBusinessDetails();
                        }}
                        canViewReviewed={ true }
                        canEditReviewed={ canEditReviewed }
                        canViewNotes={ canViewNotes }
                        canEditNotes={ canEditNotes }
                    />
                </AssessmentSectionsContainer>
            </GroupElements>
        );
    }
};


const BusinessAssessmentSummaryScreen = (props) => {
    const containerRef = useRef();
    return (
        <Container>
            <ReactToPrint 
                trigger={ () => <PrintButton title="Print" /> }
                content={ () => containerRef.current }
            /> 

            <BusinessAssessmentSummaryScreenContent {...props} ref={containerRef} />
            
            <div style={{ display: 'none'}} >
                <BusinessAssessmentSummaryScreenContent {...props} printable={true} ref={containerRef} />
            </div>

        </Container>
    );
}
export default BusinessAssessmentSummaryScreen;

export const businessAssessmentSummaryScreenRoutes = ({rootPath, ...props}) => {
    return [
        <Route path={`${rootPath}${PATH}`} key={'business-assessment-summary-screen'} render={ (routeProps) =>
            <BusinessAssessmentSummaryScreen {...props} {...routeProps} rootPath={rootPath} />
        }/>
    ];
}