import firebase from 'firebase/app';
import 'firebase/storage';


const defaultFileReference = (file) => {
    let uniqueId = Math.random().toString(36).substring(2) + Date.now().toString(36);
    return `/uploads/${uniqueId}/${file.name}`;
}

export default class FirebaseSingleFileUploadManager {

    _accept;
    _currentUploadProgress;
    _currentUploadTask;
    _getReferenceForFile;
    _error;

    constructor(accept="image/png,image/jpeg,application/pdf", getReferenceForFile) {
        this._accept = accept;
        this._getReferenceForFile = getReferenceForFile || defaultFileReference;
        this._reset();
    }

    accept = () => this._accept;

    uploadProgress = () => this._currentUploadProgress;

    onUploadProgressChange = ( onChange ) => this._onUploadProgressChange = onChange;

    error = () => this._error;
     
    onError = (onError) => this._onError = onError;

    onUploadCompleted = (onUploadCompleted) => this._onUploadCompleted = onUploadCompleted;

    _onStateChanged = (snapshot) => {
        this._currentUploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes);
        if (this._onUploadProgressChange) {
            this._onUploadProgressChange(this._currentUploadProgress)
        }
    }
    
    onFilesChange = async (files, value) => {
        if ( this._currentUploadTask ) {
            // Cancel the upload
            this._currentUploadTask.cancel();
            this._reset();
        }

        if (files.length === 0) {
            return;
        }
        
        const file = files[0];
        const reference = firebase.storage().ref( this._getReferenceForFile(file) );
        try {
            this._currentUploadTask = reference.put(file);
            this._currentUploadTask.on('state_changed', this._onStateChanged);
            await this._currentUploadTask;
            await reference.updateMetadata({cacheControl: 'max-age=31556926'});
            if (this._onUploadCompleted) {
                await this._onUploadCompleted(reference.fullPath, this);
            }
        }
        catch (_error) {
            this._error = _error;
            if (this._onError) {
                this._onError(_error);
            }
            else {
                throw _error;
            }
        }
        finally {
            this._reset();
        }
        
        return reference.fullPath || false;
    };

    _reset = () => {
        this._currentUploadProgress = undefined;
        this._currentUploadTask = undefined;
        this._error = undefined;
    }
}