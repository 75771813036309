import React, { useState } from "react";
import Button from "../../shared-components/button";
import TextField from "../../shared-components/text-field";
import FormContainer from "./form-container";
import { Hyperlink, TitleSteps, MainTitle } from "./texts";
import { Row, Column } from "./table";
import { createOnChangeField, validateStepTwoData } from "./helpers";
import styled from "styled-components";
import StepContainer from "./step-container";

export const PATH = "/register";

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: 600px) {
        flex-direction:column;
    }
    
`;

const RegisterBusinessScreenStepOne = ({visible=true, onClickClose=(_=>_), onClickContinue=(_=>_), onClickPrevious=(_=>_)}) => {
    let [data, setData] = useState({});
    let [canContinue, setCanContinue] = useState(false);
    let [loading, setLoading] = useState(false);
    let [errors, setErrors] = useState({});
    let [timer, setTimer] = useState(0);

    const onChange = createOnChangeField(data, timer, setTimer, setData, setCanContinue, setErrors, setLoading, validateStepTwoData);
    return (
        <StepContainer visible={visible} >
            <Row>
                <Column>
                    <Hyperlink href='/' onClick={(e) => {
                        e.preventDefault(); 
                        onClickClose(); 
                    }}>
                        X
                    </Hyperlink> 
                    <TitleSteps>
                        Register a business (Step 2 of 3) 
                    </TitleSteps>
                </Column>
            </Row>
            <Row>
                <Column>
                    <MainTitle>
                        We also need some basic information about your business
                    </MainTitle>
                        <FormContainer>
                            <TextField 
                                id="contactFullName" 
                                title="Contact Full Name*" 
                                message="The full name of the person to contact if there are questions." 
                                placeholder="John Smith"
                                onChange={ onChange('contactFullName') }
                                value={data.contactFullName || ''}
                                error={errors.contactFullName}
                            />
                            <TextField 
                                id="contactPhoneNumber" 
                                title="Contact Phone Number*" 
                                message="Must be a valid phone number." 
                                placeholder="+297 5884556"
                                onChange={ onChange('contactPhoneNumber') }
                                value={data.contactPhoneNumber || ''}
                                error={errors.contactPhoneNumber}
                            />
                            <TextField 
                                id="website" 
                                title="Website" 
                                message="The website if your business (optional)." 
                                placeholder="https://www.my-place.aw" 
                                onChange={ onChange('website') }
                                error={errors.website}
                            />
                            <TextField 
                                id="address" 
                                title="Registered Address*" 
                                message="The address your business is registered at." 
                                placeholder="Camacuri 11B" 
                                onChange={ onChange('address') }
                                error={errors.address}
                            />
                            <TextField 
                                id="numberOfSites" 
                                title="Number of Locations*" 
                                message="The number of locations for your business." 
                                placeholder="1" 
                                onChange={ onChange('numberOfSites') }
                                error={errors.numberOfSites}
                            />
                        </FormContainer>
                        <ButtonContainer>
                            <Button 
                                onClick={ onClickPrevious }
                                title="Previous"
                                light={false}
                            />
                            <Button 
                                onClick={ () => onClickContinue(data) }
                                disabled={ !canContinue }
                                loading={ loading }
                                title="Continue"
                            />
                        </ButtonContainer>
                </Column>
            </Row>
        </StepContainer>
    );
}
export default RegisterBusinessScreenStepOne;
