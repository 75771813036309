import React from 'react';
import { Route } from "react-router-dom";
import { DetailRow } from '../../../shared-components/detail-container';
import DisplayNameRow from './display-name-row';
import AvatarDetailRow from './avatar-detail-row';
import { FORMATTED_ROLES } from '../../../utils/user';

export const PATH = '/me';

const AccountSection = ({user={}, userRole, onUserUpdate}) => {
    return (
        <>
            <DetailRow
                title="Email"
                subtitle={user.email || '-'}
            />
            <DetailRow
                title="Role"
                subtitle={FORMATTED_ROLES[userRole]}
            />
            <DisplayNameRow
                user={user}
                onUserUpdate={onUserUpdate}
            />
            <AvatarDetailRow
                user={user}
                onUserUpdate={onUserUpdate}
            />
        </>
    );
}
export default AccountSection;


export const accountSectionRoutes = ({rootPath, ...props}) => {
    return [
        <Route path={`${rootPath}${PATH}`} exact key={'account-settings-screen-account-section'} render={ (routeProps) =>
            <AccountSection {...props} {...routeProps} />
        }/>
    ];
}