import React from 'react';
import styled from 'styled-components';
import DefaultContainer from '../container';
import OriginalBackButton from '../back-button';

export {default as DetailRow} from './detail-row';

const Container = styled(DefaultContainer)`
    box-sizing: border-box;
    flex: 1;
    flex-direction: column;
    flex-wrap: nowrap;
    
    overflow-x: hidden;
    margin: 0 0;
    
    padding-bottom: 35px;
    margin: 0 0;
    overflow-y: scroll;
    max-height: 100%;
    min-height: 100%;
    max-width: 100%;
`;

const InnerContainer = styled.div`
    box-sizing: border-box;
    width: 100%;
`;

const BackButton = styled(OriginalBackButton)`
    @media (min-width: ${({theme}) => theme.mobileWidthBoundry}) {
        display: none;
    }
`;

const RegistrationInfoDetail = ({
    onClickBack,
    children,
    ...props
}) => {
    return (
        <Container {...props}>
            <InnerContainer>
                <BackButton onClick={onClickBack}/> 
                {children}
            </InnerContainer>
        </Container>
    );
}
export default RegistrationInfoDetail;
