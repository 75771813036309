import React, { useState } from 'react';
import { Route } from "react-router-dom";
import DetailHeader, {applyIconStyle} from '../../shared-components/detail-header';
import Link from '../../shared-components/link';
import { DetailRow } from '../../shared-components/detail-container';
import InfoIcon from '../../shared-components/icons/info-icon';
import { markBusinessAsApproved } from '../../utils/server-functions';
import Button from '../../shared-components/button';
import MediaFileLink from '../../shared-components/media-file-link';
import { hasRoles } from '../../utils/user';
import GroupElements from '../../shared-components/group-elements';
import Container from '../../shared-components/detail-section-container';

export const PATH = '/registration';

const displayBusinesTypes = {
    'restaurant': 'Restaurant',
    'retail': 'Retail',
    'touroperator': 'Tour Operator'
}

const Icon = applyIconStyle(InfoIcon);

const RegistrationInfoDetail = ({
    business = {},
    userRole,
    reloadBusinessDetails,
}) => {
   
    const registration = business.registration || {}; 
    const [marking, setMarking] = useState(false);
    const showApproveOptions = hasRoles(userRole, 'admin', 'ata');
    const canApprove = !registration.isApproved;
    const canUnapprove = hasRoles(userRole, 'admin') && registration.isApproved;
    
    const markAsApproved = async (approved=true) => {
        const confirmed = window.confirm(
            `Are you sure you want to mark the registration of `+
            `${business.name} as ${approved ? '' : 'not '} approved?`
        )
        if (confirmed) {
            try {
                
                setMarking(true);
                await markBusinessAsApproved(business.id, approved);
                await reloadBusinessDetails();
            }
            catch(error) {
                console.error(error);
                alert(error.message);
            }
            finally {
                setMarking(false);
            }
        }
    };

    return (
        <Container>
            <GroupElements>
                <DetailHeader 
                    Icon={Icon}
                    title={"Registration Details"} 
                    detail={"Your business's registration information."}
                />
                <DetailRow title="Approved" subtitle={
                    registration.isApproved ? 'Yes' : 'Pending approval...'
                } />
                <DetailRow title="Type of Business" subtitle={displayBusinesTypes[business.type]} />
                <DetailRow title="Business Name" subtitle={business.name} />
                <DetailRow title="Contact - Full Name" subtitle={business.contactFullName} />
                <DetailRow title="Contact - Phone Number" subtitle={business.contactPhoneNumber} />
                {business.website && 
                    <DetailRow title="Website" subtitle={
                        <Link to={business.website}>{business.website}</Link>
                    } />
                }
                <DetailRow title="Address" subtitle={business.address} />
                <DetailRow title="Number of locations" subtitle={business.numberOfSites} />
                <DetailRow
                    title="Chamber of Commerce Registration Code"
                    subtitle={business.chamberOfCommerceRegistrationCode}
                />
                <DetailRow title="Chamber of Commerce Extract" subtitle={
                    <MediaFileLink
                        fileName={ "View File"}
                        fileURL={business.chamberOfCommerceExtractURL}
                        filePath={business.chamberOfCommerceExtractFilePath}
                    />
                }/>
                {showApproveOptions &&
                    <>
                        {canApprove && <Button 
                            title="Approve" 
                            loading={marking}
                            onClick={() => markAsApproved(true)} 
                        />}
                        {canUnapprove && <Button 
                            title="Unapprove" 
                            loading={marking}
                            onClick={() => markAsApproved(false)} 
                        />}
                    </>
                }
            </GroupElements>
        </Container>
    );
}
export default RegistrationInfoDetail;

export const businessRegistrationInfoScreenRoutes = ({ rootPath, ...props }) => {
    return [
        <Route path={`${rootPath}${PATH}`} exact key={'business-registration-info-screen'} render={(routeProps) =>
            <RegistrationInfoDetail {...props} {...routeProps} />
        } />
    ];
}