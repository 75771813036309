import React from "react";
import { Route, useHistory } from "react-router-dom";
import {CMSLogin as DefaultCMSLogin} from '@cr38te/cr38te-cms';
import {PATH as BUSINESSES_PATH} from '../businesses-screen';
import Header from '../login-screen/header';
import { forgotPasswordScreenRoutes, PATH as FORGOT_PASSWORD_SCREEN_PATH } from "../forgot-password-screen";
import { createAccountScreenRoutes, PATH as CREATE_ACCOUNT_SCREEN_PATH } from "../create-account-screen";
import styled from "styled-components";
import { userDidLogIn } from "../../utils/server-functions";

export const PATH = "/login";

const CMSLogin = styled(DefaultCMSLogin)`
    background-image:url('https://aruba.bynder.com/m/201143b3304ef039/fullscreen_letterbox-Entrance-to-Bushiribana-Gold-Mill-Ruins-Entrance-to-Bushiribana-Gold-Mill-Ruins.jpg');
    background-position:center center;
    background-repeat:no-repeat;
    background-size:cover;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    height:100vh;

    @media (max-width: 640px) {
        justify-content:flex-start;
    }

    form {
        min-width:250px;
        max-width:480px;
        width:100%;
        background-color:#f5f5f5;
        padding:15px;
        box-shadow:0 1px 2px rgba(0,0,0,.05),0 0 0 1px rgba(63,63,68,.1);

        > div:first-child {
            padding:0;
           .Login {
                background-color:#005270;
                color:white;
                text-align:center;
                border-radius:0;
                font-size:16px;
                line-height:36px;
                text-transform:uppercase;
            }
        }
        @media (max-width: 640px) {
            margin-left:15px;
            margin-right:15px;
            min-width:250px;
            max-width:calc( 100vw - 30px );
        }
    }
    .Title {
        flex: 1;
        font-size: 14px;
        line-height: 28px;
        font-weight: 700;
        flex-shrink: 0;
        background-color:transparent;
    }
    h1.Title {
        margin-top:0;
        font-size: 28px;
        font-weight:400;
        letter-spacing:0.05em;
        display:inline-block;
        width: 100%;
        color:#005270;
        flex: 1;
    }
    .InputContainer {
        font-size: 16px;
        border-radius: 0px;
        border: 1px solid #cccccc;
        padding: 5px 12px;
        color:black;
        .Input {
            font-size:14px;
        }
    }
    .TitleCheckboxContainer {
        #rememberMe {
            margin-top:-5px;
        }
        label {
            line-height:51px;
        }
    }
    .ErrorMessage {
        border:1px solid #990000;
        background-color:rgba(153, 0, 0, 0.35);
        padding:15px;
        color:#000;
        font-weight:bold;
        border-radius:0;
        font-size:14px;
        line-height:28px;
        letter-spacing:0.08px;
    }
    .Link {
        background-color:transparent;
        &:hover {
            text-decoration:none;
        }
    }
    .TextField {
        margin:0;
        padding:0;
    }
    .ForgotPassword, 
    .TitleCheckboxContainer {
        .Title {
            color:grey;
        }
    }
    .CreateAccount {
        .Title {
            color:#acc237;
        }
    }
`;

const LoginScreen = ({authManager={}}) => {
    let history = useHistory();
    const devEnv = process.env.NODE_ENV === 'development' || process.env.REACT_APP_DEPLOY_TYPE === 'dev';
    return (
        <CMSLogin 
            title={`Login${ devEnv ? ' (Development)' : '' }`}
            HeaderComponent={Header}
            onSignIn={ async (...props) => {
                await authManager.signIn(...props);
                await userDidLogIn();
            }}
            onSignInCompleted={ () => history.push(BUSINESSES_PATH) }
            onClickForgotPassword={ () => history.push(FORGOT_PASSWORD_SCREEN_PATH) }
            onClickSignUp={ () => history.push(CREATE_ACCOUNT_SCREEN_PATH)}
        />
    );
}
export default LoginScreen;


export const loginScreenRoutes = (rootProps={}) => {
    let props = {loginScreenPath: PATH, ...rootProps};
    return [
        <Route path={PATH} exact key='login-screen' >
            <LoginScreen {...props} />
        </Route>,
        ...forgotPasswordScreenRoutes(props),
        ...createAccountScreenRoutes(props)
    ];
}