import React from 'react';
import { Route } from "react-router-dom";
import styled from 'styled-components';
import MessageScreen from '../../shared-components/message-screen';

export const PATH = "/login/password-reset-completed";

const FullScreenMessageScreen = styled(MessageScreen)`
    width: 100vw;
    height: 100vh;
`;

const ForgotPasswordCompletedScreen = ({history, loginScreenPath}) => {
    return (
        <FullScreenMessageScreen 
            message="A reset link has been sent to your email address. Please check your email to reset your password."
            linkTitle="Go back to login"
            to={loginScreenPath}
            history={history}
        />
    );
}
export default ForgotPasswordCompletedScreen;

export const forgotPasswordCompletedScreenRoutes = (props) => {
    return [
        <Route path={PATH} exact key='forgot-password-completed-screen'>
            <ForgotPasswordCompletedScreen {...props} />
        </Route>
    ];
}