import styled from "styled-components";

const Container = styled.div`
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc( ${window.innerHeight}px - 60px);
    min-height: calc( ${window.innerHeight}px - 60px);
    overflow-y: scroll;
    padding: 30px;
    background-color: #f5f5f5;

    @media (max-width: 768px) {
        top: 50px;
        height: calc( ${window.innerHeight}px - 50px);
        min-height: calc( ${window.innerHeight}px - 50px);
    }

    @media only screen and (max-width: 600px) {
        padding:0;
    }
`;
export default Container;
