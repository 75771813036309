import React, { Component } from 'react';
import { Route, Switch} from "react-router-dom";
import styled from 'styled-components';

import DetailNavBar from './detail-nav-bar';
import Menu from './menu';
import { businessOverviewScreenRoutes } from '../business-overview-screen';
import { businessRegistrationInfoScreenRoutes } from '../business-registration-info-screen';
import { businessPaymentScreenRoutes } from '../business-payment-screen';
import { businessAssessmentScreenRoutes } from '../business-assessment-screen';
import { businessOnSiteInspectionScreenRoutes } from '../business-on-site-inspection-screen';
import { businessDownloadsScreenRoutes } from '../business-downloads-screen';
import { getBusinessDetails, getBusinessDownloads } from '../../utils/server-functions';
import { redirectToOverviewIfNeeded, getBusinessID, getSelectedSubPath } from './helpers';
import { CMSLoader } from '@cr38te/cr38te-cms';

export const BUSINESS_ID_PARAM_KEY = 'businessID';
export const ROOT_PATH = `/:${BUSINESS_ID_PARAM_KEY}`;
export const PATH = `${ROOT_PATH}/:selected?`;
export const rootPathForBusinessName = (name, selected) => {
    return ROOT_PATH.replace(`:${BUSINESS_ID_PARAM_KEY}`, name) + (selected ? `/${selected}` : '');
}

const Container = styled.div`
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0px;
    bottom: 0px;
    background-color: white;
    overflow-y: hidden;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 0;
    background-color: white;
    @media (min-width: 768px) {
        left: 280px;
        width: calc(100% - 280px);
    }
    transition: left 300ms;
    overflow-y: hidden;
`;

const Content = styled.div`
flex: 1;
    width: 100%;
    overflow-y: scroll;
`;

export default class BusinessDetailScreen extends Component {
    state = {
        firstLoading: true,
        menuOpen: false,
        error: false,
    };

    componentDidMount() {
        redirectToOverviewIfNeeded(this.props);
        this.loadBusinessDetails();
    }

    componentDidUpdate(prevProps) {
        redirectToOverviewIfNeeded(this.props);
        let prevBusinessID = getBusinessID(prevProps);
        let businessID = getBusinessID(this.props);
        if (prevBusinessID !== businessID) {
            this.loadBusinessDetails();
        }
    }

    loadBusinessDetails = async () => {
        try {
            this.setState({loading: true});   
            const businessID = getBusinessID(this.props);
            const [business, businessDownloads] = await Promise.all([
                getBusinessDetails(businessID),
                getBusinessDownloads(businessID)
            ]);
            this.setState({business, businessDownloads});   
        }
        catch (error) {
            this.setState({error});
        }
        finally {
            this.setState({loading: false, firstLoading: false});   
        }
    }
    
    render () {
        let {
            businesses, 
            match,
            onShowUserAccountMenu,
            history,
            userRole,
            user,
        } = this.props;

        let {
            firstLoading,
            menuOpen,
            business,
            businessDownloads,
            loading,
        } = this.state;

        const businessID = getBusinessID(this.props);
        let rootPath = rootPathForBusinessName(businessID);
        let routeProps = { 
            businessID,
            rootPath, 
            business, 
            businessDownloads,
            businessDetailsLoading: loading, 
            userRole, 
            reloadBusinessDetails: this.loadBusinessDetails,
            user
        };
        return (
            <Container>
                <Menu 
                    menuOpen={menuOpen}
                    onDismiss={ () => this.setState({menuOpen: false}) }
                    rootPath={rootPath}
                    selectedItem={ (match && match.params && match.params.selected) }
                />
                <ContentContainer>
                    <DetailNavBar 
                        businesses={businesses} 
                        selectedBusinessID={businessID}
                        onClickMenuIcon={ () => this.setState({menuOpen: true}) }
                        onClickUserIcon={ () => onShowUserAccountMenu(true) }
                        onChangeBusiness={ (businessID) => {
                            const selected = getSelectedSubPath(this.props);
                            const match = (this.props.match && this.props.match.params) || {};
                            history.push(rootPathForBusinessName(businessID, selected));
                            //BUG FIX: Force set the id because matching doesnt happen anymore.
                            match.businessID = businessID;
                            this.setState({firstLoading: true});
                            this.loadBusinessDetails();
                        }}
                        onReloadBusinesses={ this.loadBusinessDetails }
                        user={user}
                    />
                    <Content>
                        {firstLoading ?
                            <CMSLoader animating={true}/>
                            :
                            <Switch>
                                {businessOverviewScreenRoutes(routeProps)}
                                {businessRegistrationInfoScreenRoutes(routeProps)}
                                {businessPaymentScreenRoutes(routeProps)}
                                {businessAssessmentScreenRoutes(routeProps)}
                                {businessOnSiteInspectionScreenRoutes(routeProps)}
                                {businessDownloadsScreenRoutes(routeProps)}
                            </Switch>
                        }
                    </Content>
                </ContentContainer>
                
            </Container>
        );
    }
}

export const businessDetailScreenRoutes = (props) => {
    return [
        <Route path={PATH} key={'business-detail-screen'} render={ (routeProps) =>
            <BusinessDetailScreen {...props} {...routeProps}/>
        }/>
    ];
}