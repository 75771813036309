import React, { useState } from 'react';
import { DetailRow } from "../../shared-components/detail-container";
import styled from "styled-components";
import Button from '../../shared-components/button';
import Link from '../../shared-components/link';

var dateFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };

const Container = styled.div`
    display: flex; 
    flex-direction: row;

    @media only screen and (max-width: 950px) {
        flex-direction:column;
    }
`;

const InnerContainer = styled.div`
    display: flex; 
    flex: 1;
    flex-direction: column;
`;

const Detail = styled.span`
    flex: 1;
    flex-direction: column;
    margin-bottom: 15px;
`;  

const SmallButton = styled(Button)`
    padding: 0px; 
    margin: 0px;
    margin-top: -24px;
    width: 80px;
    height: 35px;

    @media only screen and (max-width: 950px) {
        min-width:calc( 100vw - 30px );
    }
`;

const PaymentInstuctionsLastSentDetailRow = ({canSend, timeStampLastSent={}, instructionsURL='https://www.cr38te.com', onResend=(_=>_)}) => {
    let displayDateLastSent = null;
    const [sending, setSending] = useState(false);
    if (canSend) {
        let dateLastSent = new Date(timeStampLastSent._seconds * 1000);
        displayDateLastSent = dateLastSent.toLocaleDateString("en-US", dateFormatOptions);
    }
    
    return (
        <DetailRow 
            title="Payment Instructions Last Sent" 
            subtitle={ canSend ? 
                <Container>
                    <InnerContainer>
                        <Detail>{displayDateLastSent}</Detail>
                        <Link to={instructionsURL}>View</Link>
                    </InnerContainer>
                    <SmallButton 
                        title="Resend" 
                        onClick={ async () => {
                            setSending(true);
                            try { 
                                await onResend();
                            }
                            catch (error) {
                                //
                            }
                            setSending(false);
                        }} 
                        loading={sending}
                    />
                </Container>
                :
                "You will receive payment instructions soon after your business is approved."
            }
        />
    );
}
export default PaymentInstuctionsLastSentDetailRow;