import styled from "styled-components";

let Menu = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: calc(${({theme:t}) => t.header.desktopHeight});
    @media (max-width: ${({theme:t}) => t.mobileWidthBoundry}) {
        top: calc(${({theme:t}) => t.header.mobileHeight} + 5px);
    }
    @media only screen and (max-width: 789px) {
       top: 50px;
    }
    right: 15px;
    width: 280px;
    min-height: 100px;
    max-height: 320px;
    overflow: scroll;
    background-color: white;
    border-style: solid;
    border-color: lightgrey;
    border-width: 0 1px 1px 1px;
    box-shadow: 1px 1px 5px grey;
    border-radius: 0 0 0 0;
    opacity: ${({visible=false}) => visible ? '1' : '0'};
    transition: opacity 300ms;
    
`;

export default Menu;