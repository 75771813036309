import React, { Component } from "react";
import styled from "styled-components";
import { Route, Switch } from "react-router-dom";

import BusinessList from "./business-list";
import NavBar from './nav-bar';
import FilterBar from './filter-bar';
import { getBusinesses, getUserRole, markBusinessAsLastViewed, Timestamp, userDidLogOut } from '../../utils/server-functions';
import { businessDetailScreenRoutes } from '../business-detail-screen';
import { registerBusinessScreenRoutes, PATH as REGISTER_BUSINESS_SCREEN_PATH } from '../register-business-screen';
import AccountMenu from "../../shared-components/account-menu";
import { accountSettingsScreenRoutes } from "../account-settings-screen";
import { PATH as ACCOUNT_SETTINGS_PATH } from '../account-settings-screen';

export const PATH = "/";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    align-items: flex-start;
    overflow-y: scroll;
`;

export default class BusinessesScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            businesses: [],
            businessesLoading: true,
            sortOrder: 'DESC',
            sortBy: 'updated',
            searchTerm: '',
        };
    }

    componentDidMount() {
        this.loadBusinesses();   
    }

    loadBusinesses = async () => {
        this.setState({ businessesLoading: true });
        try {
            let [allBusinesses, userRole] = await Promise.all([
                getBusinesses(),
                getUserRole()
            ]);
            
            const sortBy = userRole === 'partner' ? 'lastViewed' : this.state.sortBy;
            this.applyFiters({ ...this.state, allBusinesses, sortBy});
            this.setState({ allBusinesses, userRole, sortBy });
        }
        catch (error) {
            this.setState({ error });
        }
        finally {
            this.setState({ businessesLoading: false });
        }
    }

    applyFiters = (state = this.state) => {
        const { allBusinesses, searchTerm, sortBy, sortOrder } = state;
        
        let businesses = allBusinesses;
        if (searchTerm) {
            businesses = businesses.filter(
                b =>
                    b.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    b.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    b.chamberOfCommerceRegistrationCode.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    b.address.toLowerCase().includes(searchTerm.toLowerCase()),
            );
        }

        businesses = businesses.sort((a, b) => {
            let val1 = a[[sortBy]];
            let val2 = b[[sortBy]];
            if(['updated', 'lastViewed'].includes(sortBy)) {
                val1 = (a[[sortBy]] || a.updated)._seconds;
                val2 = (b[[sortBy]] || b.updated)._seconds;
            }
            if (sortOrder === 'ASC') return (val1 > val2 ? 1 : -1);
            return (val1 < val2 ? 1 : -1);
        });
        
        this.setState({businesses});
    }

    onClickItem = (item) => {
        //Set this business as last viewed.
        try {
            markBusinessAsLastViewed(item.id);
            const now = Timestamp.fromDate(new Date());
            item.lastViewed = {_seconds: now.seconds, _nanoseconds: now.nanoseconds};
            this.applyFiters();
        } 
        catch(error) {
            console.error(error);
        }
        this.props.history.push(`${PATH}${item.id}`);
    }

    onAddItem = () => {
        this.props.history.push(REGISTER_BUSINESS_SCREEN_PATH);
    };

    onShowUserAccountMenu = (show) => {
        this.setState({ showUserAccountMenu: show });
    }

    onSortList = sortBy => {
        let { sortOrder='ASC' } = this.state;
        sortOrder = (sortOrder === 'DESC' || sortBy !== this.state.sortBy) ? 'ASC' : 'DESC'
    
        this.applyFiters({...this.state, sortOrder, sortBy});
        this.setState({sortOrder, sortBy});
    }

    onFilterSearch = searchTerm => {
        this.applyFiters({...this.state, searchTerm});
        this.setState({searchTerm});
    }

    onGoToAccountSettings = () => {
        this.onShowUserAccountMenu(false);
        this.props.history.push(`${ACCOUNT_SETTINGS_PATH}`);
    }

    onClickSignOut = async () => {
        let { authManager = {} } = this.props;
        this.onShowUserAccountMenu(false);
        await new Promise(res => setTimeout(res, 100));
        if (window.confirm('Are your sure you want to sign out?')) {
            userDidLogOut()
            authManager.signOut();
        }
    }

    onUserUpdate = (user) => {
        this.setState({user});
    }

    render() {
        let {
            businesses,
            businessesLoading,
            userRole,
            error,
            showUserAccountMenu,
            sortBy,
            sortOrder,
            searchTerm,
        } = this.state;

        let {
            user,
        } = this.props;

        let routeProps = {
            businesses,
            loading: businessesLoading,
            user,
            userRole,
            onShowUserAccountMenu: this.onShowUserAccountMenu,
            refreshBusinesses: this.loadBusinesses,
            onUserUpdate: this.onUserUpdate,
        }

        if (error) {
            return <div>{error.message}</div>
        }

        const showFilterBar = !businessesLoading && userRole !== 'partner';
        return (
            <Container>
                <NavBar
                    onClickUserIcon={() => this.onShowUserAccountMenu(true)}
                    user={user}
                />
                {showFilterBar && 
                    <FilterBar
                        onSortList={this.onSortList}
                        onFilterSearch={this.onFilterSearch}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                        searchTerm={searchTerm}
                    />
                }
                <BusinessList
                    loading={businessesLoading}
                    data={businesses}
                    onClickItem={this.onClickItem}
                    onAddItem={this.onAddItem}
                    userRole={userRole}
                />
                <Switch>
                    {accountSettingsScreenRoutes(routeProps)}
                    {registerBusinessScreenRoutes(routeProps)}
                    {businessDetailScreenRoutes(routeProps)}
                    
                </Switch>
                <AccountMenu
                    user={user}
                    visible={showUserAccountMenu}
                    onDismiss={() => this.onShowUserAccountMenu(false)}
                    onClickSignOut={ this.onClickSignOut }
                    onClickShowAccountSettings={this.onGoToAccountSettings}
                />
            </Container>
        );
    }
}


export const businessesScreenRoutes = (props) => {
    return [
        <Route key={'businesses-screen'} render={(routeProps) =>
            <BusinessesScreen {...routeProps} {...props} />
        } />,
    ];
}