import React from 'react';
import styled from 'styled-components';
import UpIcon from '../../../shared-components/icons/up-icon';
import DownIcon from '../../../shared-components/icons/down-icon';
import SearchInput from '../../../shared-components/search-input';

const Container = styled.div`
    flex: 0;
    min-height: fit-content;

    display: flex;
    width: 100%;
    border-bottom:1px solid #ccc;
    padding: 15px;
    background-color:#ffffff;

    @media (max-width:700px) {
        flex-direction: column;
    }

    @media (max-width:480px) {
        min-height:120px;
    }

`;

const SortList = styled.ul`
    list-style-type: none;
    display: block;
    margin:12px 0 0 30px;
    padding:0px;

    @media (max-width: 700px) {
        margin-left: 0px;
        margin-top:0px;
        display:block;
        width:100%;
    }
`;

const SortListItem = styled.li`
    display: block;
    float: left;
    margin-right: 30px;
    text-align: center;
`;

const Item = styled.a`
    font-weight:700;
    color: ${({ selected }) => selected ? '#005270' : 'black'};
    text-transform:uppercase;
    font-size:14px;
    line-height:28px;
    cursor:pointer;
    display:block;

    div {
        display:block;
        float:right;
        svg {
            display: ${({ selected }) => selected ? 'block' : 'none'};
            color:black;
            width:30px;
            margin-top:3px;
            color: ${({ selected }) => selected ? '#005270' : 'black'};
        }
    }    

    &:hover {
        color:#005270;
    }
`;

const FilterBar = ({ sortBy, sortOrder, searchTerm, onSortList, onFilterSearch }) => {


    return (
        <Container>
            <SearchInput value={searchTerm} onChange={event => onFilterSearch(event.target.value)} placeholder="Search..." />
            <SortList>
                <SortListItem>
                    <Item 
                        onClick={() => onSortList('updated')} 
                        selected={sortBy === 'updated'}
                    >
                        Modified {sortBy === 'updated' && sortOrder === 'ASC' ? <UpIcon /> : <DownIcon />}
                    </Item>
                </SortListItem>
                <SortListItem>
                    <Item 
                        onClick={() => onSortList('name')} 
                        selected={sortBy === 'name'}
                    >
                        Name {sortBy === 'name' && sortOrder === 'ASC' ? <UpIcon /> : <DownIcon />}
                    </Item>
                </SortListItem>
                <SortListItem>
                    <Item 
                        onClick={() => onSortList('type')} 
                        selected={sortBy === 'type'}
                    >
                        Type {sortBy === 'type' && sortOrder === 'ASC' ? <UpIcon /> : <DownIcon />}
                    </Item>
                </SortListItem>
            </SortList>
        </Container>
    );
}
export default FilterBar;