import React from 'react';
import styled from "styled-components";
import { useLocation, useHistory } from "react-router-dom";

const Container = styled.div`
    display:grid;
    grid-template-columns:repeat(3, 1fr);
    grid-column-gap:0;
    align-items:center;
    text-align:center;
    margin-bottom:30px;
`;
const Item = styled.div`
    cursor:pointer;
    font-size:14px;
    
    &:last-child {
        div {
            border-right:0;
        }
    }
`;

const ItemTitle = styled.div`
    color: ${({active}) => active ? `#acc236` : 'white' };
    border-right:1px solid rgba(255,255,255,0.3);
    padding:15px;
    background-color:#005270;
    cursor: pointer;
    transition:all 0.2s linear;
    font-size:14px;

    &:hover {
        background-color:#acc236;
        color:#000000;
    }
`;

const SectionHeader = ({sections=[], rootPath}) => {
    const location = useLocation();
    const history = useHistory();
    const currentPath = location.pathname;
    return (
        <Container>
            {sections.map( (section, index) => 
                <Item key={index}>
                    <ItemTitle 
                        active={ currentPath === `${rootPath}${section.path}` }
                        onClick={ () => history.push(`${rootPath}${section.path}`) }
                    >
                        {section.title}
                    </ItemTitle>
                </Item>
            )}
        </Container>
    );
}
export default SectionHeader;