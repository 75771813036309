import React from 'react';
import styled from 'styled-components';
import { attachClasses } from '../../../utils/styled';

export const SWITCH_TO_MOBILE_WIDTH = 413;
const padding = 5;
const timeWidth = 50;

const Container = styled.div`
    position: relative;
    border-bottom: 1px lightgrey solid;
    width:100%;
    display: flex;
    flex-direction: column;
    padding: ${padding}px;
    padding-left: ${padding + timeWidth + 5}px;
    padding-top: ${2*padding}px;
    padding-bottom: ${2*padding}px;

    &:first-child {
        background-color:rgb(0, 60, 80);
        color:#ffffff;
    }
`;

const Span = styled.span``;
const Property = styled( attachClasses(Span, 'property')  )`
    font-size:14px;
    font-weight:400;
`;


const TimeProperty = styled(  attachClasses(Property, 'time') )`
    position: absolute;
    
    top: ${2 * padding + 3}px;
    left: ${padding}px;
    width: ${timeWidth}px;

    font-size:11px;
    font-weight:bold;

`;

const DescriptionProperty = styled(
    attachClasses(Property, 'description')
)`
    font-size:15px;
    font-weight:400;
    line-height: 18px;
    margin-bottom: 5px;
`;

const UserProperty = styled( 
    attachClasses(Property, 'user') 
)`
    font-size:10px;
    font-weight:bold;
    color: rgb(60, 60, 60);
`;


const AuditLogItem = ({date, userDescription, eventDescription, className}) => {
    return (
        <Container className={className}>
            <TimeProperty >{date}</TimeProperty>
            <DescriptionProperty>{eventDescription}</DescriptionProperty>            
            <UserProperty>{userDescription}</UserProperty>
        </Container>
    );
    /*
        businessID: null, 
        userDescription: "israel@cr38te.com (Izzy)", 
        date: {_seconds: 1587503329, _nanoseconds: 693000000}, 
        eventDescription: "Logged in"
    */
}
export default AuditLogItem;