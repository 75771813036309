import React, { useState } from 'react';
import { Route } from "react-router-dom";
import DetailHeader, { applyIconStyle } from '../../shared-components/detail-header';
import { DetailRow } from '../../shared-components/detail-container';
import CardIcon from '../../shared-components/icons/card-icon';
import Button from '../../shared-components/button';
import { markBusinessAsPaid, sendBusinessRegistrationPaymentInstructions } from '../../utils/server-functions';
import PaymentInstuctionsLastSentDetailRow from './payment-instructions-last-sent-detail-row';
import Message from '../../shared-components/message';
import { hasRoles } from '../../utils/user';
import GroupElements from '../../shared-components/group-elements';
import Container from '../../shared-components/detail-section-container';


export const PATH = '/payment';

const dateFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

const Icon = applyIconStyle(CardIcon);

const BusinessPayment = ({
    business = {},
    userRole,
    reloadBusinessDetails,
    ...props
}) => {

    const registration = business.registration || {};
    const [marking, setMarking] = useState(false);
    const showMarkAsPaidOptions = hasRoles(userRole, 'admin', 'leadAuditor');
    const canMarkAsPaid = !registration.isPaid;
    const canUnmarkAsPaid = hasRoles(userRole, 'admin', 'leadAuditor') && registration.isPaid;

    let paidMessage = 'No';
    if (registration.isPaid) {
        const datePaid = registration.datePaid;
        const datePaidAsDate = datePaid ? new Date(datePaid._seconds * 1000) : new Date();
        const formattedDate = datePaidAsDate.toLocaleDateString("en-US", dateFormatOptions);
        paidMessage = `Yes, received on ${formattedDate}`;
    }

    const markAsPaid = async (paid = true) => {
        const confirmed = window.confirm(
            `Are you sure you want to mark the registration of ${business.name}` +
            ` as ${paid ? '' : 'not '} paid?`
        );
        if (confirmed) {
            try {
                setMarking(true);
                await markBusinessAsPaid(business.id, paid);
                await reloadBusinessDetails();
            }
            catch (error) {
                console.error(error);
                alert(error.message);
            }
            finally {
                setMarking(false);
            }
        }
    };

    return (
        <Container>
            <GroupElements>
                <DetailHeader
                    Icon={Icon}
                    title={"Payment"}
                    detail={"Your registration payment information."}
                />
                {registration.isApproved ?
                    <>
                        <DetailRow title="Amount" subtitle={registration.registrationPaymentInstructionsFormattedTotal} />
                        <PaymentInstuctionsLastSentDetailRow
                            canSend={registration.isApproved && registration.isRegistrationPaymentInstructionsSent}
                            timeStampLastSent={registration.dateSentRegistrationPaymentInstructions}
                            onResend={() => sendBusinessRegistrationPaymentInstructions(business.id)}
                            instructionsURL={ registration.registrationPaymentInstructionsURL }
                        />
                        <DetailRow title="Paid" subtitle={paidMessage} />

                        {showMarkAsPaidOptions &&
                            <>
                                {canMarkAsPaid && <Button
                                    title="Mark As Paid"
                                    loading={marking}
                                    onClick={() => markAsPaid(true)}
                                    disabled={!registration.isApproved}
                                />}
                                {canUnmarkAsPaid && <Button
                                    title="Unmark As Paid"
                                    loading={marking}
                                    onClick={() => markAsPaid(false)}
                                />}
                                {!registration.isApproved &&
                                    <span>You will be able to mark as paid once the registration is approved.</span>
                                }
                            </>
                        }
                    </>
                    :
                    <>  
                        <Message>Once your registration has been reviewed and approved, you will receive payment instructions within two business days. You can view your payment status here.</Message>
                    </>
                }
            </GroupElements>
        </Container>
    );
}
export default BusinessPayment;


export const businessPaymentScreenRoutes = ({ rootPath, ...props }) => {
    return [
        <Route path={`${rootPath}${PATH}`} exact key={'business-payment-screen'} render={(routeProps) =>
            <BusinessPayment {...props} {...routeProps} />
        } />
    ];
}