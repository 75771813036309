import React from 'react';
import Field from '../field';

const SelectField = (props) => {
    return (
        <Field 
            id={props.id} 
            title={props.title}
            message={props.message}
            error={props.error}
            renderInput={ () =>
                <select
                    onChange={props.onChange}
                    value={props.value}
                >
                    {(props.options||[]).map( (o, index) => (
                        <option key={index} value={o.value}>{o.title}</option> 
                    ))}
                </select>
            } 
        />
    );
};
export default SelectField;
