import {AuthenticationManager} from "@cr38te/cr38te-cms";
import firebase from 'firebase/app';
import 'firebase/auth';

const configs = {
    "dev": {
        apiKey: "AIzaSyAfKEXFe_ZISYAs5gRxeUv8iFnFn6LX7Kk",
        authDomain: "aruba-quality-seal-dev.firebaseapp.com",
        databaseURL: "https://aruba-quality-seal-dev.firebaseio.com",
        projectId: "aruba-quality-seal-dev",
        storageBucket: "aruba-quality-seal-dev.appspot.com",
        messagingSenderId: "552946446661",
        appId: "1:552946446661:web:da9b464c7157f39a578e2c"
    },
    "prod": {
        apiKey: "AIzaSyClhmdDReXYt17Xt5IHnrjdvmUelW98Xxo",
        authDomain: "aruba-quality-seal.firebaseapp.com",
        databaseURL: "https://aruba-quality-seal.firebaseio.com",
        projectId: "aruba-quality-seal",
        storageBucket: "aruba-quality-seal.appspot.com",
        messagingSenderId: "248741067423",
        appId: "1:248741067423:web:ea9e2adbf74f8e8b"
    }  
};

if (process.env.NODE_ENV === 'development') {
    firebase.initializeApp(configs['dev']);
}
else {
    const deployType = process.env.REACT_APP_DEPLOY_TYPE || 'dev';
    firebase.initializeApp(configs[deployType]);
}

const LOCAL = firebase.auth.Auth.Persistence.LOCAL;

export default class FirebaseAuthManager extends AuthenticationManager {
    ready = false;

    constructor() {
        super();
        this.unsubscribeReadySelfState = firebase.auth().onAuthStateChanged( async (user) => {
            this.ready = true;
            this.unsubscribeReadySelfState();
        });
    }

    signUp = async (email, password) => {
        await firebase.auth().createUserWithEmailAndPassword(email, password);
        var user = firebase.auth().currentUser;
        await user.sendEmailVerification({ url: window.location.origin + '/login/welcome'});
    };  

    signIn = async (email, password, rememberMe) => {
        firebase.auth().setPersistence( rememberMe ? undefined : LOCAL );
        await firebase.auth().signInWithEmailAndPassword(email, password);
    };  

    signOut = () => {
        return firebase.auth().signOut();
    };

    forgotPassword = (email) => {
        return firebase.auth().sendPasswordResetEmail(email);
    };

    isReady = () => {
        return this.ready;
    }

    isSignedIn = () => {
        return (firebase.auth().currentUser !== null);
    }

    onManagerReadyStateChanged = (callback) => {
        return firebase.auth().onAuthStateChanged(callback);
    }
    
    onAuthenticationStateChanged = (callback) => {
        return firebase.auth().onAuthStateChanged( 
            (user) => callback(user ? user : null)
        );
    }

    getUser = () => {
        return Promise.resolve(firebase.auth().currentUser);
    }
}