import React, { useState } from 'react';
import styled from 'styled-components';
import {timeoutSave} from '../../../utils/saving';
import OriginalTextField from '../../../shared-components/text-field';

const TextField = styled(OriginalTextField)`
    margin-bottom: 25px;
    padding-bottom: 15px;
    border-bottom: solid 1px lightgrey;
`;

const DisplayNameRow = ({user, onUserUpdate=(()=>{})}) => {
    const [saveDisplayNameTimeout, setSaveDisplayNameTimeout] = useState();
    const [displayName, setDisplayName] = useState(user.displayName);
    return (
        <TextField
            id="displayName"
            title="Display Name"
            message="Set a display name for your account."
            value={displayName}
            placeholder="User"
            onChange={({ target }) => {
                timeoutSave(
                    saveDisplayNameTimeout,
                    setSaveDisplayNameTimeout,
                    async () => {
                        await user.updateProfile({ displayName: target.value });
                        onUserUpdate(user);
                    }
                );
                setDisplayName(target.value);
            }}
        />
    );
}
export default DisplayNameRow;