import * as React from 'react';
import {CMSDefaultTheme as DefaultTheme} from '@cr38te/cr38te-cms';
import styled, { ThemeProvider } from 'styled-components';
import Header from './header';
import Menu from './menu';
import MenuItem from './account-menu-item';

let Container = styled.div`
    position: absolute;
    z-index: 30;
    width: 100vw;
    height: 100vh;
    ${({visible=false}) => !visible && 'display: none;'}
`;

export default class AccountMenu extends React.Component {

    onClickBackground = (event) =>{
        let {onDismiss=(()=>null)} = this.props;
        onDismiss(event);
        return event;
    }

    onClick = (event) => {
        event.stopPropagation();
    }
    
    render() {
        let {
            theme=DefaultTheme,
            visible=false,
            user,
            onClickShowAccountSettings,
            onClickSignOut
        } = this.props;
        return (
            <ThemeProvider theme={theme}>
                <Container onClick={this.onClickBackground} visible={visible} > 
                    <Menu visible={visible} onClick={this.onClick}>
                        <Header 
                            displayName={ user ? user.displayName : undefined } 
                            email={ user ? user.email : undefined } 
                            photoURL={ user ? user.photoURL : undefined }
                        />
                        <MenuItem title="Account Settings" seperator onClick={onClickShowAccountSettings} />
                        <MenuItem title="Sign Out" onClick={onClickSignOut} />
                    </Menu>
                </Container>
            </ThemeProvider>
        );
    }
}