import styled from "styled-components";

const ListItemsContainer = styled.div`
    width: 100%; 
    display: flex; 
    flex-direction: column;
    justify-content: flex-start;
    overflow-y:scroll;
`;

export default ListItemsContainer;