import React from 'react';
import { Route, Link, Switch, Redirect } from "react-router-dom";
import styled from "styled-components";
import Container from './container';
import GroupElements from '../../shared-components/group-elements';
import {default as BaseDetailHeader, applyIconStyle } from '../../shared-components/detail-header';
import AvatarIcon from '../../shared-components/icons/avatar-icon';
import {accountSectionRoutes, PATH as ACCOUNT_SECTION_PATH } from './account-section';
import {userSectionRoutes, PATH as USERS_SECTION_PATH } from './users-section';
import {auditLogSectionRoutes, PATH as AUDIT_LOG_SECTION_PATH } from './audit-log-section';
import { hasRoles } from '../../utils/user';
import SectionHeader from './section-header';
import { CMSLoader } from '@cr38te/cr38te-cms';

export const PATH = '/account';

const Icon = applyIconStyle(AvatarIcon);
const SECTIONS = [
    {title: "Account", path: ACCOUNT_SECTION_PATH },
    {title: "Users", path: USERS_SECTION_PATH },
    {title: "Audit Log", path: AUDIT_LOG_SECTION_PATH }
];

const Button = styled(Link)`  
    background-color:#00536e;
    color:#FFF;
    flex: 0;
    padding: 5px 15px 5px 15px;
    font-family: ${({theme}) => theme.fontRoboto };
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    border-radius:0;
    display:block;
    text-align:center;
    text-decoration:none;
    margin-right:15px;
    cursor:pointer;
    transition:all 0.3s linear;
    align-items:center;

    &:hover {
        background-color: #005270;
    }

    @media only screen and (max-width: 600px) {
        max-width:100%;
        margin-right:0;
    }
`;

const DetailHeader = styled(BaseDetailHeader)`
    position: 'relative';

    ${Button} {
        position: absolute;
        right: 45px;
        height: 30px;

        @media only screen and (max-width: 600px) {
            right: 15px;
            max-width:100%;
            height: 30px;
        }
    }
    
`;

const AccountSettingsScreen = ({loading, rootPath='/', user={}, userRole, onUserUpdate}) => {
    const elevated = hasRoles(userRole, 'admin', 'ata', 'leadAuditor', 'auditor');
    const Header = elevated ? SectionHeader : (() => null/*no header*/);
    const routeProps = {
        user,
        userRole,
        onUserUpdate,
        rootPath: PATH
    };
    if (loading) {
        return (
            <Container>
                <CMSLoader animating={true} />
            </Container>
        );
    }
    return (
        <Container> 
            <GroupElements>
                <DetailHeader
                    Icon={Icon}
                    title="Account Settings"
                    detail="Manage your account settings"
                >
                    <Button to={rootPath}>
                        Done
                    </Button>
                </DetailHeader>
                <Header 
                    sections={SECTIONS}
                    rootPath={PATH}
                />
                <Switch>
                    {accountSectionRoutes(routeProps) }
                    {userSectionRoutes(routeProps)}
                    {auditLogSectionRoutes(routeProps)}
                    <Redirect to={`${PATH}${ACCOUNT_SECTION_PATH}`} />
                </Switch>
            </GroupElements>
        </Container>
    );
}
export default AccountSettingsScreen

export const accountSettingsScreenRoutes = ({...props}) => {
    return [
        <Route path={`${PATH}/:section**`} exact key={'account-settings-screen'} render={ (routeProps) =>
            <AccountSettingsScreen {...props} {...routeProps} />
        }/>
    ];
}