import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex; 
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-top:15px;
    border-top:1px solid #ccc;
    width: 100%;
`;

const Select = styled.select`
    font-size: 14px;
    border-radius: 0px;
    border: 1px solid #cccccc;
    padding: 5px 12px;
    color:black;
    box-sizing: border-box;
    width: fit-content;
`;

const SelectContainer = styled.div`
    display: flex;
    flex: 0;
    flex-direction: column;
    list-style: none;
`;

const SectionContainer = styled(SelectContainer)`
    flex: 1;
    margin-right: 10px;
    max-width: 500px;
    ${Select} {
        width: 100%;
    }
`;

const SelectTitle = styled.span`
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 28px;
    font-weight: 700;
    flex-shrink: 0;
`;



const Option = styled.option`
    font-size: 14px;
    color:black;
`;

const answerIndication = (answered=false, reviewed=false) => {
    return `${(answered) ? '☑' : '☐'} ${reviewed ? '◉' : '◯'}`;
};

const SectionAndQuestionSelection = ({ 
    assessment={},
    sectionIndex=0, 
    questionIndex=0, 
    onSectionChanged=(_=>_), 
    onQuestionChanged=(_=>_)
}) => {
    const sections = assessment.sections || [];
    const section = sections[sectionIndex]||{};
    const questions = section.questions || [];
    const prefix = section.prefix || '';
    return (
        <Container>
            <SectionContainer>
                <SelectTitle>Section</SelectTitle>
                <Select 
                    value={sectionIndex} 
                    onChange={({target}) => 
                        onSectionChanged(parseInt(target.value)) 
                    }
                >
                    { sections.map( (s,i) => 
                        <Option key={s.name} value={i}>
                            {s.name} - {Math.floor((s.answeredQuestions||0) / (s.totalQuestions||1) * 100)}{'%'}
                        </Option> 
                    )}
                </Select>
            </SectionContainer>
            <SelectContainer>
                <SelectTitle>Question</SelectTitle>
                <Select 
                    value={questionIndex} 
                    onChange={({target}) => 
                        onQuestionChanged(parseInt(target.value)) 
                    }
                >
                    { questions.map( (q, i) => 
                        <Option key={prefix + q.number} value={i}>{prefix}.{q.number} {answerIndication(q.answered, q.reviewed)} </Option> 
                    )}
                </Select>
            </SelectContainer>
        </Container>
    );
};
export default SectionAndQuestionSelection;