import React from "react";
import styled from "styled-components";

const ProgressWrapper = styled.div`
    position:relative;
    /* BROWSER SPECIFICS => do not delete */
    progress::-webkit-progress-bar {
        background: rgba(0,0,0,0.2);
        border-radius:20px;
    }
    progress::-webkit-progress-value {
        background: #acc236;
        border-radius:20px;
    }
    progress::-moz-progress-bar {
        background: #acc236;
        border-radius:20px;
    }
    @media only screen and (max-width: 600px) {
        width:100%;
    }
`;

const Progress = styled.progress`
    font-size: 14px;
    line-height: 28px;
    font-weight: 700;
    flex-shrink: 0;
    width:100%;
    height:20px;
    background: #f5f5f5;
    color: #acc236;
    overflow:hidden;
    padding: 0px;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius:20px;
`;

const ProgressLabel = styled.span`
    width: 100%;
    display:block;
    color:black !important;
    font-size: 11px;
    line-height: 20px;
    position:absolute;
    top:0px;
    right:10px;
    font-weight: bold;
    text-align: right;
    text-transform: uppercase;
`;

const ProgressBar = ({ progress, className}) => {
    let progressProcent = Math.round(((progress / 1) * 100) * 10) / 10;
    return (
        <ProgressWrapper className={className} progress={progress}>
            <ProgressLabel>{progressProcent}% completed</ProgressLabel>
            <Progress value={`${progressProcent}`} max="100"></Progress>
        </ProgressWrapper>
    );
}

export default ProgressBar;