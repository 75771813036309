import React from 'react';
import styled from "styled-components";


const Container = styled.div`

`;

const Label = styled.label`
    margin-right: 15px;
    font-size: 14px; 
    line-height:28px;  
    ${ ({disabled=false}) => disabled && `
        opacity: 0.6;
    `} 
`;

const Input = styled.input`
    margin-right: 5px;
    font-size: 14px; 
    line-height:28px;
`;

const RadioOptions = ({name, options=[], disabled=false, selectedOption, onChange=(_=>_), ...props}) => {
    return (
        <Container {...props} disabled={disabled}>
            {options.map( (option, index) => 
                <Label key={index}  disabled={disabled}>
                    <Input 
                        type="radio" 
                        name={name} 
                        disabled={disabled} 
                        checked={selectedOption === option}
                        onChange={() => onChange(option) }
                    />
                    {option}
                </Label>    
            )}
        </Container>
    );
}
export default RadioOptions;