import styled from "styled-components";

const ListItemContainer = styled.div`
    

    min-height: 85px;
    
    box-sizing: border-box;
    position: relative;
    display: flex;
    padding: 15px;
    border-bottom: solid 1px lightgrey;
    transition: 300ms;

    display: flex;
    align-items: center;
    
    background-color: white;

    :hover {
        cursor: pointer;
        background-color: rgb(240, 240, 240);        
    }
    
`;

export default ListItemContainer;