import React from 'react';
import styled from 'styled-components';
import Button from '../../../shared-components/button';
import { DetailRow } from '../../../shared-components/detail-container';
import ElevatedOptions from '../elevated-options';
import { useHistory } from 'react-router';
import { ROOT_PATH as DETAIL_PATH } from '../../business-assessment-detail-screen';
import { PATH as SUMMARY_PATH } from '../../business-assessment-summary-screen';

const Seperator = styled.div`
    width: 100%;
    height: 1px;
    border-bottom: solid 1px lightgrey;
`;

const progressMessageForAssessment = (assessment) => {
    if (!assessment) { return null };
    const total = assessment.totalQuestions || 0;
    const answered = assessment.totalAnsweredQuestions || 0;
    const percentage = Math.round(answered / (total || 1) * 100);
    return `${percentage}% complete (${answered} of ${total} answered)`;
}

const scoreMessageForAssessment = (assessment) => {
    if (!assessment) { return null };
    const needed = assessment.totalPointsNeeded || 0;
    const scored = assessment.totalPointsScored || 0;
    return `${scored} points (${needed} needed)`;
}

const feedbackMessageForAssessment = (assessment) => {
    if (!assessment) { return null };
    const total = assessment.totalFeebacksMesssages || 0;
    const unread = assessment.totalNewFeebacksMesssages || 0;
    return `${total} messages (${unread} new)`;
}

const DuringAssessmentContent = ({ visible, assessment, showMarkAsCompletedOption, showResetOption, reloadBusinessDetails, rootPath }) => {
    const history = useHistory();
    const showOptions = (showMarkAsCompletedOption || showResetOption);
    const lastViewedQuestion = assessment.lastViewedQuestion || {};
    const lastViewedSectionIndex = lastViewedQuestion.sectionIndex || 0;
    const lastViewedQuestionIndex = lastViewedQuestion.questionIndex || 0;
    if (!visible) return null;
    return (<>
        <DetailRow title="Current Progress" subtitle={progressMessageForAssessment(assessment)} />
        <DetailRow title="Total Score" subtitle={scoreMessageForAssessment(assessment)} />
        <DetailRow title="Feedback Received" subtitle={feedbackMessageForAssessment(assessment)} />
        <Button
            title="Continue Assessment"
            onClick={() => {
                history.push(`${rootPath}${DETAIL_PATH}/${lastViewedSectionIndex}/${lastViewedQuestionIndex}`);
            }}
        />
        {showOptions && <Seperator />}
        <ElevatedOptions
            assessment={assessment}
            reloadBusinessDetails={reloadBusinessDetails}
            showMarkAsCompletedOptions={showMarkAsCompletedOption}
            showResetOption={showResetOption}
        />
        <Seperator />
        <Button
            title="Assessment Report"
            onClick={() => {
                history.push(`${rootPath}${SUMMARY_PATH}`); 
            }}
        />
    </>);
}
export default DuringAssessmentContent;
