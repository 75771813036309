export const convertExternalDownloadsToFileSection = (externalDownloads) => {
    if (externalDownloads) {
        return {
                name: 'External Downloads',
                files: externalDownloads?.map( externalDownload => ({
                    name: externalDownload.name,
                    fileName: externalDownload.name,
                    publicUrl: externalDownload.url,
                    isPublicURL: true,
                })),
            };
    }
    return null;
}

export const termsAndAgreementDownloads = () => {
    return {
        name: 'Terms & Agreements',
        files: [
            {
                name: "Codes of Practice",
                fileName: "Codes of Practice FInal.pdf",
                publicUrl: "https://storage.googleapis.com/aruba-quality-seal.appspot.com/terms-agreements/Codes%20of%20Practice%20FInal.pdf",
                isPublicURL: true,
            },
            {
                name: "General Terms and Conditions",
                fileName: "General Terms and Conditions .pdf",
                publicUrl: "https://storage.googleapis.com/aruba-quality-seal.appspot.com/terms-agreements/General%20Terms%20and%20Conditions%20.pdf",
                isPublicURL: true,
            },
            {
                name: "Regulations Aruba Quality Seal",
                fileName: "Regulations Aruba Quality Seal Final.pdf",
                publicUrl: "https://storage.googleapis.com/aruba-quality-seal.appspot.com/terms-agreements/Regulations%20Aruba%20Quality%20Seal%20Final.pdf",
                isPublicURL: true,
            }
        ]
    };
}