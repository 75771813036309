import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    margin: 25px 0 ;
`;

const DocumentLink = styled.a`
    flex: 1;
    font-size: 14px;
    line-height:28px;
    color:#ed3132;
    text-decoration:none;
    &:hover {
        text-decoration:underline;
    }
`;

const Documents = ({document={}}) => {
    return (
        <Container>
            <DocumentLink 
                href={document.url}
                target="_blank" 
                rel="noopener noreferrer"
            >
                {document.title}
            </DocumentLink>
        </Container>
    );
}

export default Documents;