import React from 'react';
import {useHistory} from 'react-router';
import styled from 'styled-components';
import Button from '../../../shared-components/button';
import { DetailRow } from '../../../shared-components/detail-container';
import BigMessage from '../big-message';
import ElevatedOptions from '../elevated-options';
import { PATH as SUMMARY_PATH } from '../../business-assessment-summary-screen';


const Seperator = styled.div`
    width: 100%;
    height: 1px;
    border-bottom: solid 1px lightgrey;
`;

const completedMessageForAssessment = (assessment) => {
    if (!assessment) { return null };
    return `Marked as completed on the 10th of September 2019 at 11:59 pm`;
}

const scoreMessageForAssessment = (assessment) => {
    if (!assessment) { return null };
    const scored = assessment.totalPointsScored || 0;
    return `${scored} points`;
}

const CompletedAssessmentContent = ({ visible, assessment, reloadBusinessDetails, showMarkAsCompletedOption, showResetOption, rootPath}) => {
    const history = useHistory();

    if (!visible) return null;
    const showOptions = (showMarkAsCompletedOption || showResetOption);
    return (<>
        <BigMessage
            title={completedMessageForAssessment(assessment)}
        />
        <DetailRow title="Total Score" subtitle={scoreMessageForAssessment(assessment)} />
        <Button
            title="Assessment Summary"
            onClick={() => history.push(`${rootPath}${SUMMARY_PATH}`) }
        />
        {showOptions && <Seperator />}
        <ElevatedOptions
            assessment={assessment}
            reloadBusinessDetails={reloadBusinessDetails}
            showMarkAsCompletedOptions={showMarkAsCompletedOption}
            showResetOption={showResetOption}
        />
    </>);
}
export default CompletedAssessmentContent;
