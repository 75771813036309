import React, { useState, useEffect } from 'react';
import DownIcon from '../../../shared-components/icons/down-icon';
import styled from 'styled-components';

const BusinessNav = styled.nav`
    margin:0;
    padding:0;
    position:relative;
`;

const BusinessSelect = styled.span`
    display:flex;
    font-size:14px;
    align-items:center;
    color:rgba(255,255,255,1);
    cursor:pointer;
    div {
        margin-left:8px;
        svg {
            width:14px;
        }
    }
`;

const BusinessItemTitle = styled.span`
    padding:8px 15px;
    margin-bottom:0;
    display:block;
    color:grey;
    font-size:14px;
    margin-top:8px;
`;

const BusinessList = styled.div`
    list-style-type:none;
    margin:0;
    padding:0;
    position:absolute;
    display: flex;
    flex-direction: column;
    position: absolute;
    top:0;
    width: 200px;
    min-height: 100px;
    max-height: 320px;
    overflow: scroll;
    background-color:white;
    box-shadow: 1px 1px 5px grey;
    border-radius: 8px;
    opacity:${({ isOpen }) => isOpen ? 1 : 0};
    visibility:${({ isOpen }) => isOpen ? 'visible' : 'hidden'};
    transition: opacity 300ms;
`;

const BusinessItem = styled.div`
    width:100%;
`;

const Divider = styled.span`
    border-bottom: 1px solid rgba(63,63,68,.1);
    display:block;
`;

export const Option = styled.span`
    padding: 10px 15px;
    display: block;
    width: auto;
    cursor:pointer;
    position: relative;
    font-size:14px;
    &:hover {
        background-color:rgba(63,63,68,.1);
    }
`;

const BusinessListMenu = ({title='Choose Business', onClickRegisterBusiness, onClickSeeAllBusinesses, businesses=[], onClickBusiness}) => {
    const [isOpen, setIsOpen] = useState();
    const onWindowClick = function (e) {
        if (document.getElementById('business-select').contains(e.target)) {
            setIsOpen(!isOpen);
        }
        else {
            setIsOpen(false);
        }
    }

    useEffect(() => {
        window.addEventListener('click', onWindowClick);
        return () => {
            window.removeEventListener('click', onWindowClick);
        }
    });

    const hasBusinesses = (businesses.length > 0);

    return (
        <BusinessNav>
            <BusinessSelect id='business-select'>
                {title}<DownIcon />
            </BusinessSelect>
            <BusinessList 
                isOpen={isOpen} 
                id="business-list-menu" 
            >
                <BusinessItem>
                    <Option onClick={ onClickSeeAllBusinesses }>See All Businesses</Option>
                    <Option onClick={ onClickRegisterBusiness }>Register New Business</Option>
                    {hasBusinesses && <>
                        <Divider />
                        <BusinessItemTitle> Recent businesses </BusinessItemTitle>
                        {businesses && businesses.map( (b, i) =>
                            <Option key={i} onClick={ () => {
                                setIsOpen(false);
                                (onClickBusiness && onClickBusiness(b)) 
                            }}>
                                {b.name}
                            </Option>
                        )}
                    </>}
                </BusinessItem>
            </BusinessList>
        </BusinessNav>
    );
}

export default BusinessListMenu;