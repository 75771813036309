import React from 'react';
import Container from './icon-container';
import SVG from './svg';

const InfoIcon = ({className}) => (
    <Container className={className}>
        <SVG 
            aria-hidden="true" 
            focusable="false" 
            data-prefix="fal" 
            data-icon="info-circle" 
            className="svg-inline--fa fa-info-circle fa-w-16" 
            role="img" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 512 512"
        >
            <path 
                fill="currentColor" 
                d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-36 344h12V232h-12c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h48c6.627 0 12 5.373 12 12v140h12c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12h-72c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12zm36-240c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32z"
            >
            </path>
        </SVG>
    </Container>
);
export default InfoIcon;
