import React, { useState } from 'react';
import styled from 'styled-components';
import OptionsIcon from '../../../shared-components/icons/options-icon';
import {default as BaseShowPopUp} from '../../../shared-components/show-pop-up';
import {hasRoles} from '../../../utils/user';
import { setUserRole } from '../../../utils/server-functions';
import {CMSLoader} from '@cr38te/cr38te-cms';

const OPTION_HEIGHT = 60;

const ShowPopUp = styled(BaseShowPopUp)`
    .Loader {
        height: 30px;
    }
`;

const OptionsButton = styled(OptionsIcon)`
    position: relative;

    width: 30px;
    height: 30px;

    color: ${({disabled}) => disabled ? 'lightgrey' : 'grey'};

    :hover {
        cursor:pointer;
        color: ${({disabled}) => disabled ? 'lightgrey' : 'black'};
    }
`;

const MenuContainer = styled.div`
    position: absolute;
    top: ${({numberOfOptions=0}) => -(numberOfOptions * OPTION_HEIGHT - 20) / 2}px;
    left: -200px;
    width: 200px;
    height: fit-content;
    background-color: white;
    z-index:1;
    opacity: ${ ({visible}) => visible ? '1' : '0' };
    transition: opacity 300ms;
    border:1px solid lightgrey;
    box-shadow: 1px 1px 3px lightgrey;
    border-radius: 8px 8px 8px 8px;
    padding: 8px 0 8px 0;
    overflow-y: scroll;
    padding-top:0px;
    padding-bottom:0px;

    /** Flex properties */
    display: flex; 
    flex-direction: column;
`;

const MenuOption = ({children, ...props}) => {
    
    const Container = styled.div` 
        width: 100%;
        height: ${OPTION_HEIGHT}px;
        border-bottom: 1px lightgrey solid;
        background-color:rgba(253, 232, 229, 0.5);
        cursor:pointer;
        color:black;
        display: flex;
        justify-content: center;
        align-items: center;

        :hover {
            background-color: lightgrey;
        }
        &:last-child {
            border-bottom:1px;
        }
    `;
    
    const Text = styled.span` 
        font-size: 14px;
        font-weight: 400;
    `;

    return (
        <Container {...props}><Text>{children}</Text></Container>
    );
};

const createSetRoleFunction = (
    userUID, 
    role, 
    setLoading=(_ => null ), 
    onUserRoleSet=(_ => null)
) => async () => {
    if (role === 'admin') {
        const userConfirms = window.confirm('Are your sure you want to make this user an admin? It cannot not be undone.');
        if (!userConfirms)
            return;
    }
    try {
        await setLoading(true);
        await setUserRole(userUID, role);
        await onUserRoleSet(userUID, role);

    }
    catch( error ) {
        console.error(error);
    }
    finally {
        await setLoading(false);
    }
}

const countOptions = (options) => Object.values(options).reduce( 
    (total, option) => {
        return (total + (option === true ? 1 : 0));
    }, 0
);

const Menu = ({visible, activeUserRole, userRole, userUID, onUserRoleSet, onSettingUserRole}) => {
    let options = {
        makeAdmin: false, 
        makeATA: false, 
        makeLeadAuditor: false, 
        makeAuditor: false, 
        makePartner: false
    };

    if (activeUserRole === 'admin') {
        options.makeAdmin = !hasRoles(userRole, 'admin');
        options.makeATA = !hasRoles(userRole, 'admin', 'ata');
        options.makeLeadAuditor = !hasRoles(userRole, 'admin', 'leadAuditor');
        options.makeAuditor = !hasRoles(userRole, 'admin', 'auditor');
        options.makePartner = !hasRoles(userRole, 'admin', 'partner');
    }
    else if (hasRoles(activeUserRole, 'ata', 'leadAuditor', 'auditor') ) {
        options.makeAdmin = false;
        options.makeATA = hasRoles(activeUserRole, 'ata') && hasRoles(userRole, 'partner');
        options.makeLeadAuditor = hasRoles(activeUserRole, 'leadAuditor') && hasRoles(userRole, 'partner', 'auditor');
        options.makeAuditor = hasRoles(activeUserRole, 'auditor') && hasRoles(userRole, 'partner');
        options.makePartner = hasRoles(userRole, activeUserRole);
    }

    return (
        <MenuContainer visible={visible} numberOfOptions={countOptions(options)}>
            {options.makeAdmin && 
                <MenuOption onClick={ 
                    createSetRoleFunction(userUID, 'admin', onSettingUserRole, onUserRoleSet) 
                }>
                    Make Administrator
                </MenuOption> 
            }
            {options.makeATA && 
                <MenuOption onClick={ 
                    createSetRoleFunction(userUID, 'ata', onSettingUserRole, onUserRoleSet) 
                }>
                    Make ATA
                </MenuOption>
            }
            {options.makeLeadAuditor && 
                <MenuOption onClick={ 
                    createSetRoleFunction(userUID, 'leadAuditor',  onSettingUserRole, onUserRoleSet) 
                }>
                    Make Lead Auditor
                </MenuOption>
            }
            {options.makeAuditor && 
                <MenuOption onClick={ 
                    createSetRoleFunction(userUID, 'auditor', onSettingUserRole, onUserRoleSet) 
                }>
                    Make Auditor
                </MenuOption>
            }
            {options.makePartner && 
                <MenuOption onClick={ 
                    createSetRoleFunction(userUID, 'partner', onSettingUserRole, onUserRoleSet) 
                }>
                    Make Business
                </MenuOption>
            }
        </MenuContainer>
    );
}

const Options = ({userRole, userUID, activeUserRole, onUserRoleSet}) => {
    const disabled = (userRole === 'admin');
    const [loading, setLoading] = useState(false);
    return (
        <ShowPopUp 
            PopUpComponent={ ({visible}) => 
                <Menu 
                    visible={visible} 
                    userRole={userRole} 
                    userUID={userUID} 
                    activeUserRole={activeUserRole} 
                    onUserRoleSet={onUserRoleSet} 
                    onSettingUserRole={setLoading}
                /> 
            }
            disabled={disabled}
        >
            {loading ?
                <CMSLoader animating={true} width={10} />
            :
                <OptionsButton disabled={disabled}/>
            }
        </ShowPopUp>
    );
}

export default Options;