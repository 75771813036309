import React, { Component } from 'react';
import styled from "styled-components";
import SectionSearchBar from '../section-search-bar';
import { CMSLoader } from '@cr38te/cr38te-cms';
import PagingInfo from '../paging-info';
import Message from '../message';

export const PATH = '/users';

const Container = styled.div`
    min-height: 200px;
    height: 100%;
    
`;

const ErrorMessage = styled(Message)`
    display: block;
    padding-bottom: 30px;
    padding-top : 30px;
    text-align: center;
`;

class PagedList extends Component {

    state = {
        loading: true,
        rowsPerPage: 50,
        pageTokens: [null],
        currentPageIndex: 0,
        searchTerm: '',
    };

    componentDidMount() {
        this.loadResults();
    }

    loadResults = async () => {
        this.setState({ loading: true });
        const { loadResultsHandler = ( () => {}) } = this.props;
        const { rowsPerPage, pageTokens, currentPageIndex, searchTerm } = this.state;
        try {
            const currentPageToken = pageTokens[currentPageIndex];
            const results = await loadResultsHandler(rowsPerPage, currentPageToken, searchTerm);
            const { pageToken, ...data } = results;
            pageTokens[currentPageToken + 1] = (pageToken === undefined ? null : pageToken);
            this.setState({ ...data});
        }
        catch (error) {
            console.error(error);
            this.setState({ error });
        }
        this.setState({ loading: false });
    }
    
    onSetRowsPerPage = rowsPerPage => {
        this.setState({ rowsPerPage }, this.loadResults);
    }

    onSetSearchTerm = searchTerm => { 
        this.setState({ searchTerm, pageTokens: [null] }, this.loadResults);
    };
    
    onClickPrevious = () => {
        const {currentPageIndex} = this.state;
        const previousCurrentPageIndex = Math.max(currentPageIndex - 1, 0);
        this.setState({ currentPageIndex: previousCurrentPageIndex }, this.loadResults);
    };
   
    onClickNext = () => {
        const {currentPageIndex, pageTokens} = this.state;
        const nextCurrentPageIndex =  Math.min(currentPageIndex + 1, pageTokens.length - 1);
        this.setState( { currentPageIndex: nextCurrentPageIndex }, this.loadResults);
    };

    render() {
        let {
            loading,
            rowsPerPage,
            pageTokens,
            currentPageIndex,
            searchTerm,
            error,
            ...restState
        } = this.state;

        const {
            ListComponent = () => null
        } = this.props;

        return (
            <Container>
                <SectionSearchBar
                    searchTerm={searchTerm}
                    onSetSearchTerm={this.onSetSearchTerm}
                />
                {loading ?
                    <CMSLoader animating={true} position="relative" />
                    :
                    <>
                        
                        {error && 
                            <ErrorMessage>Oops an error occured. Please try again later.</ErrorMessage>
                        }
                        {!error && 
                            <ListComponent {...restState} /> 
                        }
                        <PagingInfo
                            rowsPerPage={rowsPerPage}
                            hasPrevious={ currentPageIndex > 0 }
                            hasNext={ pageTokens[currentPageIndex + 1] !== null }
                            onChangeRowsPerPage={this.onSetRowsPerPage}
                            onClickPrevious={ this.onClickPrevious }
                            onClickNext={ this.onClickNext }
                        />
                    </>
                }
            </Container>
        );
    }
}
export default PagedList;