import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
    margin-bottom: 25px;
    padding-bottom: 25px;
    color: currentColor;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    border-bottom:1px solid rgba(63,63,68,.1);

    svg {
        width:80%;
        height:auto;
        color:#005270;

        @media only screen and (max-width: 600px) {
            width:70%;  
        }
    }

    @media only screen and (max-width: 600px) {
        margin-bottom: 15px;
        padding-bottom: 15px;   
    }
`;

export const MainTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    width:100%;
    margin-bottom:5px;

    div {
        min-width: 40px;
        width: 40px;
        max-width: 40px;
        height: 40px;
        min-height: 40px;
        max-height: 40px;

        @media only screen and (max-width: 600px) {
            min-width: 30px;
            width: 30px;
            max-width: 30px;
            height: 30px;
            min-height: 30px;
            max-height: 30px;
        }
    }
`;

export const Title = styled.span`
    font-size: 28px;
    font-weight:400;
    letter-spacing:0.05em;
    display:inline-block;
    width: 100%;
    color:#005270;

    @media only screen and (max-width: 600px) {
        font-size:19px;
    }
`;

export const Detail = styled.span`
    font-size:14px;
    line-height:28px;
    font-weight:400;
    color:#808080;
`;

const DetailHeader = ({title, detail, Icon=(()=>null), children, ...props}) => {
    return (
        <Container {...props} >
            <MainTitleContainer>
                <Icon /> 
                <Title>{title}</Title>
            </MainTitleContainer>
            <Detail>{detail}</Detail>
            {children}
        </Container>
    );
}
export default DetailHeader;

export const applyIconStyle = (Icon) => styled(Icon)`
    color: black;
    min-width: 100px;
    width: 100px;
    max-width: 100px;
    height: 100px;
    min-height: 100px;
    max-height: 100px;
`;
