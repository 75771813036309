import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    padding-bottom: 15px;
    flex-shrink: 0;
    border-bottom: solid 1px lightgrey;
`;

const Title = styled.div`
    font-size:14px;
    line-height:28px;
    font-weight:700;
    flex-shrink: 0;
`;

const Subtitle = styled.div`
    font-size:14px;
    line-height:28px;
    font-weight:400;
    flex-shrink:0;

    a {
        color:#00bdeb;
        text-decoration:underline;
    }
`;

const DetailRow = ({title, subtitle, children}) => (
    <Container>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        {children}
    </Container>
)
export default DetailRow;