import React, { useState } from 'react';
import styled from 'styled-components';
import UserItem from './user-item';
import ListHeader from './list-header';

const Container = styled.div`
    margin-bottom: 0;
`;

const UserList = ({users=[], activeUserRole}) => {
    const [_, update] = useState(0);
    const onUserRoleSet = (userUID, role) => {
        const user = users.find( user => user.uid === userUID) || {};
        user.userRole = role;
        console.log(user);
        update(_ + 1);
    };
    
    return (
        <Container>
            <ListHeader />
            {users.map( (user, index) => {
                const created = user?.created && new Date(user?.created);
                const signedIn = user?.signedIn && new Date(user?.signedIn);
                const options = {year: 'long', month: 'long', day: 'long'};
                const formattedCreated = created ? created.toLocaleDateString(options) : '-';
                const formattedSignedIn = signedIn ? signedIn.toLocaleDateString(options) : '-';
                return (
                    <UserItem 
                        key={index}
                        email={user.email}  
                        created={formattedCreated}  
                        signedIn={formattedSignedIn}  
                        userRole={user.userRole}
                        userUID={user.uid}
                        activeUserRole={activeUserRole}
                        onUserRoleSet={onUserRoleSet}
                    />
                );
            })}
        </Container>
    );
}
export default UserList;