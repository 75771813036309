import React, { useState } from 'react';
import Button from '../../../shared-components/button';
import { markBusinessAssessmentAsCompleted, resetBusinessAssessment } from '../../../utils/server-functions';

const ElevatedOptions = ({assessment, reloadBusinessDetails, showMarkAsCompletedOptions, showResetOption}) => {
    const [markingAsCompleted, setMarkingAsCompleted] = useState(false);
    const [resetting, setResetting] = useState(false);
    
    return (<>
        {showMarkAsCompletedOptions &&
            <Button
                title={`Mark As ${assessment.completed ? 'Not ' : ''}Completed`}
                loading={markingAsCompleted}
                onClick={async () => {
                    const confirmed =window.confirm(
                        `Are you sure you want to mark this online assessment as ${assessment.completed ? 'not ' : ''}completed?`
                    );
                    if (confirmed) {
                        try {
                            setMarkingAsCompleted(true);
                            await markBusinessAssessmentAsCompleted(assessment.id, !assessment.completed);
                            await reloadBusinessDetails();
                        }
                        catch (error) {
                            console.log(error);
                            alert(error);
                        }
                        finally {
                            setMarkingAsCompleted(false);
                        }
                    }
                }}
            />
        }
        {showResetOption && 
            <Button
                title="Reset"
                loading={resetting}
                onClick={ async () => {
                    const confirmed =window.confirm(
                        `Are you sure you want to reset this online assessment?`
                    );
                    if (confirmed) {
                        try {
                            setResetting(true);
                            await resetBusinessAssessment(assessment.id);
                            await reloadBusinessDetails();
                        }
                        catch (error) {
                            console.log(error);
                            alert(error);
                        }
                        finally {
                            setResetting(false);
                        }
                    }
                }}
            />
        }
    </>);
}
export default ElevatedOptions;
