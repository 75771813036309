import styled from "styled-components";
import { CMSTextField } from "@cr38te/cr38te-cms";

export const applyBasicStyling = (component) => styled(component)`
    label, 
    .Message {
        font-weight: ${({theme}) => theme.weightBold };
        font-family: ${({theme}) => theme.fontRoboto };
        font-size:14px;
    line-height:28px;
    font-weight:700;
    flex-shrink: 0;
    }
    .Message {
        font-weight: ${({theme}) => theme.weightRegular };
        margin-top:0;
        ${({error}) => error && `color: red;` };
    }
    .InputContainer {
        border:0;
        select {
            padding-left:0px;
        }
        select,
        input {
            width:100%;
            height:40px;
            border-radius:0;
            border:1px solid #ccc;
            box-shadow:none;
            font-size:13px;
            padding:.375rem 1.75rem .375rem .75rem;
        }
    }
`;

const TextField = applyBasicStyling(CMSTextField);

export default TextField;