import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: lightgrey;
`;

const Message = styled.span`
    font-size: 15px;
`;

const SVG = styled.svg`
    width: 100px;
    height: 100px;  
`;

const EmptyNotifications = () => {
    return (
        <Container>
            <SVG 
                aria-hidden="true" 
                focusable="false" 
                data-prefix="fad" 
                data-icon="couch" 
                className="svg-inline--fa fa-couch fa-w-20" 
                role="img" 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 640 512"
            >
                <g 
                    className="fa-group"
                >
                    <path 
                        className="fa-secondary" 
                        fill="currentColor" 
                        d="M96 160H64a96 96 0 0 1 96-96h320a96 96 0 0 1 96 96h-32a64.06 64.06 0 0 0-64 64v64H160v-64a64.06 64.06 0 0 0-64-64z" 
                        opacity="0.4"
                    >
                    </path>
                    <path 
                        className="fa-primary" 
                        fill="currentColor" 
                        d="M640 256a63.84 63.84 0 0 1-32 55.1V432a16 16 0 0 1-16 16h-64a16 16 0 0 1-16-16v-16H128v16a16 16 0 0 1-16 16H48a16 16 0 0 1-16-16V311.1A63.79 63.79 0 0 1 64 192h32a32 32 0 0 1 32 32v96h384v-96a32 32 0 0 1 32-32h32a64.06 64.06 0 0 1 64 64z"
                    >
                    </path>
                </g>
            </SVG>
            <Message>No notifications</Message>
        </Container>
    );
}
export default EmptyNotifications;

