import React from 'react';
import styled from 'styled-components';
import Document from './document';
import EditorSectionTitle from '../../../shared-components/editor-section-title';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 25px 0px 25px 0px;
    border-bottom: solid 1px lightgrey;
    margin-bottom: 15px;
`;

const RelatedDocuments = ({visible=true, documents=[]}) => {
    if (!visible) {
        return null;
    }
    return (
        <Container>
            <EditorSectionTitle>Related Documents</EditorSectionTitle>
            {documents.map( (d,i) => 
                <Document key={i} document={d} />    
            )}
        </Container>
    );
}

export default RelatedDocuments;