import React from 'react';
import styled from 'styled-components';

const CommentBoxInput = styled.textarea`
    margin-top: 15px;
    resize: none;
    height: 150px;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    line-height:28px;
    border-radius: 0px;
    border: 1px solid #cccccc;
    padding:10px;
`;

const CommentBox = ({children, ...props}) => (
    <CommentBoxInput 
        row="4" 
        placeholder="Comment (required)" 
        {...props}
    >
        {children}
    </CommentBoxInput>
);
export default CommentBox;