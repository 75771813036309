import React from 'react';
import { Route } from "react-router-dom";
import DetailHeader, { applyIconStyle } from '../../shared-components/detail-header';
import GlobeIcon from '../../shared-components/icons/globe-icon';
import { markBusinessOnSiteInspectionAsPassed, addBusinessOnSiteInspectionFeedbackMessage, setBusinessOnSiteInspectionReport } from '../../utils/server-functions';
import InspectionPassEditor from './inspection-pass-editor';
import Message from '../../shared-components/message';
import AuditorFeedbackEditor from '../business-detail-screen/auditor-feedback-editor';
import InspectionReportEditor from './inspection-report-editor';
import { hasRoles } from '../../utils/user';
import GroupElements from '../../shared-components/group-elements';
import Container from '../../shared-components/detail-section-container';

export const PATH = '/inspection';

const Icon = applyIconStyle(GlobeIcon);

const BusinessOnSiteInspection = (props) => {
    const {
        businessID,
        business = {},
        userRole,
        reloadBusinessDetails,
        user={}
    } = props;
    
    const registration = business.registration || {};
    const assessment = business.assessment || {};
    const elevatedRole = hasRoles(userRole, 'admin', 'leadAuditor', 'auditor');
    const messages = registration.onSiteInpectionFeedbackMessages || [];
    return (
        <Container>
            <GroupElements>
                <DetailHeader
                    Icon={Icon}
                    title={"Inspection"}
                    detail={"On-site inspection of your business."}
                />
                    {assessment.completed ?
                    <>
                        <InspectionPassEditor
                            passed={registration.onSiteInpectionPassed}
                            datePassed={registration.dateOnSiteInpectionPassed}
                            onMarkAsPassed={async (passed) => {
                                const confired = window.confirm(
                                    `Are you sure you want to mark the on site inspection` +
                                    ` of ${business.name} as ${passed ? '' : 'not '}passed?`
                                );
                                if (confired) {
                                    await markBusinessOnSiteInspectionAsPassed(businessID, passed);
                                    await reloadBusinessDetails();
                                }
                            }}
                            canEdit={ hasRoles(userRole, 'admin', 'leadAuditor', 'auditor') }
                        />
                        <InspectionReportEditor
                            visible={ elevatedRole || registration.onSiteInpectionPassed }
                            canEdit={ hasRoles(userRole, 'admin', 'leadAuditor', 'auditor') }
                            reportFileName={registration.onSiteInpectionReportFileName}
                            reportFilePath={registration.onSiteInpectionReportFilePath}
                            reportFileURL={registration.onSiteInpectionReportURL}
                            onSetReportFilePath={ async filePath => {
                                await setBusinessOnSiteInspectionReport(businessID, filePath);
                                await reloadBusinessDetails();
                            }}
                        />
                        <AuditorFeedbackEditor
                            visible={ elevatedRole || messages.length > 0 }
                            userRole={ userRole }
                            userUID={ user.uid }
                            onSend={ async ( message, filePath ) => {
                                await addBusinessOnSiteInspectionFeedbackMessage(businessID, message, filePath);
                                await reloadBusinessDetails();
                            }}
                            messages={ messages }
                            editorID={ 'business-on-site-inspection-feedback-editor' } 
                        />
                    </>
                    :
                    <>
                        <Message>After you have completed the online assessment an auditor will do an on-site inspection at your busines and list the feedback here.</Message>
                    </>
                }
            </GroupElements>
        </Container>
    );
}
export default BusinessOnSiteInspection;

export const businessOnSiteInspectionScreenRoutes = ({ rootPath, ...props }) => {
    return [
        <Route path={`${rootPath}${PATH}`} exact key={'business-on-site-inspection-screen'} render={(routeProps) =>
            <BusinessOnSiteInspection {...props} {...routeProps} rootPath={rootPath} />
        } />
    ];
}