import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import QuestionButton from './question-button';
import RadioOptions from './radio-options';
import Button from '../../../shared-components/button';
import CommentBox from '../../../shared-components/comment-box';
import EditorSectionTitle from '../../../shared-components/editor-section-title';
import { getSaveMessage } from '../helpers';
import MandatoryButton from './mandatory-button';
import AnsweredButton from './answered-button';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 25px 0px 25px 0px;
    border-bottom: solid 1px lightgrey;
`;

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    flex-wrap: wrap;

    @media only screen and (max-width: 600px) {
        .button {
            margin-right:0;
            min-width:calc( 100vw - 30px );
        }
    }
`;

const Question = styled.div` 
    font-size: 14px; 
    line-height:28px;   
    max-width: 650px;
    margin-bottom: 25px;
`;

const SaveInfo = styled.span`
    margin-top: 15px;
    font-size: 14px; 
    line-height:28px;
    font-weight:bold;
    color:black;
    height: 30px;
`;

const QuestionEditor = ({
    numberPrefix='', 
    question={}, 
    canEditAnswer=true, 
    goToPreviousQuestion=(_=>_),
    hasPreviousQuestion=false,
    goToNextQuestion=(_=>_),
    hasNextQuestion=false,
    onSetQuestionAnswer=(_=>_)
}) => {
    const [fullNumber, setFullNumber] = useState(`${numberPrefix}.${question.number}`);
    const [answer, setAnswer] = useState(question.answer);
    const [answerComment, setAnswerComment] = useState(question.answerComment);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState();
    const [commentTimeout, setCommentTimeout] = useState();

    const [lastUsedSaveFunction, setLastUsedSaveFunction] = useState({});

    useEffect( () => {
        const newFullNumber = `${numberPrefix}.${question.number}`;
        if(fullNumber !== newFullNumber) {
            setFullNumber(newFullNumber);
            setAnswer(question.answer);
            setAnswerComment(question.answerComment);
            setSaving(false);
            setError(null);
        }
    }, [numberPrefix, question.number, question.answer, question.answerComment, fullNumber]);

    const onSave = async ({newAnswer=answer, newAnswerComment=answerComment}) => {
        setSaving(true);
        try {
            await onSetQuestionAnswer(newAnswer, newAnswerComment);
            setError(null);
        }
        catch(error) {
            setError(error);
        }
        setSaving(false);
    }

    const title = `Question ${fullNumber}`
    const saveMessage = getSaveMessage(
        saving, error, (answer||answerComment), lastUsedSaveFunction.call, (answer||answerComment)
    );
    return (
        <Container>
            <RowContainer>
                <EditorSectionTitle>{title}</EditorSectionTitle>
                {question.mandatory &&  
                    <MandatoryButton answered={question.answered} answer={answer} correctAnswer={'Yes'}/>
                }
                <AnsweredButton answered={question.answered} />
            </RowContainer>
            <Question>{question.question}</Question>
            {question.extraInfo && 
                <QuestionButton tooltipText={question.extraInfo} />
            }
            <RadioOptions 
                name={`${fullNumber}-answer`} 
                options={['Yes', 'No', 'Not Applicable']} 
                disabled={ (!canEditAnswer || saving) }
                selectedOption={ answer }
                onChange={async (answer) => {
                    setAnswer(answer);
                    onSave({newAnswer: answer});
                }}
            />
            <CommentBox
                value={ answerComment || ''}
                disabled={ (!canEditAnswer || !((answer||'').length > 0)) }
                onChange={async ({target}) => {
                    const answerComment = target.value;
                    setAnswerComment(answerComment);
                    clearTimeout(commentTimeout);
                    let timeout = setTimeout( () => onSave({newAnswerComment: answerComment}), 800);
                    setLastUsedSaveFunction( {call: () => onSave({newAnswerComment: answerComment})} );
                    setCommentTimeout(timeout);
                }}
            />
            <SaveInfo>{saveMessage}</SaveInfo>
            <RowContainer>
                <Button 
                    style={{
                        width: '100%'
                    }}
                    title='<< previous'
                    disabled={!hasPreviousQuestion}
                    onClick={goToPreviousQuestion}
                    className="button"
                />
                <Button 
                    title={'Next >>'}
                    disabled={!hasNextQuestion || saving}
                    onClick={goToNextQuestion}
                    loading={saving}
                    className="button"
                />
            </RowContainer>
        </Container>
    );
}

export default QuestionEditor;