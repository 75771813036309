import React from 'react';
import styled from 'styled-components';
import AuditLogItem from './audit-log-item';
import ListHeader from './list-header';
import DaySectionItem from './day-section-item';

const Container = styled.div`
    margin-bottom: 15px;
`;

const dateFormatOptions = { year: 'numeric', month: 'long', day: 'numeric'};
const timeFormatOptions = { hour: '2-digit', minute: '2-digit' };

const AuditLogList = ({logItems=[]}) => {
    if (!logItems.formatted) {
        let currentSectionDay = null; 
        logItems.formatted = logItems.reduce( (list, item) => {
            const itemDate = new Date(item?.date?._seconds * 1000);
            const itemDay = itemDate.toLocaleDateString("en-US", dateFormatOptions);
            if (currentSectionDay !== itemDay) {
                list.push({day: itemDay});
                currentSectionDay = itemDay;
            }
            list.push(item);
            return list;
        }, []);
    };
    return (
        <Container>
            <ListHeader />
            {logItems.formatted.map( (logItemOrDay, index) => {
                if (logItemOrDay.day) {
                    return <DaySectionItem key={index} day={logItemOrDay.day} />
                }

                if (logItemOrDay.date && !logItemOrDay.dateAsDate) {
                    const dateAsDate = new Date(logItemOrDay.date._seconds * 1000);
                    logItemOrDay.formattedDate = dateAsDate.toLocaleTimeString("en-US", timeFormatOptions);
                }
                return (
                    <AuditLogItem 
                        key={index}
                        date={ logItemOrDay.formattedDate }
                        userDescription={ logItemOrDay.userDescription }
                        eventDescription={ logItemOrDay.eventDescription }
                    />
                );
            })}
        </Container>
    );
}
export default AuditLogList;