import React from 'react';
import { useHistory } from "react-router";
import styled from 'styled-components';
import Button from '../../shared-components/button';


const StepContent = styled.div`
    margin-top:90px;
    padding-top:30px;
    border-top:1px solid lightgrey;
`;

const Title = styled.h3`
    margin-top:0;
    margin-bottom:15px;
    font-size: 21px;
    font-weight: 400;
    letter-spacing: 0.05em;
    color:#005270;
`;

const Content = styled.span`
    font-size:14px;
    line-height:28px;
`;

const ProgressContent = ({title, content, titleButton, toPath}) => {
    const history = useHistory();

    return (
        <StepContent>
            <Title>{title}</Title>
            <Content>{content}</Content>
            <Button
                title={`${titleButton}`}
                onClick={ () => history.push(toPath) } 
            /> 
        </StepContent>
    );
}
export default ProgressContent;