import React from 'react';
import Container from './icon-container';
import SVG from './svg';

const RightIcon = ({ className, ...otherProps }) => (
    <Container className={className} {...otherProps} >
        <SVG 
            aria-hidden="true" 
            focusable="false" 
            data-prefix="far" 
            data-icon="chevron-right" 
            className="svg-inline--fa fa-chevron-right fa-w-8" 
            role="img" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 256 512"
        >
            <path 
                fill="currentColor" 
                d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"
            >
            </path>
        </SVG>
    </Container>
);

export default RightIcon;