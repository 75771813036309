import styled from 'styled-components';
import React, { useState } from 'react';
import ManagedUploadFileField from '../../../shared-components/managed-file-upload-field';
import FirebaseSingleFileUploadManager from '../../../utils/firebase-single-file-upload-manager';
import EditorSectionTitle from '../../../shared-components/editor-section-title';
import Message from '../../../shared-components/message';
import MediaFileLink from '../../../shared-components/media-file-link';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 25px 0px 25px 0px;
    border-bottom: solid 1px lightgrey;
`;

const InspectionReportEditor = ({ visible=false, canEdit=false, reportFilePath, reportFileName, reportFileURL, onSetReportFilePath }) => {
    const [settingReportURL, setSettingReportURL] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    let [uploadManager] = useState(new FirebaseSingleFileUploadManager());
    
    if (!visible) {
        return null;
    }
    return (
        <Container>
            <EditorSectionTitle>Inspection Report</EditorSectionTitle>
            {(true&&canEdit) && 
                <ManagedUploadFileField
                    title={`Upload Report (PDF) *`}
                    fileUploadManager={ uploadManager }
                    onUploadCompleted={async (filePath) => {
                        setSettingReportURL(true);
                        setUploadError(null);
                        try {
                            await onSetReportFilePath(filePath);
                        }
                        catch(error) {
                            setUploadError(error);
                        }
                        setSettingReportURL(false);
                    }}
                    error={uploadError}
                    disabled={ settingReportURL }
                />
            }
            {(!settingReportURL && reportFilePath) ?
                <MediaFileLink
                    fileName={`Download ${reportFileName || 'Report'}`}
                    fileURL={ reportFileURL }
                    filePath={ reportFilePath }
                />
                :
                <>{!canEdit && <Message>No Report Available</Message>}</>
            }
        </Container>
    );
}

export default InspectionReportEditor;