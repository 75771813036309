import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import BellIcon from '../icons/bell-icon'
import CardIcon from '../icons/card-icon';
import CheckIcon from '../icons/check-icon';
import FileIcon from '../icons/file-icon';
import HomeIcon from '../icons/home-icon';
import InfoIcon from '../icons/info-icon';
import GlobeIcon from '../icons/globe-icon';
import { useHistory } from 'react-router';
import EmptyNotifications from './empty-notification';
import { 
    latestNotificationsOnSnapshot, 
    clearNewNotifications 
} from '../../utils/server-functions';
import MinusIcon from '../icons/minus-icon';

const CloseButton = styled(MinusIcon)`
    position: absolute;
    top: 20px;
    right: 20px;
    color: black;
    :active {
        color: grey;
    }
`;

const IconsTypes = {
    'bell': BellIcon,
    'card': CardIcon,
    'check': CheckIcon,
    'file': FileIcon, 
    'home': HomeIcon,
    'info': InfoIcon,
    'globe': GlobeIcon,
};

const Container = styled.div`
    padding: 0;
    width: auto;
    display:block;
    float:right;
    cursor: pointer;
    justify-content:center;
`;

const IconWrapper = styled.div`
    position:relative;
    margin-right:10px;
`;

const IconBadge = styled.div`
    position:absolute;
    top: -5px;
    right: -9px;
    background-color:#990000;
    width: 21px;
    height: 15px;
    text-align: center;
    border-radius: 5px;
    color:white;
    font-size: 10px;
    font-weight: bold;
    line-height: 15px;
`;


const NotificationContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 65px;
    right: 65px;
    width: 500px;
    min-height: 100px;
    overflow: scroll;
    background-color:white;
    border:1px solid lightgrey;
    box-shadow: 1px 1px 5px grey;
    border-radius: 0 0 0 0;
    opacity: 1;
    visibility: display;
    transition: opacity 300ms;

    @media only screen and (max-width: 600px) {
        top:50px;
        right:0px;
        width: 100%;
        max-height: calc(${window.innerHeight}px - 50px);
        height: calc(${window.innerHeight}px - 50px);
    }
`;

const Header = styled.div`
    position: relative;

    background-color:#f5f5f5;
    text-align:center;
    font-weight:bold;
    font-size:12pt;
    letter-spacing:0.2px;
    padding-top:20px;
    padding-bottom:20px;
    border-bottom:0.4pt solid rgba(210,210,210,0.5);
`;

const NotificationList = styled.ul`
    overflow-y:scroll;
    list-style-type:none;
    margin:0;
    padding:0;
`;

const NotificationItem = styled.li`
    display:block;
`;

const NotificationLink = styled.a`
    padding:15px 15px;
    display:block;
    width:auto;
    position:relative;
    border-bottom:1px solid rgba(63,63,68,.1);
    &:hover {
        background-color:#f5f5f5;
        div {
            color:#00bdeb;
        }
    }
`;

const NotificationIconWrapper = styled.div`
    display:block;
    position:absolute;
    top:18px;
    left:15px;

    > div {
        display:block;
        text-align:center;
        min-width: 40px;
        width: 40px;
        max-width: 40px;
        height: 40px;
        min-height: 40px;
        max-height: 40px;
        background-color:#005270;
        border-radius:50%;
        > svg {
            color:#acc236;
            width:15px;
        }
    }
`;

const NotificationDate = styled.div`
    font-size:11px;
    color:#b7b9cc;
    line-height:24px;
    font-weight:400;
    padding-left:50px;
`;

const NotificationMessage = styled.div`
    font-size:13px;
    letter-spacing:0.2px;
    font-weight:700;
    padding-left:50px;
`;

const hasChanges = (notifications, newNotifications) => {
    if (newNotifications.length === notifications.length) {
        for (let i = 0; i < newNotifications.length; i++) {
            const someNotification = newNotifications[i];
            const index = notifications.findIndex( n => n.id === someNotification.id);
            if (index === -1) {
                return true;
            }
            else {
                //compare them.
                const otherNotification = notifications[index];
                if(
                    otherNotification.message !== someNotification.message 
                    ||
                    otherNotification.viewed !== someNotification.viewed
                    || 
                    otherNotification.date !== someNotification.date
                ) {
                    return true;
                }
            }
        }
        return false;
    }
    return true;
}

const dateFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute:'numeric'};

const NotificationListMenu = (props) => {
    const menuID = props.id || 'notification-select';
    const history = useHistory();
    const {onReload} = props;

    const [isOpen, setIsOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [clickTimerHandle, setClickTimerHandle] = useState(0);
    
    const onWindowClick = (e) => {
        if (document.getElementById(menuID).contains(e.target)) {
            const shouldOpen = !isOpen;
            setIsOpen(shouldOpen);

            clearTimeout(clickTimerHandle);
            if (shouldOpen) {
                const clearHandler = () => clearNewNotifications(notifications);
                const handle = setTimeout( clearHandler, 500);
                setClickTimerHandle(handle);
            }
        }
        else {
            setIsOpen(false);
        }
    }

    useEffect(() => {
        window.addEventListener('click', onWindowClick);
        const unsubscibeNotification = latestNotificationsOnSnapshot(         
            changes => {                
                //Check only for changes to prevent a feedback loop.
                if ( hasChanges(notifications, changes) ) {
                    (onReload && onReload());
                    setNotifications(changes);
                }
            }, 
            e => console.error(e) 
        );
        return () => {
            window.removeEventListener('click', onWindowClick);
            unsubscibeNotification();
        }
    });

    const newCount = notifications.reduce( (c, n) => c + (n.viewed ? 0 : 1), 0);
    return (
        <Container>
            <IconWrapper id={menuID} >
                <BellIcon />
                {newCount > 0 &&
                    <IconBadge>{newCount}</IconBadge>
                }
            </IconWrapper> 
            {isOpen && 
                <NotificationContainer>
                    <Header>
                        Notifications
                        <CloseButton onClick={ () => setIsOpen(false) }/>
                    </Header>
                    <NotificationList>
                        {notifications.map( ({iconType, date, message, path}, index) => {
                            const Icon = IconsTypes[iconType] || BellIcon;
                            const dateAsDate = date ? new Date(date.seconds * 1000) : new Date();
                            const formattedDate = dateAsDate.toLocaleDateString("en-US", dateFormatOptions);
                            return (
                                <NotificationItem key={index} >
                                    <NotificationLink onClick={ (e) => {
                                        e.preventDefault();
                                        history.push(path || '/');
                                        setIsOpen(false);
                                    }}>
                                        <NotificationIconWrapper>
                                            <Icon />
                                        </NotificationIconWrapper>
                                        <NotificationDate>{formattedDate}</NotificationDate>
                                        <NotificationMessage>{message || 'no content'}</NotificationMessage>
                                    </NotificationLink>
                                </NotificationItem>
                            );
                        })}
                        {notifications.length === 0 && 
                            <EmptyNotifications />
                        }
                    </NotificationList>
                </NotificationContainer>
            }
        </Container>
    );
}
export default NotificationListMenu;