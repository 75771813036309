import React from 'react';
import styled from 'styled-components';
import { attachClasses } from '../../../utils/styled';

export const SWITCH_TO_MOBILE_WIDTH = 413;
const padding = 5;

const Container = styled.div`
    position: sticky;

    top: -25px;
    border-bottom: 1px lightgrey solid;
    width:100%;
    display: flex;
    flex-direction: column;
    padding: ${padding}px;

    background: lightgray;
    z-index: 1;

    @media only screen and (max-width: 768px) {
        top: -30px;
    }

    @media only screen and (max-width: 600px) {
        top: 0px;
    }
`;

const Span = styled.span``;
const Property = styled( attachClasses(Span, 'property')  )`
    font-size:14px;
    font-weight:400;
    background: lightgray;
`;


const DayProperty = styled(  attachClasses(Property, 'time') )`
    font-weight:bold;
`;

const DaySectionItem = ({day, className}) => {
    return (
        <Container className={className}>
            <DayProperty >{day}</DayProperty>
        </Container>
    );
}
export default DaySectionItem;