import styled from "styled-components";

const IconContainer = styled.div`
    min-width: 20px;
    width: 20px;
    max-width: 20px;
    height: 20px;
    min-height: 20px;
    max-height: 20px;
    color: white;
    user-select: none;
`;

export default IconContainer;