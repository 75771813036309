import styled from "styled-components";

export const HEIGHT = '200px';
export const MOBILE_SMALL_WIDTH = '288px';
export const MOBILE_MEDIUM_WIDTH = '343px';
export const MOBILE_LARGE_WIDTH = '382px';
export const WIDTH = '248px';

const BusinessItemContainer = styled.a`
    margin-top:15px;
    box-sizing: border-box;
    height: ${HEIGHT};
    min-height: ${HEIGHT};
    max-height: ${HEIGHT};
    width: ${WIDTH};
    min-width: ${WIDTH};
    max-width: ${WIDTH};
    border: 1px #ccc ${({type='normal'}) => (type === 'add') ? 'dashed' : 'solid'};
    border-radius: 0;
    margin-bottom: 15px;

    @media (max-width: 320px) {
        margin: 0px;
        min-width: ${MOBILE_SMALL_WIDTH};
        max-width: ${MOBILE_SMALL_WIDTH};
        width:100%;
    }

    @media (min-width: 321px) and (max-width: 375px) {
        margin-right: 0;
        min-width: calc( ${MOBILE_MEDIUM_WIDTH} - 38px );
        max-width: calc( ${MOBILE_MEDIUM_WIDTH} - 38px );
        margin-bottom:7.5px;
        margin-top:7.5px;
    }

    @media (min-width: 376px) and (max-width: 564px) {
        margin-right: 0px;
        min-width: ${MOBILE_LARGE_WIDTH};
        max-width: ${MOBILE_LARGE_WIDTH};
    }

    @media (min-width: 565px) and (max-width: 768px) {
        margin-right: 7.5px;
        margin-left: 7.5px;
    }

    @media (min-width: 769px) {
        margin-right: 7.5px;
        margin-left: 7.5px;
    }

    display: flex;
`;
export default BusinessItemContainer;