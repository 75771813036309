import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 15px;
`;  

const Message = styled.span`
    text-size: 13pt;
    text-align: center;
    margin-bottom: 15px;
`;

const Link = styled.a`
    
`;

const MessageScreen = (props) => {
    let {message, linkTitle, to, history, ...otherProps} = props;
    return (
        <Container {...otherProps} >
            <Message>{message}</Message>
            <Link 
                href={to} 
                onClick={ (event) => {
                    if (history) {
                        history.push(to);
                        event.preventDefault();
                    }
                    return;
                }}
            >
                {linkTitle}
            </Link> 
        </Container>
    );
}
export default MessageScreen;