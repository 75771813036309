import styled from "styled-components";

const PopUp = styled.div` 
    background-color:rgba(0,0,0,0.8);
    color:white;
    border-radius:5px;
    padding: 15px;
    margin-bottom: 30px;
    font-size: 12px;
    line-height: 22px;
    position: absolute;
    z-index:61;
    bottom: 0;
    right: -15px;
    min-width: 250px;
    max-width: 480px;
    width: 100%;
    display:none;

    :after {
        content: '';
        position: absolute;
        right: 15px;
        bottom: -10px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid rgba(0,0,0,0.8);
        clear: both;
    }
`;

export default PopUp;