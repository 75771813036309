import React from 'react';
import styled from 'styled-components';

const Container = styled.a`
    
`;

const Link = ({children, openInNewTab=true, to, className, ...props}) => {
    return <Container 
        target={openInNewTab ? '_blank' : undefined}
        rel={openInNewTab ? 'noopener noreferrer' : undefined}
        href={to}
        className={className}
    >
        {children}
    </Container>
}
export default Link;
