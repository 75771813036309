import { PATH as BUSINESS_OVERVIEW_PATH } from '../business-overview-screen';

export const getSelectedSubPath = ({ match }) => {
    return (match && match.params && match.params.selected);
}

export const redirectToOverviewIfNeeded = ({ match, history }) => {
    let selected = getSelectedSubPath({ match });
    if (selected === undefined) {
        history.replace(`${match.url}${BUSINESS_OVERVIEW_PATH}`);
        return true;
    }
    return false;
}

export const getBusinessID = ({ match }) => {
    if (match && match.params && match.params.businessID) {
        return match.params.businessID;
    }
    return '';
}

export const GetAllAssessmentQuestions = sections => sections.map(({ questions = [] }) => questions).flat()
export const GetAnsweredQuestions = allQuestions => allQuestions.filter(({ answer }) => answer != null)
export const GetAnsweredQuestionsPercentage = (answeredQuestions, allQuestions) => Math.round(answeredQuestions.length / allQuestions.length * 100) || 0