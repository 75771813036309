import React from "react";
import styled from "styled-components";
import BusinessItemContainer from './list-item-container';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

TimeAgo.addLocale(en);

const AttributeContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
`;

const Name = styled.span`
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 5px;
`;

const Detail = styled.span`
    font-weight: normal;
    font-size: 14px;
`;

const RightDetail = styled.span`
    font-weight: normal;
    font-size: 12px;
    color: grey;
    flex-basis: 60px;
    text-align: right;
    flex: 1;
`;

const BusinessListItem = ({ name, type, onClick, updatedTimestamp}) => {
    const updated = updatedTimestamp ? new Date(updatedTimestamp._seconds * 1000) : new Date();
    const timeAgo = new TimeAgo('en-US')
    const formattedUpdated = `Updated ${timeAgo.format(updated, 'time')} ago`;
    return (
        <BusinessItemContainer onClick={onClick}>
            <AttributeContainer>
                <Name>{name}</Name>
                <Detail>{formattedUpdated}</Detail>
            </AttributeContainer>
            <RightDetail>{type || '-'}</RightDetail>
        </BusinessItemContainer>
    );
};

export default BusinessListItem;
