import React from 'react';
import AddBusinessItem from './add-item';
import BlockItem from "./block-item";
import ListItem from "./list-item";
import { CMSLoader } from "@cr38te/cr38te-cms";
import BlockItemsContainer from './block-items-container';
import ListItemsContainer from './list-items-container';
import { hasRoles } from '../../../utils/user';

const BusinessList = ({ loading = false, data = [], onClickItem = () => { }, onAddItem, userRole = 'partner' }) => {
    let showAsBlocks = hasRoles(userRole, 'partner');
    let Container = BlockItemsContainer;
    let AddItem = AddBusinessItem;
    let Item = BlockItem;
    if (!showAsBlocks) {
        Container = ListItemsContainer;
        AddItem = (() => null);
        Item = ListItem;
    }
    return (
        <Container fullHeight={loading}>
            {loading && <CMSLoader animating={true} zIndex={0} />}
            {!loading &&
                <>
                    <AddItem onClick={onAddItem} />
                    {data.map((item = {}) => (
                        <Item
                            key={item.id}
                            name={item.name}
                            type={item.type}
                            isApproved={item.registrationIsApproved}
                            isPaid={item.registrationIsPaid}
                            onClick={() => {
                                (onClickItem && onClickItem(item));
                            }}
                            updatedTimestamp={item.updated}
                        />
                    ))}
                </>
            }
        </Container>
    )
};
export default BusinessList;
